import React, { PropsWithChildren } from 'react';

import { TechemTheme } from '@techem/techem-theme';

import { ModalFooter } from 'baseui/modal';

interface CustomModalFooterProps {
  style?: any;
  className?: any;
}

const CustomModalFooter: React.FC<PropsWithChildren<CustomModalFooterProps>> = ({
  style,
  className = '',
  children
}) => {
  return (
    <ModalFooter
      className={className + ' mx-0'}
      style={{
        marginBottom: '8px',
        padding: '24px !important',
        backgroundColor: TechemTheme.colors.backgroundSecondary,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        '@media (max-width: 767px)': {
          paddingBottom: '32px !important'
        },
        ...style
      }}
    >
      {children}
    </ModalFooter>
  );
};

export default CustomModalFooter;
