import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { TechemTheme } from '@techem/techem-theme';
import { formatTime } from 'tools/dev/DateTimeFormatHelper';
import { TrackerUtil } from 'tracking/TrackerUtil';
import { Button } from '@techem/ui-components';
import { OrtecTimeSlotContext } from 'contexts/OrtecTimeSlotContext';
import { OBCTimeSlot } from 'pages/OBCFlow/OBCSchedulingAssistant/OBCSchedulingAssistantPage';
import { TimeSlot } from 'clients/obc/types';
import { KIND } from 'baseui/button';

interface TimeSlotButtonProps {
  index: number;
  timeSlot: TimeSlot | OBCTimeSlot;
  kind: keyof typeof KIND;
  additionalTestId?: string;
  handleSetOBCTimeSlotCandidate?: React.Dispatch<React.SetStateAction<OBCTimeSlot | undefined>>;
}

const TimeSlotButton: React.FC<TimeSlotButtonProps> = (props) => {
  const { setTimeSlotCandidate: setTimeSlotCandidateViaContext } = useContext(OrtecTimeSlotContext);

  const { index, timeSlot: item, kind } = props;
  const dateFrom = item.from;
  const dateTill = item.till;

  return (
    <Button
      kind={kind}
      additionalStyleAsPlainCSS={{
        'font-size': '0.875rem !important',
        'padding-left': '0 !important',
        'padding-right': '0 !important'
      }}
      mainColor={TechemTheme.colors.accent}
      mainHoverColor={TechemTheme.colors.accent700}
      onClick={() => {
        // By default, we use the context's handler.
        // If there is a custom handler specified, we use it instead.
        !!props.handleSetOBCTimeSlotCandidate
          ? props.handleSetOBCTimeSlotCandidate({
              ...item,
              from: new Date(dateFrom),
              till: new Date(dateTill)
            })
          : setTimeSlotCandidateViaContext({
              ...item,
              from: dateFrom as string,
              till: dateTill as string
            });
      }}
      stretched
      additionalTestId={`timeslot ${index} ${
        !!props.additionalTestId ? ` ${props.additionalTestId}` : ''
      }`}
      trackClickUsingTestId={() => {
        TrackerUtil.trackBtnClick(
          `${TrackerUtil.getPageName()}_button_timeslot-${dateFrom}-${dateTill}`,
          `button_timeslot-${dateFrom}-${dateTill}`
        );
      }}
    >
      <Trans
        i18nKey="OBCSchedulingAssistantTimeslotSelectionButtonText"
        values={{
          fromTime: formatTime(new Date(item.from!), true),
          toTime: formatTime(new Date(item.till!), true)
        }}
      />
    </Button>
  );
};

export default TimeSlotButton;
