import LoadingSpinner from 'components/LoadingSpinner';
import { OrtecAvailabilityContext } from 'contexts/OrtecAvailabilityContext';
import OrtecTimeSlotProvider from 'contexts/OrtecTimeSlotContext';
import PreferredTimeSlotProvider from 'contexts/PreferredTimeSlotContext';
import React, { useContext } from 'react';
import OnSiteInspectionSchedulingAssistantPage from './OnSiteInspectionSchedulingAssistantPage';
import OnSiteInspectionSchedulingOrtecPage from './OnSiteInspectionSchedulingOrtecPage';

const OnSiteInspectionSchedulingAssistantInterceptor: React.FC = () => {
  const { isLoading, rawAvailableTimeSlots } = useContext(OrtecAvailabilityContext);

  return isLoading ? (
    <div className="d-flex vw-100 vh-100" style={{ transitionDuration: '350ms' }}>
      <div className="m-auto">
        <LoadingSpinner />
      </div>
    </div>
  ) : rawAvailableTimeSlots.length > 0 ? (
    <OrtecTimeSlotProvider availableOrtecTimeSlots={rawAvailableTimeSlots}>
      <OnSiteInspectionSchedulingOrtecPage />
    </OrtecTimeSlotProvider>
  ) : (
    <PreferredTimeSlotProvider appointmentType="inspection">
      <OnSiteInspectionSchedulingAssistantPage />
    </PreferredTimeSlotProvider>
  );
};

export default OnSiteInspectionSchedulingAssistantInterceptor;
