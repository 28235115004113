import { trackEvent as trackEventInEtracker, trackPage as trackPageInEtracker } from './etrackr';
import { trackEvent as trackEventInGtag, trackPage as trackPageInGtag } from './gtag';
import { trackPage as trackPageInFB, trackGenericEvent as trackGenericEventInFB } from './fbPixel';
import {
  emitDataLayerEvent as emitDataLayerEventForAdobeAnalytics,
  emitDataLayerClickEvent as emitDataLayerClickEventForAdobeAnalytics,
  emitDataLayerPageImpression as emitDataLayerPageImpressionForAdobeAnalytics,
  emitDataLayerModalImpression as emitDataLayerModalImpressionForAdobeAnalytics,
  DataLayerEventForAdobeAnalytics
} from './adobe';

// TrackerUtil contains code shared/common to multiple tracking tools and acts as a facade in addition.
export class TrackerUtil {
  static trackPage(pageName: string) {
    trackPageInEtracker(pageName);
    trackPageInGtag(pageName);
    trackPageInFB();
    emitDataLayerPageImpressionForAdobeAnalytics({
      pageIdentifier: `${pageName}`,
      pageLanguage: 'de',
      pageName: document.title,
      pagePath: `${
        window.location.href.split(window.location.origin)[1].split('#')[0].split('?')[0]
      }`,
      virtualPagePath: window.location.hash.split('#')[1]
        ? `#${window.location.hash.split('#')[1]}`
        : '',
      pageURL: window.location.href
    });
  }

  static trackEvent(
    pageName: string,
    category: string,
    action: string,
    type: string,
    value?: string
  ) {
    trackEventInEtracker(pageName, category, action, type, value);
    trackEventInGtag(action, category, type);
    trackGenericEventInFB(pageName, category, action, type, value);
  }

  static trackModalAppearance(modalIdentifier: string) {
    emitDataLayerModalImpressionForAdobeAnalytics({
      elementCategory: 'modal-view',
      elementIdentifier: `${modalIdentifier}`,
      elementType: 'modal-view'
    });
  }

  static trackPrevBtnClick() {
    TrackerUtil.trackBtnClick(`${TrackerUtil.getPageName()}_button_back`, 'button_back');
  }
  static trackNextBtnClick() {
    TrackerUtil.trackBtnClick(`${TrackerUtil.getPageName()}_button_next`, 'button_next');
  }
  static trackBtnClickByIdentifier(buttonIdentifier: string, componentIdentifier?: string) {
    const trackingPrefix = componentIdentifier ?? TrackerUtil.getPageName();
    TrackerUtil.trackBtnClick(
      `${trackingPrefix}_button_${buttonIdentifier}`,
      `button_${buttonIdentifier}`
    );
  }

  static trackBtnClick(uniqueIdentifier: string, name: string) {
    TrackerUtil.trackEvent(TrackerUtil.getPageName(), 'button', 'click', uniqueIdentifier);
    emitDataLayerClickEventForAdobeAnalytics({
      elementCategory: 'button',
      elementIdentifier: uniqueIdentifier,
      elementType: name
    });
  }

  static trackLinkClick(uniqueIdentifier: string, name: string) {
    TrackerUtil.trackEvent(TrackerUtil.getPageName(), 'link', 'click', uniqueIdentifier);
    emitDataLayerClickEventForAdobeAnalytics({
      elementCategory: 'link',
      elementIdentifier: uniqueIdentifier,
      elementType: name
    });
  }

  static trackRadioClick(uniqueIdentifier: string, name: string) {
    TrackerUtil.trackEvent(TrackerUtil.getPageName(), 'radio-button', 'click', uniqueIdentifier);
    emitDataLayerClickEventForAdobeAnalytics({
      elementCategory: 'radio-button',
      elementIdentifier: uniqueIdentifier,
      elementType: name
    });
  }

  static trackCheckboxClick(uniqueIdentifier: string, name: string) {
    TrackerUtil.trackEvent(TrackerUtil.getPageName(), 'checkbox', 'click', uniqueIdentifier);
    emitDataLayerClickEventForAdobeAnalytics({
      elementCategory: 'checkbox',
      elementIdentifier: uniqueIdentifier,
      elementType: name
    });
  }

  static trackToggleClick(uniqueIdentifier: string, name: string) {
    TrackerUtil.trackEvent(TrackerUtil.getPageName(), 'toggle', 'click', uniqueIdentifier);
    emitDataLayerClickEventForAdobeAnalytics({
      elementCategory: 'toggle',
      elementIdentifier: uniqueIdentifier,
      elementType: name
    });
  }

  static trackError(identifier: string, errorMessage?: string) {
    TrackerUtil.trackEvent(
      TrackerUtil.getPageName(),
      'error',
      identifier,
      errorMessage ? errorMessage : ''
    );
    const payload: DataLayerEventForAdobeAnalytics = {
      event: 'error',
      errorId: identifier
    };
    if (errorMessage) {
      payload.errorMessage = errorMessage;
    }
    emitDataLayerEventForAdobeAnalytics(payload);
  }

  static getPageName(): string {
    // as we are using HashRouter, use the hash as our base for the page name
    let pageNameToTrack = window.location.hash;

    if (pageNameToTrack.startsWith('#') && pageNameToTrack.length >= 2) {
      // strip the leading hash sign
      pageNameToTrack = window.location.hash.substring(1);
    }

    // Strip the leading slash because we want to track our pages
    // exactly like we did in the past with an explicit mapping(which didn't use slashes)
    if (pageNameToTrack.startsWith('/') && pageNameToTrack.length >= 2) {
      pageNameToTrack = pageNameToTrack.substring(1);
    }

    // Query parameters should not be included in the tracked page name,
    // because that would lead to many unintendedly "individual" pages tracked.
    // Surprisingly "window.location.hash" can include query parameters.
    // This was a bug in the past when clicking on links in emails.
    const idxOfQuestionMark = pageNameToTrack.indexOf('?');
    if (idxOfQuestionMark !== -1) {
      pageNameToTrack = pageNameToTrack.substring(0, idxOfQuestionMark);
    }

    // backwards compatibility:
    // The path of this page has no typo,
    if (pageNameToTrack === 'apartment-count') {
      // but the tracked pageName always contained the typo(double pp).
      // Continue tracking including the typo to not have a break in the reports.
      pageNameToTrack = 'appartment-count';
    }

    return pageNameToTrack;
  }
}

export const handleButtonTracking = (buttonIdentifier: string) => {
  TrackerUtil.trackBtnClick(
    `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
    `button_${buttonIdentifier}`
  );
};
