import { createSlice } from '@reduxjs/toolkit';

export interface PersonalData {
  gender: string;
  firstName: string;
  lastName: string;
}
export interface Address {
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
}
export interface ContractData {
  personalData: PersonalData;
  address: Address;
}
export interface ContactDetails {
  phoneNumber: string;
  email: string;
}

export interface ContactChannels {
  phoneNumberAllowedForContact: boolean;
  emailAddressAllowedForContact: boolean;
}

export interface EventHistoryNameOrderObject {
  name: string;
  progress: number;
}
export interface EventHistoryEntry {
  timestamp: string;
  nameOrderObject: EventHistoryNameOrderObject;
  data: any | undefined;
}

export enum OrderType {
  TechemDirect = 'statement_heating_costs',
  SmokeAlarm = 'statement_smoke_alarm'
}
export interface Order {
  type: OrderType;
  clientContactDetails: ContactDetails;
  eventHistory: EventHistoryEntry[];
  data: {};
  onSiteInspectionInterval: {
    from: Date;
    to: Date;
  };
  installationInterval: {
    from: Date;
    to: Date;
  };
  orderNumber: string;
}
export interface TechemDirectOrder extends Order {
  type: OrderType.TechemDirect;
  data: {
    centralHeatingAvailable: string;
    centralHotWaterDelivery: string;
    heatingType: string;
    heatingSources: string[];
    billingPeriod: { start: string; end: string };
  };
  installationKitTrackingId?: string;
}

export interface Property {
  address: Address;
  numberOfApartments: number;
  orders: Order[];
  propertyNumber: string;
  onSiteInspectionAppointmentAdvanceDays?: number;
  installationAppointmentAdvanceDays?: number;
}
export interface UserData {
  clientNumber: string;
  properties: Property[];
  contractData: ContractData;
  contactChannels: {
    phoneNumberAllowedForContact: boolean;
    emailAddressAllowedForContact: boolean;
  };
}
export type StoreObject = {
  userData: UserData;
  selectedPropertyIndex: number;
  isDirty: boolean;
};
const initialState: StoreObject = {
  userData: {
    clientNumber: '',
    properties: [],
    contractData: {
      personalData: { gender: '', firstName: '', lastName: '' },
      address: { street: '', houseNumber: '', zip: '', city: '' }
    },
    contactChannels: {
      phoneNumberAllowedForContact: true,
      emailAddressAllowedForContact: true
    }
  },
  selectedPropertyIndex: -1,
  isDirty: false
};

// Maybe helpful to have this defined here as well?
export enum EventName {
  OrderCompleted = 'order_completed',
  OnboardingAccessGranted = 'onboarding_access_granted',
  OnboardingActionRequired = 'onboarding_action_required',
  PreferredOnSiteInspectionTimeslotsRequired = 'preferred_on_site_inspection_timeslots_required',
  PreferredOnSiteInspectionTimeslotsSet = 'preferred_on_site_inspection_timeslots_set',
  OnSiteInspectionScheduled = 'on_site_inspection_scheduled',
  OnSiteInspectionCancelled = 'on_site_inspection_cancelled',
  OnSiteInspectionIncomplete = 'on_site_inspection_incomplete',
  OnSiteInspectionFollowUpRequired = 'on_site_inspection_followup_required',
  InstallationStateRecorded = 'installation_state_recorded',
  InstallationKitNeeded = 'installation_kit_needed',
  InstallationKitRequested = 'installation_kit_requested',
  InstallationKitSent = 'installation_kit_sent',
  InstallationKitInstalled = 'installation_kit_installed',
  InstallationIntervalSet = 'installation_interval_set',
  PreferredInstallationTimeslotsRequired = 'preferred_installation_timeslots_required',
  PreferredInstallationTimeslotsSet = 'preferred_installation_timeslots_set',
  InstallationTimeslotScheduled = 'installation_timeslot_scheduled',
  InstallationTimeslotCancelled = 'installation_timeslot_cancelled',
  InstallationFollowUpRequired = 'installation_followup_required',
  InstallationCompleted = 'installation_completed',
  OnboardingCompleted = 'onboarding_completed'
}

export const EventHistoryOrdering: EventHistoryNameOrderObject[] = [
  { name: EventName.OrderCompleted, progress: 5 },
  { name: EventName.OnboardingAccessGranted, progress: 7 },
  { name: EventName.PreferredOnSiteInspectionTimeslotsRequired, progress: 10 },
  {
    name: EventName.PreferredOnSiteInspectionTimeslotsSet,
    progress: 15
  },
  {
    name: EventName.OnSiteInspectionScheduled,
    progress: 20
  },
  {
    name: EventName.OnSiteInspectionCancelled,
    progress: 22
  },
  {
    name: EventName.OnSiteInspectionIncomplete,
    progress: 25
  },
  {
    name: EventName.OnSiteInspectionFollowUpRequired,
    progress: 30
  },
  {
    name: EventName.InstallationStateRecorded,
    progress: 40
  },
  { name: EventName.InstallationKitNeeded, progress: 45 },
  { name: EventName.InstallationKitRequested, progress: 47 },
  { name: EventName.InstallationKitSent, progress: 50 },
  { name: EventName.InstallationKitInstalled, progress: 52 },
  { name: EventName.InstallationIntervalSet, progress: 53 },
  { name: EventName.OnboardingActionRequired, progress: 54 },
  { name: EventName.PreferredInstallationTimeslotsRequired, progress: 55 },
  { name: EventName.PreferredInstallationTimeslotsSet, progress: 60 },
  { name: EventName.InstallationTimeslotScheduled, progress: 80 },
  { name: EventName.InstallationTimeslotCancelled, progress: 82 },
  { name: EventName.InstallationFollowUpRequired, progress: 85 },
  { name: EventName.InstallationCompleted, progress: 90 },
  { name: EventName.OnboardingCompleted, progress: 100 }
];
export const mostRecentEvent = (eventList: EventHistoryEntry[]) => {
  // Assume pre-sorted event list on back-end side, so we perform no actual sorting here.
  return eventList[eventList.length - 1];
};

export enum TimeOfDay {
  AllDay = 'all_day',
  Morning = 'morning',
  Afternoon = 'afternoon'
}

export const onboardingInformationSlice = createSlice({
  name: 'onboardingInfo',
  initialState: initialState,
  reducers: {
    setCustomerDataDirty: (state) => {
      state.isDirty = true;
    },
    setAllFromAPIResponse: (state, action) => {
      state.userData = action.payload.userData;
      state.selectedPropertyIndex = action.payload.selectedPropertyIndex;
      state.isDirty = false;
    },
    setSelectedPropertyIndex: (state, action) => {
      state.selectedPropertyIndex = action.payload;
    },
    setContactChannels: (state, action) => {
      state.userData.contactChannels = action.payload;
    }
  }
});

export const {
  setCustomerDataDirty,
  setAllFromAPIResponse,
  setSelectedPropertyIndex,
  setContactChannels
} = onboardingInformationSlice.actions;

export default onboardingInformationSlice.reducer;
