import { KIND, SIZE } from 'baseui/button';
import Calendar from 'components/Calendar';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { TechemTheme } from '@techem/techem-theme';
import { DateDefaultLocale } from 'tools/dev/DateTimeFormatHelper';
import { TrackerUtil } from 'tracking/TrackerUtil';
import { Button } from '@techem/ui-components';
import TimeSlotButton from './OrtecTimeSlotButton';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import { DAY_OF_DATE_INDEX_SATURDAY, DAY_OF_DATE_INDEX_SUNDAY } from 'tools/Constants';
import { TimeSlot, TimeslotDataItem } from 'clients/obc/types';
import AnimatedExpandingView from 'components/AnimatedExpandingView';

interface TimeSlotPickerProps {
  title: string;
  availableTimeSlots: TimeslotDataItem[];
  unavailableDays: Date[];
  excludeDates?: Date[];
  excludeDays?: number[];

  minDate: Date;
  maxDate?: Date;

  dateCandidate: Date | undefined;
  handleSetDateCandidate: (dates: {
    readonly date: Date | (Date | null | undefined)[] | null | undefined;
  }) => void;

  timeSlotCandidate: TimeSlot | undefined;
  handleSetTimeSlotCandidate: Dispatch<SetStateAction<TimeSlot | undefined>>;

  handleOpenModal: Dispatch<SetStateAction<boolean>>;
}

const TimeSlotPicker: React.FC<TimeSlotPickerProps> = (props) => {
  const {
    title,
    minDate,
    maxDate,
    excludeDays,
    availableTimeSlots,
    unavailableDays,
    dateCandidate,
    timeSlotCandidate,
    handleSetDateCandidate,
    handleOpenModal
  } = props;

  const availableTS =
    availableTimeSlots.find((item) => {
      if (item.date && dateCandidate) {
        const itemDate = new Date(item.date.valueOf()).setHours(0, 0, 0, 0);
        const searchedDate = new Date(dateCandidate.valueOf()).setHours(0, 0, 0, 0);
        return itemDate.valueOf() === searchedDate.valueOf();
      }
      return false;
    })?.availableTimeslots || [];

  const [randomIdForAnimatedView, setRandomIdForAnimatedView] = useState(Math.random());

  return (
    <>
      <Row
        className="justify-content-center"
        style={{
          backgroundColor: TechemTheme.colors.backgroundSecondary,
          ...CSSAssignBorderRadius('8px')
        }}
      >
        <Col
          xs="auto"
          className="m-3"
          style={{
            backgroundColor: TechemTheme.colors.backgroundPrimary,
            ...CSSAssignBorderRadius('8px')
          }}
        >
          <Calendar
            minDate={minDate}
            maxDate={maxDate}
            locale={DateDefaultLocale}
            excludeDates={unavailableDays}
            onChange={(dates) => {
              // If actual date changed (no click on the exact same date as just before!),
              // we need to reset the random id to tell animated view that this is a new child.
              if (dates.date?.toString() !== dateCandidate?.toString())
                setRandomIdForAnimatedView(Math.random());
              handleSetDateCandidate(dates);
            }}
            filterDate={(date: Date) =>
              !(excludeDays ?? [DAY_OF_DATE_INDEX_SUNDAY, DAY_OF_DATE_INDEX_SATURDAY]).includes(
                date.getDay()
              )
            } // Make weekends not selectable
            hideWeekend
          />
        </Col>
      </Row>

      {!!dateCandidate ? (
        <AnimatedExpandingView>
          <Row
            key={`timeslot-picker-${dateCandidate.toString()}-${randomIdForAnimatedView}}`}
            className="justify-content-center"
          >
            <Col xs={12} md={6} className="m-3">
              <Row className="mb-3">
                <Col xs className="text-bold copy-l">
                  {title}
                </Col>
              </Row>
              <Row className="justify-content-center">
                {availableTS.map((item, index) => {
                  const from = new Date(item.from);
                  return (
                    <Col xs={6} key={index} className="pb-3">
                      <TimeSlotButton
                        index={index}
                        timeSlot={item}
                        kind={
                          !!timeSlotCandidate &&
                          new Date(timeSlotCandidate.from).valueOf() === from.valueOf()
                            ? KIND.primary
                            : KIND.secondary
                        }
                        additionalTestId={`start-${new Date(item.from).toLocaleTimeString('en', {
                          timeStyle: 'short',
                          hour12: false
                        })} end-${new Date(item.till).toLocaleTimeString('en', {
                          timeStyle: 'short',
                          hour12: false
                        })}${
                          !!timeSlotCandidate &&
                          new Date(timeSlotCandidate.from).valueOf() === from.valueOf()
                            ? ' selected'
                            : ''
                        }`}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </AnimatedExpandingView>
      ) : (
        <Row className="justify-content-center">
          <Col xs={12} md={6} className="m-3">
            <Row className="justify-content-center">
              <Col xs={8} className="text-center copy">
                <Trans i18nKey="OBCSchedulingAssistantSelectDateHint" />
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Row className="justify-content-center">
        <Col xs="auto" className="m-3 mb-0">
          <Button
            size={SIZE.large}
            onClick={() => handleOpenModal(true)}
            disabled={!timeSlotCandidate}
            additionalTestId="book-timeslot"
            trackClickUsingTestId={(buttonIdentifier) => {
              TrackerUtil.trackBtnClick(
                `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                `button_${buttonIdentifier}`
              );
            }}
          >
            <Trans i18nKey="OBCSchedulingAssistantSendTimeslotButtonText"></Trans>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default TimeSlotPicker;
