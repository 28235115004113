export const CSSAssignMultiple = (keys: string[], valueForAllKeys: any) => {
  return keys.reduce((obj, prop) => ({ ...obj, [prop]: valueForAllKeys }), {});
};
export const CSSAssignBorderRadius = (borderRadius: string) => {
  return {
    ...CSSAssignMultiple(
      [
        'borderTopLeftRadius',
        'borderTopRightRadius',
        'borderBottomLeftRadius',
        'borderBottomRightRadius'
      ],
      borderRadius
    )
  };
};
