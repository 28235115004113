import React, { PropsWithChildren } from 'react';

import { useStyletron } from 'baseui';

import { Modal, SIZE } from 'baseui/modal';
import Icon, { ICONSIZE } from '../Icon';

import xIconRed from '../../assets/icons/png/3x/icn-xmark-red@3x.png';
import { useModalTracking } from '../../tools/hooks/useModalTracking';
import { Col, Row } from 'react-bootstrap';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';

interface Props {
  isOpen: boolean;
  modalTrackingId?: string;
  onClose: () => void;
  additionalTestId?: string;
  additionalDialogContainerStyles?: {};
  additionalDialogStyles?: {};
  hideXIcon?: boolean;
}

const CustomModal: React.FC<PropsWithChildren<Props>> = ({
  children,
  isOpen,
  modalTrackingId,
  onClose,
  additionalTestId,
  additionalDialogContainerStyles,
  additionalDialogStyles,
  hideXIcon = false
}) => {
  const [css] = useStyletron();
  useModalTracking(isOpen, modalTrackingId);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      animate
      autoFocus={false}
      size={SIZE.auto}
      overrides={{
        Root: {
          style: {
            zIndex: 10000
          },
          props: {
            'data-gi': `modal${additionalTestId !== undefined ? ' ' + additionalTestId : ''}`
          }
        },
        DialogContainer: {
          style: {
            ...additionalDialogContainerStyles
          }
        },
        Dialog: {
          style: {
            maxWidth: '100vw',
            width: '972px',
            margin: '30px',
            '@media (max-width: 992px)': {
              width: 'calc(100vw - 20px)',
              margin: '10px'
            },
            ...CSSAssignBorderRadius('8px'),
            ...additionalDialogStyles
          }
        },
        Close: {
          style: () => ({
            display: 'none'
          })
        }
      }}
    >
      {!hideXIcon && (
        <Row className="justify-content-end mx-0">
          <Col
            xs="auto"
            className={
              css({
                width: '48px',
                height: '48px'
              }) + ' p-2 d-flex'
            }
          >
            <Icon
              centered
              size={ICONSIZE.small}
              iconSrc={xIconRed}
              handleClick={onClose}
              alt="close icon"
              additionalTestId="close"
            />
          </Col>
        </Row>
      )}

      {children}
    </Modal>
  );
};

export default CustomModal;
