/* TODO: Check more characteristics
 * we can use /(^\+49|^0049|^0)1[5-7][1-9]/ for a more characteristic german mobile number validation
 * see: https://de.wikipedia.org/wiki/Rufnummer#:~:text=In%20Deutschland%20wurden%20bei%20der,auch%20Telefonvorwahl%20(Deutschland)).
 */
class PhoneNumberValidator {
  static regExMobileNumber = new RegExp(/(^\+49|^0049|^0)1/);

  static isValidMobileNumber(phoneNumber: string) {
    phoneNumber = phoneNumber.replace(' ', '');
    return this.regExMobileNumber.test(phoneNumber);
  }
}

export default PhoneNumberValidator;
