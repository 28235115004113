import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { BaseProvider } from 'baseui';

import './App.scss';

import i18n from 'localization/Localization';
import store from './store';
import { initGtag, loadGtag } from 'tracking/gtag';
import { loadEtracker } from 'tracking/etrackr';
import { initFBPixel, loadFBPixel } from 'tracking/fbPixel';
import initABTesting from 'abTesting';
import { TechemTheme } from '@techem/techem-theme';
import { StyletronEngineProvider, ThemeContextProvider } from '@techem/ui-components';
import { useTranslation } from 'react-i18next';
import { loadAdobeAnalytics } from 'tracking/adobe';
import { ETRACKER_SECURE_CODE, FB_PIXEL_ID, GA_TRACKING_ID } from 'config';
import Routes from './Routes';

const styletronEngine = new Styletron({
  // ensure compatibility with other frameworks like bootstrap by prefixing class names, etc.
  prefix: 'st-'
});

const App = () => {
  const { t } = useTranslation();
  const [initDone, setInitDone] = useState<boolean>(false);

  useEffect(() => {
    // Initialize A/B-Testing.
    const abTesting = initABTesting();

    // The following promises need to be resolved before rendering the application,
    // because the application is relying on global functions and variables provided by them.
    loadAdobeAnalytics();
    Promise.all([
      loadEtracker(false, 'onboarding', abTesting, ETRACKER_SECURE_CODE).catch((err) =>
        console.error(err)
      ),
      loadGtag(GA_TRACKING_ID)
        .then((initNeeded) => initGtag(initNeeded, false))
        .catch((err) => console.error(err)),
      loadFBPixel(FB_PIXEL_ID)
        .then((initNeeded) => initFBPixel(initNeeded, FB_PIXEL_ID))
        .catch((err) => console.error(err)),
      i18n
    ])
      .then(() => setInitDone(true))
      .catch((err) => console.error('app init failed', err));
  }, [t]);

  return initDone ? (
    <ReduxProvider store={store}>
      <ThemeContextProvider value={TechemTheme}>
        <BaseProvider theme={TechemTheme}>
          <StyletronProvider value={styletronEngine}>
            <StyletronEngineProvider value={styletronEngine}>
              <RouterProvider router={Routes} />
            </StyletronEngineProvider>
          </StyletronProvider>
        </BaseProvider>
      </ThemeContextProvider>
    </ReduxProvider>
  ) : null;
};
export default App;
