import { EventHistoryOrdering, OrderType } from 'reducers/onboardingInformationSlice';
import { CustomerDTO } from 'services/DTO';
import { DateFormat, formatDate } from 'tools/dev/DateTimeFormatHelper';
import { OptionKeys } from 'tools/FixedOptionMapper';

function mapCustomerDataResult(customerDataResult: CustomerDTO) {
  customerDataResult.userData.properties.forEach((property: any) => {
    property.orders.forEach((propertyOrder: any) => {
      propertyOrder.eventHistory = propertyOrder.eventHistory.map((eventItem: any) => {
        return {
          timestamp: eventItem.timestamp,
          nameOrderObject: EventHistoryOrdering.find((item) => item.name === eventItem.name)!,
          data: eventItem.eventData
        };
      });

      if (propertyOrder.type === OrderType.TechemDirect) {
        propertyOrder.data = {
          centralHeatingAvailable: propertyOrder.data.centralHeatingAvailable
            ? OptionKeys.CentralHeatingAvailable.Yes
            : OptionKeys.CentralHeatingAvailable.No,
          centralHotWaterDelivery: propertyOrder.data.centralHotWaterDelivery
            ? OptionKeys.HotWaterDeliveryCentral.Yes
            : OptionKeys.HotWaterDeliveryCentral.No,
          heatingType: propertyOrder.data.heatingType,
          heatingSources: propertyOrder.data.heatingSources,
          billingPeriod: {
            start: formatDate(
              new Date(propertyOrder.data.billingPeriod.start),
              DateFormat.DateSimple
            ),
            end: formatDate(new Date(propertyOrder.data.billingPeriod.end), DateFormat.DateSimple)
          }
        };
      }
    });
  });
  return customerDataResult;
}

export default mapCustomerDataResult;
