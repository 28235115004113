import React from 'react';

interface Props {
  surveyId?: string;
  tdObjectId?: string;
}

const SurveyView: React.FC<Props> = ({ surveyId, tdObjectId }) => {
  return !!surveyId && !!tdObjectId ? (
    <div
      className="w-100"
      id="survey-container"
      style={{
        height: '146px',
        border: 'none',
        overflow: 'hidden'
      }}
    >
      <iframe
        className="w-100"
        scrolling="no" // Yes, it's deprecated, but works still better than most CSS.
        title="survicate-survey"
        src={`https://survey.survicate.com/${surveyId}/?first_name=${tdObjectId}`}
        style={{ height: '146px', border: 'none' }}
      />
    </div>
  ) : null;
};

export default SurveyView;
