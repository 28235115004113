import { CalendarProps, StatefulCalendar } from 'baseui/datepicker';
import React, { PropsWithChildren, useState } from 'react';

import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';

interface CustomCalendarProps extends PropsWithChildren<CalendarProps> {
  additionalTestId?: string;
  topLevelStylingOverrides?: any;
  key?: string;
  hideWeekend?: boolean;
}

const Calendar = (props: CustomCalendarProps) => {
  const [dateValue, setDateValue] = useState(props.value);
  return (
    <StatefulCalendar
      autoFocusCalendar={props.autoFocusCalendar}
      dateLabel={props.dateLabel}
      excludeDates={props.excludeDates}
      filterDate={props.filterDate}
      highlightedDate={props.highlightedDate}
      includeDates={props.includeDates}
      key={props.key}
      locale={props.locale}
      maxDate={props.maxDate}
      minDate={props.minDate}
      monthsShown={props.monthsShown}
      fixedHeight={false}
      onChange={(a) => {
        setDateValue(a.date);
        if (props.onChange) props.onChange(a);
      }}
      onDayClick={props.onDayClick}
      onDayMouseLeave={props.onDayMouseLeave}
      onDayMouseOver={props.onDayMouseOver}
      onMonthChange={props.onMonthChange}
      onYearChange={props.onYearChange}
      orientation={props.orientation}
      peekNextMonth={props.peekNextMonth}
      quickSelect={props.quickSelect}
      quickSelectOptions={props.quickSelectOptions}
      range={props.range}
      timeSelectEnd={props.timeSelectEnd}
      timeSelectStart={props.timeSelectStart}
      trapTabbing={props.trapTabbing}
      value={props.value}
      overrides={{
        CalendarHeader: {
          style: ({ $theme }) => ({
            backgroundColor: 'transparent',
            color: $theme.colors.primary,
            paddingLeft: '0',
            paddingRight: '0',
            fontSize: '0.875rem !important',
            width: '320px' // Introduced fixed width based on max mnth name length.
          })
        },
        CalendarContainer: {
          style: {
            paddingLeft: '0',
            paddingRight: '0',
            fontSize: '0.875rem !important'
          }
        },
        Input: {},
        InputWrapper: {},
        Month: {
          style: { width: '100%' }
        },
        MonthContainer: {
          style: {
            paddingLeft: '0',
            paddingRight: '0',
            fontSize: '0.875rem !important'
            //backgroundColor: "green",
          }
        },
        MonthYearSelectIconContainer: {},
        MonthYearSelectPopover: {},
        MonthYearSelectStatefulMenu: {},
        NextButtonIcon: {},
        Popover: {},
        PrevButtonIcon: {},
        QuickSelect: {},
        QuickSelectContainer: {},
        QuickSelectFormControl: {},
        TimeSelect: {},
        TimeSelectContainer: {},
        TimeSelectFormControl: {},
        Week: {
          style: ({ $theme }) => ({
            backgroundColor: 'transparent',
            color: $theme.colors.contentSecondary
          })
        },
        WeekdayHeader: {
          style: ({ $theme }) => ({
            backgroundColor: 'transparent',
            color: $theme.colors.contentSecondary,
            borderBottomColor: $theme.colors.primary,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            ":not(.col):not(.row)[alt*='Samstag'], :not(.col):not(.row)[alt*='Sonntag']": {
              display: props.hideWeekend ? 'none' : 'inline-block'
            },
            width: props.hideWeekend ? '20%' : '14.286%',
            fontSize: '0.875rem !important'
          })
        },
        MonthHeader: {
          style: ({ $theme }) => ({
            backgroundColor: 'transparent'
          })
        },
        MonthYearSelectButton: {
          style: ({ $theme }) => ({
            color: $theme.colors.primary,
            fontWeight: 'bold',
            fontSize: '0.875rem !important',
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
            ':nth-of-type(2)': {
              // Target month dropdown select button to adapt its size only.
              flexGrow: 1,
              justifyContent: 'right'
            }
          })
        },
        PrevButton: {
          style: ({ $theme, $disabled }) => {
            return {
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
              flexGrow: 0,
              color: $disabled ? $theme.colors.buttonDisabledText : $theme.colors.primary
            };
          }
        },
        NextButton: {
          style: ({ $theme, $disabled }) => {
            return {
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
              flexGrow: 0,
              color: $disabled ? $theme.colors.buttonDisabledText : $theme.colors.primary
            };
          }
        },
        Day: {
          style: ({ $theme, $selected, $isHovered, $isHighlighted, $disabled }) => ({
            backgroundColor: $selected
              ? $theme.colors.contentTertiary
              : $isHovered && !$disabled
                ? $theme.colors.backgroundSecondary
                : 'transparent',
            color: $selected
              ? $theme.colors.primaryB
              : $disabled
                ? $theme.colors.buttonDisabledText
                : $isHighlighted && !$disabled
                  ? $theme.colors.contentTertiary
                  : 'inherit',
            fontWeight: $selected ? 'bold' : 'inherit',
            borderWidth: 0,
            ...CSSAssignBorderRadius('50%'),
            '::after': {
              backgroundColor: $selected
                ? $theme.colors.contentTertiary
                : $isHovered
                  ? $theme.colors.backgroundSecondary
                  : 'transparent',
              color: $isHighlighted
                ? $theme.colors.contentTertiary
                : $disabled
                  ? $theme.colors.buttonDisabledText
                  : 'inherit',
              borderWidth: 0
            },
            ":not(.col):not(.row)[role*='gridcell']:nth-of-type(6), :not(.col):not(.row)[role*='gridcell']:nth-of-type(7)":
              {
                display: props.hideWeekend ? 'none' : 'inline-block'
              },
            width: props.hideWeekend ? '20%' : '14.286%',
            marginLeft: props.hideWeekend ? 'calc((20% - 48px)/2)' : '0',
            marginRight: props.hideWeekend ? 'calc((20% - 48px)/2)' : '0',
            fontSize: '0.875rem !important'
          })
        },
        DayLabel: {
          style: ({ $theme, $selected, $isHovered, $isHighlighted, $disabled }) => ({
            backgroundColor: $selected
              ? $theme.colors.contentTertiary
              : $isHovered
                ? $theme.colors.backgroundSecondary
                : 'transparent',
            color: $isHighlighted
              ? $theme.colors.contentTertiary
              : $disabled
                ? $theme.colors.buttonDisabledText
                : 'inherit'
          })
        },
        Root: {
          style: {
            backgroundColor: 'transparent'
          },
          props: {
            'data-gi': `calendar${
              !!dateValue ? ` selected_${(dateValue as Date).toLocaleDateString('de-DE')}` : ''
            }`
          }
        }
      }}
    >
      {props.children}
    </StatefulCalendar>
  );
};
export default Calendar;
