import { TechemTheme } from '@techem/techem-theme';
import {
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader
} from '@techem/ui-components';
import { AppointmentClient } from 'clients';
import { TimeSlot } from 'clients/obc/types';
import PhoneSupportNoTitleView from 'components/PhoneSupportNoTitleView';
import React, { createContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OrderType } from 'reducers/onboardingInformationSlice';
import { RootState } from 'reducers/rootReducer';
import { Paths } from 'Routes';
import { styled } from 'styletron-react';
import { TrackerUtil } from 'tracking/TrackerUtil';

export type AppointmentType = 'inspection' | 'installation';

interface OrtecContextProps {
  appointmentType: AppointmentType;
}

interface OrtecContextType {
  isLoading: boolean;
  rawAvailableTimeSlots: TimeSlot[];
}

const setHref = (toUrl: string) => {
  window.location.href = `${window.location.origin}/#${toUrl}${
    window.location.hash.split('?').length > 1 ? '?' + window.location.hash.split('?')[1] : ''
  }`;
};

const HeaderView = styled(Col, {
  height: '100px',
  '@media (max-width: 767px)': {
    height: '80px'
  },
  backgroundColor: TechemTheme.colors.backgroundPrimary
});

export const OrtecAvailabilityContext = createContext({} as OrtecContextType);

const OrtecAvailabilityProvider: React.FC<OrtecContextProps> = ({ appointmentType, children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // data from timeslot request.
  const [rawAvailableTimeSlots, setRawAvailableTimeSlots] = useState<TimeSlot[]>([]);
  // error state if no fetching possible
  const [errorLoadingTimeslots, setErrorLoadingTimeslots] = useState<{
    show: boolean;
  } | null>(null);

  const { t } = useTranslation();

  const orderId: string =
    useSelector((state: RootState) => {
      const onboardingInfo = state.onboardingInfo;
      return onboardingInfo!.userData.properties.length > 0
        ? onboardingInfo!.userData.properties[onboardingInfo!.selectedPropertyIndex].orders.find(
            (order) => order.type === OrderType.TechemDirect
          )?.orderNumber
        : '';
    }) || '';

  const data = {
    isLoading,
    rawAvailableTimeSlots
  };

  // Fetch data on initial loading of this page
  useEffect(() => {
    AppointmentClient.getOrtecAvailableTimeSlots(orderId, appointmentType)
      .then((response) => {
        setRawAvailableTimeSlots(response.data.availableSlots);
        setIsLoading(false);
      })
      .catch((error) => {
        setErrorLoadingTimeslots({
          show: true
        });
      });
  }, [orderId, appointmentType]);

  return (
    <>
      <OrtecAvailabilityContext.Provider value={data}>{children}</OrtecAvailabilityContext.Provider>
      <TechemModal
        closeable
        header={
          <TechemModalHeader
            title={
              errorLoadingTimeslots
                ? t('OBCSchedulingAssistantErrorLoadingAvailableSlotsModalTitle')
                : ''
            }
          />
        }
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {errorLoadingTimeslots && (
                  <Trans
                    i18nKey={'OBCSchedulingAssistantErrorLoadingAvailableSlotsModalContent1'}
                    tOptions={{ skipPostProcessing: true }}
                  />
                )}
              </Col>
            </Row>
            {errorLoadingTimeslots && (
              <Row>
                <Col xs className="text-center copy">
                  <HeaderView lg={4} md={7} sm="auto" xs="auto" className="d-flex w-100">
                    <PhoneSupportNoTitleView />
                  </HeaderView>
                </Col>
              </Row>
            )}
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                'load-available-timeslots-error-modal'
              );
            }}
            primary={{
              text: errorLoadingTimeslots
                ? t('OBCSchedulingAssistantErrorLoadingAvailableSlotsModalButtonText')
                : '',
              handleOnClick: () => {
                setErrorLoadingTimeslots({
                  show: false
                });

                if (!!errorLoadingTimeslots) {
                  // Refresh the page, i.e. just go back to Jumppad.
                  setHref(Paths.Default);
                } else {
                  setTimeout(() => {
                    setErrorLoadingTimeslots(null);
                  }, 1500);
                }
              },
              additionalTestId: 'close-error-modal'
            }}
          ></TechemModalButtonFooter>
        }
        isOpen={!!errorLoadingTimeslots && errorLoadingTimeslots.show}
        modalTrackingId="load-available-timeslots-error-modal"
        onClose={() => {
          setErrorLoadingTimeslots({
            show: false
          });

          if (!!errorLoadingTimeslots) {
            // Refresh the page, i.e. just go back to Jumppad.
            setHref(Paths.Default);
          } else {
            setTimeout(() => {
              setErrorLoadingTimeslots(null);
            }, 1500);
          }
        }}
        additionalTestId="load-available-timeslots-error-modal"
      />
    </>
  );
};

export default OrtecAvailabilityProvider;
