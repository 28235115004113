import axios, { AxiosRequestConfig } from 'axios';

// Stolen from:
// https://stackoverflow.com/a/59771249/2440410

export const createFormAxios = (config?: AxiosRequestConfig) => {
  return axios.create({
    ...config,
    transformRequest: [
      function (data, headers) {
        if (
          headers['Content-Type'] &&
          (headers['Content-Type'] as string).startsWith('multipart/form-data')
        ) {
          const form = new FormData();
          for (const key in data) {
            const value = data[key];
            if (key === 'files') {
              value.forEach((v: any) => {
                form.append(key, v);
              });
            } else if (key === 'file') {
              form.append(key, Array.isArray(value) ? value[0] : value);
            } else {
              var blob = new Blob([JSON.stringify(value)], {
                type: 'application/json'
              });
              form.append(key, blob);
            }
          }
          return form;
        }

        return data;
      }
    ]
  });
};
