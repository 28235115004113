import React from 'react';

import { TechemTheme } from '@techem/techem-theme';
import { Spinner as BaseUISpinner, SpinnerProps } from 'baseui/spinner';
import { withStyle } from 'baseui';

interface CustomLoadingSpinnerProps extends SpinnerProps {
  additionalTestId?: string;
  topLevelStylingOverrides?: any;
}

const LS = (props: CustomLoadingSpinnerProps) => {
  const Spinner = withStyle(BaseUISpinner, {
    borderTopColor: TechemTheme.colors.spinnerTrackFill,
    ...props.topLevelStylingOverrides
  });

  return <Spinner data-gi={props.additionalTestId ? props.additionalTestId : ''} />;
};
export default LS;
