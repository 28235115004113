import { createSlice } from '@reduxjs/toolkit';

// Use this part of the store to store the selected variants of A/B tests.
// This way it's easy to show/hide or enable/disable certain parts at any location
// of the application for tests.
export type OBCInfoStoreData = {
  token?: string;
  personalInformation?: {
    firstName: string;
    lastName: string;
    gender: string;
  };
  appointmentSlot?: {
    from: string;
    to: string;
  };
  photosRecentlyUploaded: boolean;
  numberOfRecentlyUploadedFotos: number;
};
export const initialOBCInfoStoreData: OBCInfoStoreData = {
  photosRecentlyUploaded: false,
  numberOfRecentlyUploadedFotos: 0
};

export const OBCSetTimeslotResultOutcome = {
  ErrorNoAgents: 'ErrorNoAgents',
  ErrorSlotFullyBooked: 'ErrorSlotFullyBooked',
  ErrorTechnicalFailure: 'ErrorTechnicalFailure',
  Success: 'Success'
};

export const OBCInfoSlice = createSlice({
  name: 'obc',
  initialState: initialOBCInfoStoreData,
  reducers: {
    setOBCToken: (state, action) => {
      state.token = action.payload;
    },
    setOBCPersonalInformation: (state, action) => {
      state.personalInformation = action.payload;
    },
    setOBCAppointmentSlot: (state, action) => {
      state.appointmentSlot = action.payload;
    },
    setOBCInfoFromAPIResponse: (state, action) => {
      state.personalInformation = action.payload.personalInformation;
      state.appointmentSlot = action.payload.appointmentSlot;
      state.photosRecentlyUploaded = state.photosRecentlyUploaded ?? false; // do not overwrite uploaded status on refetching
    },
    setOBCPhotosRecentlyUploaded: (state, action) => {
      state.photosRecentlyUploaded = action.payload.photosRecentlyUploaded;
      state.numberOfRecentlyUploadedFotos = action.payload.numberOfRecentlyUploadedFotos;
    },
    setNumberOfRecentlyUploadedFotos: (state, action) => {
      state.numberOfRecentlyUploadedFotos = action.payload.numberOfRecentlyUploadedFotos;
    }
  }
});

export const {
  setOBCToken,
  setOBCPersonalInformation,
  setOBCAppointmentSlot,
  setOBCInfoFromAPIResponse,
  setOBCPhotosRecentlyUploaded
} = OBCInfoSlice.actions;

export default OBCInfoSlice.reducer;
