// Functions to work with Google Tag Manager(gtg)
import { GA_TRACKING_ID } from 'config';

export const isGtagLoaded = (): boolean => {
  return !!window.gtag;
};

/**
 * Loads google's gtag.js into the current DOM.
 * Returns a promise which resolves to {@code true} if the script needs to be initialized after.
 */
export function loadGtag(gaTrackingId?: string): Promise<boolean> {
  if (!gaTrackingId) {
    // don't try to load it if no gaTrackingId got provided
    console.error('Failed to load gtag script: No tracking id provided!');
    return Promise.resolve(false);
  }

  if (!!window.gtag) {
    console.warn('gtag script is already attached to DOM');
    return Promise.resolve(false);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
    script.type = 'text/plain';
    script.async = true;
    script.setAttribute('data-cookieconsent', 'marketing');
    script.onerror = function () {
      reject('failed to load gtag script');
    };
    document.head.appendChild(script);
    resolve(true);
  });
}

/**
 * Init google's gtag.js.
 * Returns a promise which resolves to {@code true} if gtag is ready to use.
 */
export function initGtag(
  initNeeded: boolean,
  sendPageView: boolean,
  gaTrackingId?: string
): Promise<boolean> {
  if (!initNeeded) {
    return Promise.resolve(isGtagLoaded());
  }

  return new Promise((resolve) => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.gtag = function () {
      // @ts-ignore
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', gaTrackingId!, { send_page_view: sendPageView });

    resolve(true);
  });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const trackPage = (page_title: string) => {
  if (!GA_TRACKING_ID) {
    console.info('GA_TRACKING_ID is not set. trackPage was triggered', {
      page_title
    });
  }
  if (!isGtagLoaded()) {
    return;
  }

  window.gtag('config', GA_TRACKING_ID, {
    page_title: page_title
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const trackEvent = (action: string, category: string, label?: string, value?: number) => {
  if (!GA_TRACKING_ID) {
    console.info('GA_TRACKING_ID is not set. trackEvent was triggered', {
      action,
      category,
      label,
      value
    });
  }
  if (!isGtagLoaded()) {
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
};

// Legacy/Deprecated: prefer using regular event tracking
export const trackConversionEvent = (nonPrefixedSendTo: string, transactionId?: string) => {
  if (!GA_TRACKING_ID) {
    console.info('GA_TRACKING_ID is not set. trackConversionEvent was triggered', {
      nonPrefixedSendTo,
      transactionId
    });
  }
  if (!isGtagLoaded()) {
    return;
  }

  window.gtag('event', 'conversion', {
    send_to: `${GA_TRACKING_ID}/${nonPrefixedSendTo}`,
    transaction_id: transactionId
  });
};
