import React, { useContext, useState } from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import TitleWithPrevPageLink from 'components/TitleWithPrevPageLink';
import PageDescription from 'components/PageDescription';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from 'baseui';
import { StoreObject, OrderType, Order } from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';

import { SIZE } from 'baseui/button';
import { TechemTheme } from '@techem/techem-theme';
import {
  Button,
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader
} from '@techem/ui-components';

import PhoneSupportNoTitleView from 'components/PhoneSupportNoTitleView';
import { RefreshAfterApiCall } from 'components/ApplicationInterceptor';
import { Paths } from 'Routes';
import { TrackerUtil } from 'tracking/TrackerUtil';
import PreferredTimeSlotSelectionPage from '../PreferredTimeSlotSelection/PreferredTimeSlotSelectionPage';
import PreferredTimeSlotPicker from 'components/timeslot/PreferredTimeSlotPicker';
import { PreferredTimeSlotContext } from 'contexts/PreferredTimeSlotContext';
import { AppointmentClient } from 'clients';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
    onSiteInspectionError: state.onboardingInfo.onSiteInspectionError
  };
};

interface Props {
  onboardingInfo?: StoreObject;
  onSiteInspectionError: boolean;
}

const OnSiteInspectionSchedulingAssistantPage: React.FC<Props> = ({ onboardingInfo }) => {
  // Hooks
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  //Context
  const { preferredTimeslots, setPreferredTimeslotIndex, resetPreferredTimeslotIndex } =
    useContext(PreferredTimeSlotContext);

  // States
  const [isSubmittingPreferredTimeslots, setIsSubmittingPreferredTimeslots] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    setIsSubmittingPreferredTimeslots(false);
  };

  const HeaderView = styled(Col, {
    width: '100%',
    display: 'flex',
    height: '100px',
    '@media (max-width: 767px)': {
      height: '80px'
    },
    backgroundColor: TechemTheme.colors.backgroundPrimary
  });

  const submitChosenTimeslotsHandler = () => {
    setIsSubmittingPreferredTimeslots(true);
    const payload = preferredTimeslots.filter((item) => {
      return !!item.date && !!item.timeOfDay;
    });

    AppointmentClient.setOnSiteInspectionPreferredTimeslots(
      onboardingInfo!.userData.properties[onboardingInfo!.selectedPropertyIndex].orders.find(
        (order: Order) => order.type === OrderType.TechemDirect
      )!.orderNumber,
      payload
    )
      .then((_: any) => {
        setIsSubmittingPreferredTimeslots(false);
        RefreshAfterApiCall();
      })
      .catch((_: any) => {
        setIsSubmittingPreferredTimeslots(false);
        setErrorModalOpen(true);
      });
  };

  const onSetPreferredTimeslotButtonClick = (index: number) => {
    if (index < 0 || index > 2) return;
    // Set time slot index to identify the position in array of chosen timeslots
    setPreferredTimeslotIndex(index);
    setIsSetting(true);
  };

  return isSetting ? (
    <PreferredTimeSlotSelectionPage
      handleGoBack={() => {
        setIsSetting(false);
        resetPreferredTimeslotIndex();
      }}
      appointmentType={'inspection'}
    />
  ) : (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <TitleWithPrevPageLink
            onPrevClick={() => {
              navigate({
                pathname: Paths.OnSiteInspectionInfo,
                search: loc.search
              });
            }}
            title={t('schedulingAssistantOnSiteInspectionPageTitle')}
          />

          <div className="mb-3" />

          <PageDescription className="mx-1 mb-3 mb-md-4">
            <Trans
              i18nKey="schedulingAssistantOnSiteInspectionPageDescription"
              tOptions={{ skipPostProcessing: true }}
            />
            <br />
            <span style={{ color: TechemTheme.colors.accent }}>
              <Trans
                i18nKey="schedulingAssistantOnSiteInspectionPageDescription2"
                tOptions={{ skipPostProcessing: true }}
              />
            </span>
          </PageDescription>

          <PreferredTimeSlotPicker
            submitChosenTimeslotsHandler={submitChosenTimeslotsHandler}
            onSetPreferredTimeslotButtonClick={onSetPreferredTimeslotButtonClick}
          />

          <Row className="justify-content-center">
            <Col xs="auto" className="mt-4 pt-3 pt-md-2">
              <Button
                size={SIZE.large}
                disabled={
                  !preferredTimeslots[0].date ||
                  !preferredTimeslots[0].timeOfDay ||
                  !preferredTimeslots[1].date ||
                  !preferredTimeslots[1].timeOfDay
                }
                isLoading={isSubmittingPreferredTimeslots}
                onClick={() => {
                  submitChosenTimeslotsHandler();
                }}
                additionalTestId="send-preferred-timeslots"
                trackClickUsingTestId={(buttonIdentifier) => {
                  TrackerUtil.trackBtnClick(
                    `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                    `button_${buttonIdentifier}`
                  );
                }}
              >
                <Trans i18nKey="schedulingAssistantOnSiteInspectionPageSendPreferredTimeslotsButtonText" />
              </Button>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />

      <TechemModal
        closeable
        header={<TechemModalHeader title={t('generalErrorModalTitle')} />}
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {t('generalErrorModalDescription1')}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" sm={11} className="copy mt-3 mb-2">
                {t('generalErrorModalDescription2')}
              </Col>
            </Row>
            <Row>
              <Col xs className="text-center copy">
                <HeaderView lg={4} md={7} sm="auto" xs="auto">
                  <PhoneSupportNoTitleView />
                </HeaderView>
              </Col>
            </Row>
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                'on-site-inspection-set-preferred-timeslots-error-modal'
              );
            }}
            primary={{
              text: t('generalErrorModalCloseButton'),
              handleOnClick: () => {
                handleErrorModalClose();
              },
              additionalTestId: 'close-error-modal'
            }}
          />
        }
        isOpen={errorModalOpen}
        modalTrackingId="on-site-inspection-set-preferred-timeslots-error-modal"
        onClose={() => {
          handleErrorModalClose();
        }}
        additionalTestId="on-site-inspection-set-preferred-timeslots-error-modal"
      />
    </div>
  );
};

export default connect(mapStateToProps)(OnSiteInspectionSchedulingAssistantPage);
