import { TechemTheme } from '@techem/techem-theme';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { TrackerUtil } from 'tracking/TrackerUtil';

const ProgressOverviewNavigationLink = () => {
  const navigate = useNavigate();
  const loc = useLocation();

  return (
    <Row className="justify-content-center mb-4 mb-md-3 pt-0 pt-md-3">
      <Col
        className="d-flex justify-content-center copy"
        style={{
          color: TechemTheme.colors.primary,
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
      >
        <span
          onClick={() => {
            TrackerUtil.trackLinkClick(
              `${TrackerUtil.getPageName()}_link_process-overview`,
              'link_process-overview'
            );

            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search
              },
              { state: { from: loc.pathname } }
            );
          }}
          data-gi="show-all-steps"
        >
          <Trans i18nKey="wizardStepsProcessOverviewButtonText" />
        </span>
      </Col>
    </Row>
  );
};

export default ProgressOverviewNavigationLink;
