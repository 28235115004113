import { createSlice } from '@reduxjs/toolkit';

// Use this part of the store to store the selected variants of A/B tests.
// This way it's easy to show/hide or enable/disable certain parts at any location
// of the application for tests.
export type MiscStoreData = {};
export const initialMiscStoreData: MiscStoreData = {};

export const miscSlice = createSlice({
  name: 'miscellaneous',
  initialState: initialMiscStoreData,
  reducers: {}
});

//export const {} = miscSlice.actions;

export default miscSlice.reducer;
