import React from 'react';

interface YoutubeEmbeddedVideoProps {
  embedId: string;
}

// See https://blog.theodo.com/2018/01/responsive-iframes-css-trick/ for instructions on responsive iFrame.
const YoutubeEmbeddedVideo: React.FC<YoutubeEmbeddedVideoProps> = ({ embedId }) => {
  return (
    <div
      className="video-responsive position-relative"
      style={{ overflow: 'hidden', paddingTop: '56.25%' }}
    >
      <iframe
        className="position-absolute w-100 h-100 top-0 start-0 border-0"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YoutubeEmbeddedVideo;
