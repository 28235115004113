// TODO: stop using polyfills as we walled off IE11 already
import './polyfills';
// Only using the bootstrap layouting mechanisms to avoid any stylings that come from baseweb anyways.
import 'bootstrap/dist/css/bootstrap-grid.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/UniversLTPro-45Light.ttf';
import { Provider } from 'react-redux';
import store from 'store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
