import React, { ChangeEvent, PropsWithChildren } from 'react';
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';

import { TechemTheme } from '@techem/techem-theme';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';

interface Props {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  labelPlacement?: any;
  additionalTestId?: string;
}

const Toggle: React.FC<PropsWithChildren<Props>> = ({
  checked,
  onChange,
  labelPlacement,
  additionalTestId,
  children
}) => {
  return (
    <Checkbox
      labelPlacement={labelPlacement ? labelPlacement : LABEL_PLACEMENT.left}
      checked={checked}
      checkmarkType={STYLE_TYPE.toggle_round}
      onChange={onChange}
      overrides={{
        Root: {
          props: {
            'data-gi': `toggle-item${additionalTestId !== undefined ? ' ' + additionalTestId : ''}`
          }
        },
        Toggle: {
          props: {
            'data-gi': 'toggle'
          },
          style: ({ $checked }) => ({
            width: '24px',
            height: '24px',
            backgroundColor: $checked ? TechemTheme.colors.toggleFill : TechemTheme.colors.mono500,
            border: $checked ? 'unset' : `1px solid ${TechemTheme.colors.mono300}`,
            transform: $checked ? 'translateX(28px)' : 'translateX(0px)'
          })
        },
        ToggleTrack: {
          style: ({ $checked }) => ({
            width: '60px',
            height: '30px',
            padding: '4px',
            backgroundColor: $checked ? TechemTheme.colors.primary : TechemTheme.colors.toggleFill,
            ...CSSAssignBorderRadius('18px'),
            outline: $checked ? 'unset' : `1px solid ${TechemTheme.colors.mono300}`
          })
        },
        Label: {
          style: ({ $theme }) => ({
            alignSelf: 'center'
          })
        }
      }}
    >
      {children}
    </Checkbox>
  );
};

export default Toggle;
