import React, { useState } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader
} from '@techem/ui-components';
import Title from 'components/Title';

import {
  ContactChannels,
  OrderType,
  setCustomerDataDirty,
  StoreObject
} from 'reducers/onboardingInformationSlice';
import DefaultLayout from 'layout/DefaultLayout';

import { TechemTheme } from '@techem/techem-theme';
import { TrackerUtil } from 'tracking/TrackerUtil';
import { OnboardingInformationClient } from 'clients';
import TechemBreadcrumb from 'components/navigation/TechemBreadcrumb';
import commentIcon from 'assets/icons/png/3x/icn-comment@3x.png';
import Toggle from 'components/Toggle';
import { RootState } from 'reducers/rootReducer';
import PhoneNumberValidator from 'services/tools/PhoneNumberValidator';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const ContactChannelsPage: React.FC<Props> = ({ onboardingInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [smsChecked, setSmsChecked] = useState(
    onboardingInfo!.userData!.contactChannels!.phoneNumberAllowedForContact
  );
  const [emailChecked] = useState(
    onboardingInfo!.userData!.contactChannels!.emailAddressAllowedForContact
  );
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const isValidMobileNumber = useSelector((state: RootState) => {
    const phoneNumber = state.onboardingInfo.userData.properties[0].orders.find(
      (order) => order.type === OrderType.TechemDirect
    )!.clientContactDetails.phoneNumber;
    return PhoneNumberValidator.isValidMobileNumber(phoneNumber);
  });

  const { numberOfProperties, propertyNumber } = useSelector((state: RootState) => {
    const numberOfProperties = state.onboardingInfo.userData.properties.length;
    return {
      numberOfProperties: numberOfProperties,
      propertyNumber:
        numberOfProperties === 1
          ? state.onboardingInfo.userData.properties[0].propertyNumber
          : 'NoDirectPropertyMatch'
    };
  });

  const handleToggle = (e: any) => {
    const isChecked = e.target.checked;

    TrackerUtil.trackBtnClick(
      `${TrackerUtil.getPageName()}_checkbox_sms-notifications`,
      'checkbox_sms-notifications'
    );

    let contactChannels: ContactChannels = {
      phoneNumberAllowedForContact: isChecked,
      emailAddressAllowedForContact: emailChecked
    };

    OnboardingInformationClient.setContactChannels(contactChannels)
      .then(() => {
        setSmsChecked(isChecked);
        dispatch(setCustomerDataDirty());
      })
      .catch(() => {
        setSmsChecked(!isChecked);
        setErrorModalOpen(true);
      });
  };
  return (
    <DefaultLayout>
      <Container>
        <Row>
          <TechemBreadcrumb
            items={[
              {
                title: 'Übersicht',
                href: numberOfProperties === 1 ? `/#/?propertyNumber=${propertyNumber}` : '/'
              }
            ]}
            currentPageTitle={t('ContactChannelsPageTitle')}
          />
        </Row>

        <Row className="mt-4">
          <Col xs={12} md={8} lg={7} className="mb-4">
            <Title title={t('ContactChannelsPageTitle')} className="mx-0 mb-4" centered={false} />

            <Col xs={12}>
              <Trans i18nKey={'ContactChannelsPageDescription'} />
              {isValidMobileNumber ? (
                <Trans i18nKey={'ContactChannelsPageActionDescription'} />
              ) : (
                <span style={{ color: TechemTheme.colors.accent }}>
                  <Trans i18nKey={'ContactChannelsPageActionDescriptionNoMobileNumber'} />
                </span>
              )}
            </Col>
          </Col>
        </Row>

        <Row>
          <Col>
            {isValidMobileNumber && (
              <Card
                style={{
                  maxWidth: '384px',
                  border: `1px solid ${TechemTheme.colors.mono300}`,
                  borderRadius: '12px'
                }}
              >
                <Card.Body className="p-4">
                  <img
                    src={commentIcon}
                    alt="comment"
                    className="top-0 start-0"
                    style={{ maxWidth: '32px', maxHeight: '32px' }}
                  />
                  <h4 className="mt-3 mb-2 pt-2" style={{ lineHeight: '32px' }}>
                    <Trans i18nKey={'ContactChannelsPageActionCardTitle'} />
                  </h4>
                  <p className="fs-6 mb-2" style={{ color: TechemTheme.colors.mono600 }}>
                    <Trans i18nKey={'ContactChannelsPageActionCardDescription'} />
                  </p>

                  <Col>
                    <div style={{ marginLeft: '-14px' }}>
                      <Toggle
                        checked={smsChecked}
                        onChange={(e: any) => {
                          TrackerUtil.trackBtnClick(
                            `${TrackerUtil.getPageName()}_checkbox_sms-notifications`,
                            'checkbox_sms-notifications'
                          );
                          handleToggle(e);
                        }}
                        additionalTestId="sms"
                      />
                    </div>
                  </Col>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>

      <TechemModal
        closeable
        header={<TechemModalHeader title={t('contactChannelsErrorModalTitle')} />}
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {t('contactChannelsErrorModalDescription')}
              </Col>
            </Row>
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                'update-contact-channels-error-modal'
              );
            }}
            primary={{
              text: t('contactChannelsErrorModalCloseButton'),
              handleOnClick: () => {
                setErrorModalOpen(false);
              },
              additionalTestId: 'close-error-modal'
            }}
          />
        }
        isOpen={errorModalOpen}
        modalTrackingId="update-contact-channels-error-modal"
        onClose={() => {
          setErrorModalOpen(false);
        }}
        additionalTestId="update-contact-channels-error"
      />
    </DefaultLayout>
  );
};

export default connect(mapStateToProps)(ContactChannelsPage);
