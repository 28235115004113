import React, { PropsWithChildren, useState } from 'react';
import { styled } from 'baseui';
import { Container, Col, Row } from 'react-bootstrap';
import PhoneSupportView from 'components/PhoneSupportView';
import { TechemTheme } from '@techem/techem-theme';
import logoRef from 'assets/images/techemlogo_final.svg';
import { md, minBreakpoints } from 'theme/mediaQueries';
import { useMediaQuery } from 'tools/hooks/useMediaQuery';
import { useStyletron } from 'baseui';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { connect } from 'react-redux';
import tenantIcon from 'assets/icons/png/3x/icn-user.png';
import burgerIcon from 'assets/icons/png/3x/icn-bars.png';
import Icon, { ICONSIZE } from 'components/Icon';
import CustomModal from 'components/modal/CustomModal';
import CustomModalFooter from 'components/modal/CustomModalFooter';
import { ModalHeader } from 'baseui/modal';
import { ModalBody } from 'react-bootstrap';
import { KIND, SIZE } from 'baseui/button';
import { useTranslation } from 'react-i18next';
import { TrackerUtil } from 'tracking/TrackerUtil';
import { Button } from '@techem/ui-components';
import store from 'store';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import AuthClient from 'clients/auth/AuthClient';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

const Logo = styled('img', {
  height: '27px',
  [md]: { height: '50px' }
});
const HeaderView = styled(Col, {
  height: '80px',
  backgroundColor: TechemTheme.colors.backgroundPrimary,
  [md]: { height: '100px' }
});
const WrapperView = styled('div', {
  zIndex: 1000,
  backgroundColor: TechemTheme.colors.backgroundPrimary
});

const HorizontalRulerContainer = styled(Container, {
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: TechemTheme.colors.backgroundSecondary
});

interface HeaderProps {
  ignoreSpacing?: boolean;
}

const Header: React.FC<PropsWithChildren<HeaderProps>> = ({ ignoreSpacing, children }) => {
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);
  const [css] = useStyletron();

  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);

  const selectPropertyClickedHandler = () => {
    TrackerUtil.trackLinkClick('header_link_menu-select-property', 'link_menu-select-property');
    navigate(Paths.PropertySelection);
  };

  const contactChannelClickedHandler = () => {
    TrackerUtil.trackLinkClick('header_link_menu-contact-channels', 'link_menu-contact-channels');
    navigate(Paths.ContactChannels);
  };

  const logoutHandler = () => {
    TrackerUtil.trackLinkClick('header_link_menu-logout', 'link_menu-logout');
    AuthClient.logout();
  };

  return (
    <WrapperView id="header" className={`${ignoreSpacing ? '' : 'mb-md-5 pb-md-5'} mb-3`}>
      <Container>
        <Row className="justify-content-between">
          <HeaderView xs className="d-flex px-3 pt-3 pt-md-0">
            <Logo src={logoRef} alt="techem logo" className="d-flex mx-0 my-auto" />
          </HeaderView>

          <HeaderView xs="auto" className="d-flex">
            <Row>
              <Col xs="auto" className="d-flex">
                <PhoneSupportView />
              </Col>
              {!!store.getState().onboardingInfo.userData.clientNumber && (
                <Col xs="auto" className="d-flex">
                  <div className="d-flex">
                    <div className="d-inline-flex w-100 h-auto my-auto mx-0">
                      <Icon
                        handleClick={() => {
                          TrackerUtil.trackBtnClick('header_button_menu', 'button_menu');
                          setMenuOpen(!menuOpen);
                        }}
                        size={isMdOrAbove ? ICONSIZE.xlarge : ICONSIZE.large}
                        iconSrc={isMdOrAbove ? tenantIcon : burgerIcon}
                        alt="menu icon"
                        additionalTestId="menu"
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </HeaderView>

          {menuOpen &&
            (isMdOrAbove ? (
              <Row data-gi="desktop-header-menu" className="copy">
                <ul
                  className={`${css({
                    listStyle: 'none',
                    gap: '50px',
                    borderTopWidth: '2px',
                    borderLeftWidth: '0',
                    borderBottomWidth: '2px',
                    borderRightWidth: '0',
                    borderStyle: 'solid',
                    borderColor: 'rgba(192,192,192,0.3)',
                    lineHeight: '55px'
                  })} ${menuOpen ? 'd-flex' : 'd-none'} justify-content-center`}
                >
                  <li
                    style={{
                      cursor: 'pointer',
                      fontWeight: loc.pathname === Paths.PropertySelection ? 'bold' : 'normal'
                    }}
                    onClick={() => {
                      selectPropertyClickedHandler();
                    }}
                    data-gi="property-selection-menu-item"
                  >
                    <span>{t('headerMenuPropertySelectionLabel')}</span>
                  </li>
                  <li
                    style={{
                      cursor: 'pointer',
                      fontWeight: loc.pathname === Paths.ContactChannels ? 'bold' : 'normal'
                    }}
                    onClick={() => {
                      contactChannelClickedHandler();
                    }}
                    data-gi="contact-channels-menu-item"
                  >
                    <span>{t('headerMenuContactChannelsLabel')}</span>
                  </li>
                  <li
                    className={css({ cursor: 'pointer' })}
                    onClick={() => {
                      logoutHandler();
                    }}
                    data-gi="logout-menu-item"
                  >
                    <span>{t('headerMenuLogoutLabel')}</span>
                  </li>
                </ul>
              </Row>
            ) : (
              <div>
                <CustomModal
                  additionalDialogStyles={{
                    margin: '0px !important',
                    minHeight: '100vh',
                    width: '100vw !important',
                    ...CSSAssignBorderRadius('0')
                  }}
                  additionalDialogContainerStyles={{
                    backgroundColor: 'white'
                  }}
                  isOpen={menuOpen}
                  onClose={() => {
                    setMenuOpen(false);
                  }}
                  additionalTestId="mobile-header-menu-modal"
                  modalTrackingId="mobile-header-menu-modal"
                >
                  <ModalHeader className="px-3" style={{ display: 'contents' }}>
                    {isMdOrAbove ? (
                      ''
                    ) : (
                      <HeaderView xs className="d-flex px-3 pt-3 pt-md-0">
                        <Logo src={logoRef} alt="techem logo" className="d-flex mx-0 my-auto" />
                      </HeaderView>
                    )}
                  </ModalHeader>
                  <ModalBody className="flex-shrink-1 flex-grow-1">
                    <Row>
                      <Col xs className="text-center copy">
                        <div>
                          <ul className="ps-0">
                            <div
                              className={`${css({
                                listStyle: 'none',
                                lineHeight: '3.5em',
                                cursor: 'pointer'
                              })} d-flex justify-content-between`}
                              onClick={() => {
                                selectPropertyClickedHandler();
                              }}
                            >
                              <li
                                className={
                                  loc.pathname === Paths.PropertySelection ? 'fw-bold' : 'fw-normal'
                                }
                                data-gi="property-selection-menu-item"
                              >
                                {t('headerMenuPropertySelectionLabel')}
                              </li>
                              <span
                                className="position-relative"
                                style={{
                                  color: 'red',
                                  fontSize: '2em',
                                  bottom: '4px'
                                }}
                              >
                                &#8594;
                              </span>
                            </div>
                            <div
                              className={`${css({
                                listStyle: 'none',
                                lineHeight: '3.5em',
                                cursor: 'pointer'
                              })} d-flex justify-content-between`}
                              onClick={() => {
                                contactChannelClickedHandler();
                              }}
                            >
                              <li
                                style={{
                                  fontWeight:
                                    loc.pathname === Paths.ContactChannels ? 'bold' : 'normal'
                                }}
                                data-gi="contact-channels-menu-item"
                              >
                                {t('headerMenuContactChannelsLabel')}
                              </li>
                              <span
                                className="position-relative fw-lighter"
                                style={{
                                  color: 'red',
                                  fontSize: '2em',
                                  bottom: '4px'
                                }}
                              >
                                &#8594;
                              </span>
                            </div>
                            <div
                              className={`${css({
                                listStyle: 'none',
                                lineHeight: '5.5em',
                                cursor: 'pointer'
                              })} d-flex justify-content-between`}
                              onClick={() => {
                                logoutHandler();
                              }}
                            >
                              <li data-gi="logout-menu-item">{t('headerMenuLogoutLabel')}</li>
                              <span
                                className="position-relative fw-lighter"
                                style={{
                                  color: 'red',
                                  fontSize: '2em',
                                  bottom: '4px'
                                }}
                              >
                                &#8594;
                              </span>
                            </div>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                  <CustomModalFooter className="position-absolute w-100 bottom-0 start-0 bg-transparent">
                    <Container>
                      <Row className="justify-content-end">
                        <Col xs={12}>
                          <Row className="justify-content-center">
                            <Col xs="auto">
                              <Button
                                size={SIZE.large}
                                kind={KIND.secondary}
                                onClick={() => {
                                  setMenuOpen(false);
                                }}
                                additionalStyleAsPlainCSS={() => ({
                                  fontSize: '1.1rem',
                                  color: 'rgb(226, 6, 19)'
                                })}
                                additionalTestId="close-header-menu-modal"
                                trackClickUsingTestId={(buttonIdentifier) => {
                                  TrackerUtil.trackBtnClick(
                                    `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                                    `button_${buttonIdentifier}`
                                  );
                                }}
                              >
                                {t('modalCloseButtonText')}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </CustomModalFooter>
                </CustomModal>
              </div>
            ))}
        </Row>
      </Container>

      <Container>{children}</Container>

      <HorizontalRulerContainer fluid />
    </WrapperView>
  );
};

export default connect(mapStateToProps)(Header);
