import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Panel, PanelOverrides } from 'baseui/accordion';

interface AnimatedExpandingViewProps extends PropsWithChildren<{}> {
  additionalTestId?: string;
  skipInitialFadeIn?: boolean;
}

const ANIMATION_DURATION = 300;

const panelOverrides: PanelOverrides = {
  Header: {
    style: { display: 'none' }
  },
  PanelContainer: {
    style: {
      borderBottomWidth: '0px'
    }
  },
  Content: {
    style: {
      transitionProperty: 'all',
      transitionTimingFunction: 'ease-in-out',
      transitionDuration: `${ANIMATION_DURATION}ms`,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit'
    }
  },
  ContentAnimationContainer: {
    style: {
      transitionProperty: 'all',
      transitionTimingFunction: 'ease-in-out',
      transitionDuration: `${ANIMATION_DURATION}ms`
    }
  }
};

/*

  For reference - whenever we talk about 'child', we mean the default 'children' element 
  for any element(s) that reside within the two <AnimatedExpandingView> tags.
  
  HowTo: Simply put this component around any (optional) child JSX element.
  
  !! IMPORTANT !!
  The direct child must (!) have a key property. This ensures that React updates an existing child 
  instead of recognizing an updated element as a new one. Whenever the child get replaced, 
  the old one fades out and the new one fades in. When the key prop is missing, any component update 
  also leads to this component recognizing the updated element as a new one, 
  thus fading out and in the exact same component.

*/
const AnimatedExpandingView = ({
  additionalTestId,
  skipInitialFadeIn = false,
  children
}: AnimatedExpandingViewProps) => {
  const [childToFadeOutKey, setChildToFadeOutKey] = useState(Math.random());
  const [childToFadeInKey, setChildToFadeInKey] = useState(Math.random());

  const [childToFadeOut, setChildToFadeOut] = useState<ReactNode | undefined>(undefined);
  const [childToFadeOutIsExpanded, setChildToFadeOutIsExpanded] = useState(false);
  const [childToFadeIn, setChildToFadeIn] = useState<ReactNode | undefined>(undefined);
  const [childToFadeInIsExpanded, setChildToFadeInIsExpanded] = useState(skipInitialFadeIn);

  // Determine whether the new child element is to be considered "new".
  const isNewChildToBeAnimated = (prevChildElement: any, newChildElement: any) => {
    if (!newChildElement || !prevChildElement) return true;
    return (
      (prevChildElement as { type: any }).type !== (newChildElement as { type: any }).type ||
      JSON.stringify((prevChildElement as { key: any }).key) !==
        JSON.stringify((newChildElement as { key: any }).key)
    );
  };

  useEffect(() => {
    /* If the new child element is determined as new, we 

      - set key and child element for previous value to ensure it is being faded out correctly.
      - set a new key and a new child element to be faded in instead.

      This ensures smooth transitioning in every case (new child, child gone, child was switched).

    */
    if (isNewChildToBeAnimated(childToFadeIn, children)) {
      if (childToFadeIn) {
        setChildToFadeOutKey(childToFadeInKey);
        setChildToFadeOut(childToFadeIn);
        setChildToFadeOutIsExpanded(true);

        // Mini-timeout to ensure the element is first not expanded initially, but expands with animation right after.
        // This tells the Panel the update with the transition and not remove the element right away.
        setTimeout(() => {
          setChildToFadeOutIsExpanded(false);

          // After  the animation has finished, we can safely remove the actual element
          // from the DOM without any visual "jumping" when removing an element suddenly.
          setTimeout(() => {
            setChildToFadeOut(<></>);
          }, ANIMATION_DURATION);
        }, 10);

        setChildToFadeInKey(Math.random());
      }

      setChildToFadeIn(children);

      // Quick return in case of initially setting child with animation turned off.
      if (skipInitialFadeIn && !!children && !childToFadeIn) return;

      // Mini timeout to ensure the new children element is not expanded initially, but expands with animation right after.
      setChildToFadeInIsExpanded(false);
      setTimeout(() => {
        setChildToFadeInIsExpanded(true);
      }, 10);
    } else {
      setChildToFadeIn(children);
    }
  }, [children, childToFadeInKey, childToFadeIn, skipInitialFadeIn]);

  return (
    <div
      data-gi={`animated-expanding-view${
        additionalTestId !== undefined ? ' ' + additionalTestId : ''
      }`}
    >
      <Panel
        key={childToFadeOutKey}
        expanded={childToFadeOutIsExpanded}
        title={<></>}
        overrides={panelOverrides}
      >
        {childToFadeOut}
      </Panel>

      <Panel
        key={childToFadeInKey}
        expanded={childToFadeInIsExpanded}
        title={<></>}
        overrides={panelOverrides}
      >
        {childToFadeIn}
      </Panel>
    </div>
  );
};

export default AnimatedExpandingView;
