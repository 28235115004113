// Functions to work with facebook's pixel API
import { FB_PIXEL_ID } from 'config';
import { defineFbqFunctionGlobally } from './fbPixelPrepare';

export const isFBPixelLoaded = (): boolean => {
  return !!window.fbq;
};

/**
 * Loads facebook's pixel script into the current DOM.
 * Returns a promise which resolves to {@code true} if the script needs to be initialized after.
 */
export function loadFBPixel(fbPixelId?: string): Promise<boolean> {
  if (!fbPixelId) {
    // don't try to load it if no fbPixelId got provided
    console.error('Failed to load fb pixel: No pixel id provided!');
    return Promise.resolve(false);
  }

  if (isFBPixelLoaded()) {
    console.warn('fb pixel script is already attached to DOM');
    return Promise.resolve(false);
  }

  return new Promise((resolve, reject) => {
    defineFbqFunctionGlobally();

    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    script.type = 'text/plain';
    script.async = true;
    script.setAttribute('data-cookieconsent', 'marketing');
    script.onerror = function () {
      reject('Failed to load fb pixel script');
    };
    document.head.appendChild(script);
    resolve(true);
  });
}

/**
 * Init facebook's pixel script.
 * Returns a promise which resolves to {@code true} if fb pixel is ready to use.
 */
export function initFBPixel(initNeeded: boolean, fbPixelId?: string): Promise<boolean> {
  if (!initNeeded) {
    return Promise.resolve(isFBPixelLoaded());
  }

  return new Promise((resolve) => {
    // https://developers.facebook.com/docs/facebook-pixel/implementation/tag_spa
    // Facebook's script listens on history api changes by default.
    // We deactivate it to have it aligned with our already existing "path change"-based
    // page view tracking.
    // @ts-ignore
    fbq.disablePushState = true;

    fbq('init', fbPixelId!);

    resolve(true);
  });
}

export const trackPage = () => {
  if (!FB_PIXEL_ID) {
    console.info('FB_PIXEL_ID is not set. trackPage was triggered');
  }
  if (!isFBPixelLoaded()) {
    return;
  }

  // assumption: fbq handles URLs and paths on its own based on current history api state
  window.fbq('track', 'PageView');
};

export const trackGenericEvent = (
  pageName: string,
  action: string,
  category: string,
  label?: string,
  value?: string
) => {
  if (!FB_PIXEL_ID) {
    console.info('FB_PIXEL_ID is not set. trackEvent was triggered', {
      action,
      category,
      label,
      value
    });
  }
  if (!isFBPixelLoaded()) {
    return;
  }

  // @ts-ignore
  window.fbq('trackCustom', 'generic', {
    // @ts-ignore
    pageName,
    // @ts-ignore
    action,
    category,
    label,
    // @ts-ignore
    value
  });
};
