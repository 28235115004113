export const OptionKeys = {
  CentralHeatingAvailable: {
    Yes: 'centralheating_yes',
    No: 'centralheating_no'
  },
  HotWaterDeliveryCentral: {
    Yes: 'hotwaterdeliverycentral_yes',
    No: 'hotwaterdeliverycentral_no'
  },
  HeatingType: {
    RadiatorOnly: 'radiator',
    UnderFloorHeatingOnly: 'underfloor_heating',
    RadiatorAndUnderfloorHeating: 'radiator_underfloor_heating'
  },
  HeatingSources: {
    LongDistance: 'long_distance_heating',
    Gas: 'gas',
    HeatingOil: 'heating_oil',
    Pellets: 'pellets',
    Solar: 'solar',
    HeatPump: 'heat_pump',
    Other: 'other'
  },
  ContactOptions: {
    Male: 'MALE',
    Female: 'FEMALE'
  }
};

const translationKeyMapping = [
  {
    optionKey: OptionKeys.CentralHeatingAvailable.Yes,
    translationKey: 'TDOrderOverviewPageContentCentralHeatingCentralValue'
  },
  {
    optionKey: OptionKeys.CentralHeatingAvailable.No,
    translationKey: 'TDOrderOverviewPageContentCentralHeatingNotCentralValue'
  },
  {
    optionKey: OptionKeys.HotWaterDeliveryCentral.Yes,
    translationKey: 'TDOrderOverviewPageContentHotWaterDeliveryCentralValue'
  },
  {
    optionKey: OptionKeys.HotWaterDeliveryCentral.No,
    translationKey: 'TDOrderOverviewPageContentHotWaterDeliveryNotCentralValue'
  },
  {
    optionKey: OptionKeys.HeatingType.RadiatorOnly,
    translationKey: 'TDOrderOverviewPageContentHeatingTypeRadiatorValue'
  },
  {
    optionKey: OptionKeys.HeatingType.UnderFloorHeatingOnly,
    translationKey: 'TDOrderOverviewPageContentHeatingTypeUnderfloorValue'
  },
  {
    optionKey: OptionKeys.HeatingType.RadiatorAndUnderfloorHeating,
    translationKey: 'TDOrderOverviewPageContentHeatingTypeBothValue'
  },
  {
    optionKey: OptionKeys.HeatingSources.LongDistance,
    translationKey: 'TDOrderOverviewPageContentEnergySourcesLongDistanceHeatingValue'
  },
  {
    optionKey: OptionKeys.HeatingSources.Gas,
    translationKey: 'TDOrderOverviewPageContentEnergySourcesGasValue'
  },
  {
    optionKey: OptionKeys.HeatingSources.HeatingOil,
    translationKey: 'TDOrderOverviewPageContentEnergySourcesHeatingOilValue'
  },
  {
    optionKey: OptionKeys.HeatingSources.Pellets,
    translationKey: 'TDOrderOverviewPageContentEnergySourcesPelletsValue'
  },
  {
    optionKey: OptionKeys.HeatingSources.Solar,
    translationKey: 'TDOrderOverviewPageContentEnergySourcesSolarValue'
  },
  {
    optionKey: OptionKeys.HeatingSources.HeatPump,
    translationKey: 'TDOrderOverviewPageContentEnergySourcesHeatPumpValue'
  },
  {
    optionKey: OptionKeys.HeatingSources.Other,
    translationKey: 'TDOrderOverviewPageContentEnergySourcesOtherValue'
  },
  {
    optionKey: OptionKeys.ContactOptions.Male,
    translationKey: 'maleSalutationValue'
  },
  {
    optionKey: OptionKeys.ContactOptions.Female,
    translationKey: 'femaleSalutationValue'
  }
];

export function translationKeyForFixedOption(optionKey: string) {
  var entry = translationKeyMapping.find((item) => item.optionKey === optionKey);
  if (entry) {
    return entry.translationKey;
  }
  console.warn('Translation Key Not set for fixed option ' + optionKey + '!');
  return '';
}
