import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RouteManager } from 'Routes';

import { TrackerUtil } from 'tracking/TrackerUtil';

export default function usePathBasedPageTracking() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (RouteManager.isValidPath(pathname)) {
      TrackerUtil.trackPage(TrackerUtil.getPageName());
    }
  }, [pathname]);

  return null;
}
