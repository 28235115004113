import React, { useEffect, useState } from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import Title from 'components/Title';
import PageDescription from 'components/PageDescription';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { OBCInfoStoreData, OBCSetTimeslotResultOutcome } from 'reducers/OBCSlice';
import { OptionKeys, translationKeyForFixedOption } from 'tools/FixedOptionMapper';
import { TechemTheme } from '@techem/techem-theme';
import Calendar from 'components/Calendar';
import { DateDefaultLocale, formatDate, formatTime } from 'tools/dev/DateTimeFormatHelper';
import LoadingSpinner from 'components/LoadingSpinner';
import {
  Button,
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader
} from '@techem/ui-components';
import { SIZE } from 'baseui/button';
import { Paths } from 'Routes';
import { TrackerUtil } from 'tracking/TrackerUtil';
import PhoneSupportNoTitleView from 'components/PhoneSupportNoTitleView';
import { styled } from 'styletron-react';
import { PublicHolidays } from 'services/tools/PublicHolidayProvider';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import TimeSlotList from 'components/timeslot/TimeSlotList';
import { OBCClient } from 'clients';
import AnimatedExpandingView from 'components/AnimatedExpandingView';
import { TFunction } from 'i18next';

export interface OBCTimeSlotDTO {
  from: Date;
  till: Date;
}
export interface OBCTimeSlot extends OBCTimeSlotDTO {}
export interface OBCTimeslotDataItem {
  date: Date;
  availableTimeslots: OBCTimeSlot[];
}

const mapStateToProps = (state: any) => {
  return {
    OBCInfo: state.OBCInfo
  };
};

const setHref = (toUrl: string) => {
  window.location.href = `${window.location.origin}/#${toUrl}${
    window.location.hash.split('?').length > 1 ? '?' + window.location.hash.split('?')[1] : ''
  }`;
};
function CustomRedirect(props: any) {
  setTimeout(() => {
    setHref(props.url);
  }, 100); // Add some minor time delay to ensure tracking is being fired prior to this component being unmounted.
  return null;
}

interface Props {
  OBCInfo?: OBCInfoStoreData;
}

const HeaderView = styled(Col, {
  height: '100px',
  '@media (max-width: 767px)': {
    height: '80px'
  },
  backgroundColor: TechemTheme.colors.backgroundPrimary
});

/**
 * Builds a welcome message based on personal information and a translation function. If no personal information is provided
 * the welcome message will be an empty string.
 *
 * @param {{ firstName: string, lastName: string, gender: string }} personalInformation - Personal information of the user.
 * @param {TFunction} t - The translation function.
 * @returns {string} The constructed welcome message.
 */
function buildFullSalutation(
  t: TFunction,
  personalInformation?: { firstName: string; lastName: string; gender: string }
): string {
  // return empty salutation if no personal information is provided
  if (!personalInformation) return '';

  const { firstName, lastName, gender } = personalInformation;

  // Resolve salutation for MALE and FEMALE, empty string otherwise
  const salutation = gender ? t(translationKeyForFixedOption(gender!)) : '';

  const fullName = [OptionKeys.ContactOptions.Male, OptionKeys.ContactOptions.Female].includes(
    gender
  )
    ? buildName(lastName)
    : buildName(lastName, firstName);

  return `${salutation} ${fullName}`;
}

/**
 * Builds a full name based on the provided last name and optional first name.
 *
 * @param {string} lastname - The last name of the person.
 * @param {string} [firstname] - The optional first name of the person.
 * @returns {string} The formatted full name.
 */
function buildName(lastname: string, firstname?: string): string {
  return `${firstname ? `${firstname} ${lastname}` : lastname}`;
}

const OBCSchedulingAssistantPage: React.FC<Props> = ({ OBCInfo }) => {
  const { t } = useTranslation();

  const [isLoadingTimeslotData, setIsLoadingTimeslotData] = useState(true);
  const [dateCandidate, setDateCandidate] = useState<Date | null | undefined>(undefined);
  const [timeslotCandidate, setTimeslotCandidate] = useState<OBCTimeSlot | undefined>(undefined);

  const salutation = buildFullSalutation(t, OBCInfo!.personalInformation);

  const preferredTimeslotDaysInAdvance = 0;
  const selectionPeriodInDays = 14;
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());

  const [availableTimeslots, setAvailableTimeslots] = useState<OBCTimeslotDataItem[]>([]);
  const [unavailableDays, setUnavailableDays] = useState<Date[]>([]);

  const [confirmTimeslotModalOpen, setConfirmTimeslotModalOpen] = useState(false);
  const [isSubmittingTimeslot, setIsSubmittingTimeslot] = useState(false);

  const [errorLoadingTimeslots, setErrorLoadingTimeslots] = useState<{
    show: boolean;
  } | null>(null);
  const [errorSendingTimeslot, setErrorSendingTimeslot] = useState<{
    errorType: string;
    show: boolean;
  } | null>(null);

  useEffect(() => {
    // Quick exit if token is not in store (yet).
    if (!OBCInfo || !OBCInfo!.token) {
      return;
    }

    let newAvailableTimeslots: OBCTimeslotDataItem[] = [];
    let newUnavailableDays: Date[] = [];
    let newMinDate = new Date(
      new Date().setHours(0, 0, 0, 0) + (preferredTimeslotDaysInAdvance + 1) * 86400000
    );
    // Ensure calendar starts at the next free workday.
    while (newMinDate.getDay() === 0 || newMinDate.getDay() === 6) {
      newMinDate.setDate(newMinDate.getDate() + 1);
    }
    setMinDate(newMinDate);

    let newMaxDate = new Date(
      new Date(newMinDate).setDate(newMinDate.getDate() + selectionPeriodInDays)
    );
    setMaxDate(newMaxDate);

    setIsLoadingTimeslotData(true);
    OBCClient.getOBCAvailableTimeslots(OBCInfo!.token!, newMinDate, newMaxDate)
      .then((response) => {
        const availableSlots = response.data.availableSlots;

        if (availableSlots.length > 0) {
          newMinDate = new Date(availableSlots[0].from);
          newMinDate.setHours(0, 0, 0, 0);
          setMinDate(newMinDate);

          newMaxDate = new Date(availableSlots[availableSlots.length - 1].from);
          newMaxDate.setHours(0, 0, 0, 0);
          setMaxDate(newMaxDate);
        }

        for (
          let i = newMinDate;
          i <= newMaxDate;
          i = new Date(new Date(i).setDate(i.getDate() + 1))
        ) {
          let timeSlotsForDay = response.data.availableSlots
            .map((as: { from: string; to: string }) => {
              return { from: new Date(as.from), till: new Date(as.to) };
            })
            .filter((item: OBCTimeSlot) => {
              let from = item.from;
              return (
                from.getFullYear() === i.getFullYear() &&
                from.getMonth() === i.getMonth() &&
                from.getDate() === i.getDate()
              );
            });

          if (timeSlotsForDay.length > 0) {
            newAvailableTimeslots = [
              ...newAvailableTimeslots,
              { date: i, availableTimeslots: timeSlotsForDay }
            ];
            setAvailableTimeslots(newAvailableTimeslots);
          } else {
            newUnavailableDays = [...newUnavailableDays, i];
            setUnavailableDays(newUnavailableDays);
          }
        }
        setIsLoadingTimeslotData(false);
      })
      .catch((error) => {
        for (let i = newMinDate; i <= newMaxDate; i = new Date(i.valueOf() + 86400000)) {
          newUnavailableDays = [...newUnavailableDays, i];
          setUnavailableDays(newUnavailableDays);
        }
        console.error(error);
        setErrorLoadingTimeslots({
          show: true
        });
        setIsLoadingTimeslotData(false);
      });
  }, [OBCInfo]);

  const handleTimeslotSubmission = () => {
    setIsSubmittingTimeslot(true);
    OBCClient.setOBCTimeslot(OBCInfo!.token!, timeslotCandidate!)
      .then((response) => {
        if (response.data.outcome === OBCSetTimeslotResultOutcome.Success) {
          // Simply go to Jumppad and load updated data there automatically.
          setHref(Paths.OBC);
        } else {
          setIsSubmittingTimeslot(false);
          setConfirmTimeslotModalOpen(false);
          setErrorSendingTimeslot({
            errorType: response.data.outcome,
            show: true
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorSendingTimeslot({
          errorType: `${error}`,
          show: true
        });
        setIsSubmittingTimeslot(false);
        setConfirmTimeslotModalOpen(false);
      });
  };

  return !!OBCInfo && !!OBCInfo.personalInformation ? (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={6} className="mb-4 px-3">
              <Title
                className="mx-1 mb-2 text-center justify-content-center"
                title={t('OBCSchedulingAssistantPageTitle')}
              />

              <PageDescription className="mx-1 text-center">
                <Trans
                  i18nKey="OBCSchedulingAssistantPageDescription"
                  values={{
                    salutation: salutation
                  }}
                />
              </PageDescription>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8}>
              {isLoadingTimeslotData ? (
                <div className="d-flex w-100 h-100" style={{ transitionDuration: '350ms' }}>
                  <div className="m-auto">
                    <LoadingSpinner />
                  </div>
                </div>
              ) : (
                <>
                  <Row
                    className="justify-content-center"
                    style={{
                      backgroundColor: TechemTheme.colors.backgroundSecondary,
                      ...CSSAssignBorderRadius('8px')
                    }}
                  >
                    <Col
                      xs="auto"
                      className="m-3"
                      style={{
                        backgroundColor: TechemTheme.colors.backgroundPrimary,
                        ...CSSAssignBorderRadius('8px')
                      }}
                    >
                      <Calendar
                        minDate={minDate}
                        maxDate={maxDate}
                        locale={DateDefaultLocale}
                        excludeDates={[...unavailableDays, ...PublicHolidays]}
                        onChange={(dates) => {
                          setDateCandidate(Array.isArray(dates.date) ? dates.date[0] : dates.date);
                          setTimeslotCandidate(undefined);
                        }}
                        filterDate={(date: Date) => date.getDay() !== 0 && date.getDay() !== 6} // Make weekends not selectable
                        hideWeekend
                      />
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col xs className="m-3 px-0">
                      {!!dateCandidate ? (
                        <AnimatedExpandingView>
                          <TimeSlotList
                            key={dateCandidate.toString()}
                            availableTimeSlots={availableTimeslots}
                            dateCandidate={dateCandidate}
                            timeSlotCandidate={timeslotCandidate}
                            handleSetTimeSlotCandidate={setTimeslotCandidate}
                          />
                        </AnimatedExpandingView>
                      ) : (
                        <Row className="justify-content-center">
                          <Col xs={12} md={6} className="text-center copy">
                            <Trans i18nKey="OBCSchedulingAssistantSelectDateHint" />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col xs="auto" className="m-3 mb-0">
                      <Button
                        size={SIZE.large}
                        onClick={() => {
                          setConfirmTimeslotModalOpen(true);
                        }}
                        disabled={!timeslotCandidate}
                        additionalTestId="book-timeslot"
                        trackClickUsingTestId={(buttonIdentifier) => {
                          TrackerUtil.trackBtnClick(
                            `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                            `button_${buttonIdentifier}`
                          );
                        }}
                      >
                        <Trans i18nKey="OBCSchedulingAssistantSendTimeslotButtonText"></Trans>
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>

          <TechemModal
            header={
              <TechemModalHeader
                title={t('OBCSchedulingAssistantConfirmationModalChosenDatesTitle')}
              />
            }
            body={
              <TechemModalBody>
                <Row className="justify-content-center">
                  <Col xs={10} className="text-center copy">
                    <Trans i18nKey="OBCSchedulingAssistantConfirmationModalChosenDatesDescription" />
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col xs="auto" className="text-center copy-l">
                    <Trans
                      i18nKey="OBCSchedulingAssistantConfirmationModalChosenDatesContent"
                      values={{
                        dateTerm: !!timeslotCandidate ? formatDate(timeslotCandidate!.from) : '',
                        timeStartTerm: !!timeslotCandidate
                          ? formatTime(timeslotCandidate!.from, true)
                          : '',
                        timeEndTerm: !!timeslotCandidate
                          ? formatTime(timeslotCandidate!.till, true)
                          : ''
                      }}
                    />
                  </Col>
                </Row>
              </TechemModalBody>
            }
            footer={
              <TechemModalButtonFooter
                trackClickUsingTestId={(buttonIdentifier: string) => {
                  TrackerUtil.trackBtnClickByIdentifier(buttonIdentifier, 'modal-confirm-timeslot');
                }}
                primary={{
                  text: t('OBCSchedulingAssistantConfirmationModalSendTimeslotButtonText'),
                  handleOnClick: () => {
                    handleTimeslotSubmission();
                  },
                  additionalTestId: 'confirm-timeslot',
                  isLoading: isSubmittingTimeslot
                }}
                secondary={{
                  text: t('OBCSchedulingAssistantConfirmationModalCancelButtonText'),
                  handleOnClick: () => {
                    setConfirmTimeslotModalOpen(false);
                  },
                  additionalTestId: 'cancel'
                }}
              />
            }
            isOpen={confirmTimeslotModalOpen}
            modalTrackingId="modal-confirm-timeslot"
            onClose={() => {
              setConfirmTimeslotModalOpen(false);
            }}
            additionalTestId="confirmation-modal"
          />
        </Container>
      </main>

      <Footer />

      <TechemModal
        closeable
        header={
          <TechemModalHeader
            title={
              errorLoadingTimeslots
                ? t('OBCSchedulingAssistantErrorLoadingAvailableSlotsModalTitle')
                : ''
            }
          />
        }
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {errorLoadingTimeslots && (
                  <Trans
                    i18nKey={'OBCSchedulingAssistantErrorLoadingAvailableSlotsModalContent1'}
                    tOptions={{ skipPostProcessing: true }}
                  />
                )}
              </Col>
            </Row>
            {errorLoadingTimeslots && (
              <Row>
                <Col xs className="text-center copy">
                  <HeaderView lg={4} md={7} sm="auto" xs="auto" className="d-flex w-100">
                    <PhoneSupportNoTitleView />
                  </HeaderView>
                </Col>
              </Row>
            )}
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                'load-available-timeslots-error-modal'
              );
            }}
            primary={{
              text: errorLoadingTimeslots
                ? t('OBCSchedulingAssistantErrorLoadingAvailableSlotsModalButtonText')
                : '',
              handleOnClick: () => {
                setErrorLoadingTimeslots({
                  show: false
                });

                if (!!errorLoadingTimeslots) {
                  // Refresh the page, i.e. just go back to Jumppad.
                  setHref(Paths.OBC);
                } else {
                  setTimeout(() => {
                    setErrorLoadingTimeslots(null);
                  }, 1500);
                }
              },
              additionalTestId: 'close-error-modal'
            }}
          />
        }
        isOpen={!!errorLoadingTimeslots && errorLoadingTimeslots.show}
        modalTrackingId="load-available-timeslots-error-modal"
        onClose={() => {
          setErrorLoadingTimeslots({
            show: false
          });

          if (!!errorLoadingTimeslots) {
            // Refresh the page, i.e. just go back to Jumppad.
            setHref(Paths.OBC);
          } else {
            setTimeout(() => {
              setErrorLoadingTimeslots(null);
            }, 1500);
          }
        }}
        additionalTestId="load-available-timeslots-error-modal"
      />

      <TechemModal
        closeable
        header={
          <TechemModalHeader
            title={
              errorSendingTimeslot
                ? t(
                    errorSendingTimeslot!.errorType ===
                      OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                      ? 'OBCSchedulingAssistantErrorAlreadyBookedModalTitle'
                      : 'generalErrorModalTitle'
                  )
                : ''
            }
          />
        }
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {errorSendingTimeslot && (
                  <Trans
                    i18nKey={
                      errorSendingTimeslot!.errorType ===
                      OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                        ? 'OBCSchedulingAssistantErrorAlreadyBookedModalContent1'
                        : 'generalErrorModalDescription1'
                    }
                    tOptions={{ skipPostProcessing: true }}
                  />
                )}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" sm={11} className="copy mt-3 mb-2">
                {errorSendingTimeslot && (
                  <Trans
                    i18nKey={
                      errorSendingTimeslot!.errorType ===
                      OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                        ? 'OBCSchedulingAssistantErrorAlreadyBookedModalContent2'
                        : 'generalErrorModalDescription2'
                    }
                    tOptions={{ skipPostProcessing: true }}
                  />
                )}
              </Col>
            </Row>
            {errorSendingTimeslot &&
              errorSendingTimeslot!.errorType !==
                OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked && (
                <Row>
                  <Col xs className="text-center copy">
                    <HeaderView lg={4} md={7} sm="auto" xs="auto" className="d-flex w-100">
                      <PhoneSupportNoTitleView />
                    </HeaderView>
                  </Col>
                </Row>
              )}
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(buttonIdentifier, 'send-timeslot-error-modal');
            }}
            primary={{
              text: errorSendingTimeslot
                ? t(
                    errorSendingTimeslot!.errorType ===
                      OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                      ? 'OBCSchedulingAssistantErrorAlreadyBookedModalButtonText'
                      : 'generalErrorModalCloseButton'
                  )
                : '',
              handleOnClick: () => {
                setErrorSendingTimeslot({
                  errorType: errorSendingTimeslot!.errorType,
                  show: false
                });

                if (
                  !!errorSendingTimeslot &&
                  errorSendingTimeslot!.errorType ===
                    OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                ) {
                  // Refresh the page, i.e. just go back to Jumppad.
                  setHref(Paths.OBC);
                } else {
                  setTimeout(() => {
                    setErrorSendingTimeslot(null);
                  }, 1500);
                }
              },
              additionalTestId: 'close-error-modal'
            }}
          />
        }
        isOpen={
          !!errorSendingTimeslot && !!errorSendingTimeslot.errorType && errorSendingTimeslot.show
        }
        modalTrackingId="send-timeslot-error-modal"
        onClose={() => {
          setErrorSendingTimeslot({
            errorType: errorSendingTimeslot!.errorType,
            show: false
          });
          setTimeout(() => {
            setErrorSendingTimeslot(null);
          }, 1500);
        }}
        additionalTestId="send-timeslot-error-modal"
      />
    </div>
  ) : (
    <CustomRedirect url={Paths.OBC} />
  );
};

export default connect(mapStateToProps)(OBCSchedulingAssistantPage);
