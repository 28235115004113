import { SHOULD_USE_MOCKED_DATA } from 'config';
import { apiClient } from 'lib/axios';
import { Address, ContactChannels, PersonalData } from 'reducers/onboardingInformationSlice';
import { CustomerDTO } from 'services/DTO';
import { MockedCustomerData } from 'services/MockedData';
import mapCustomerDataResult from './OnboardingInformationUtils';

const Endpoints = {
  CustomerData: '/onboarding/customer',
  ContactChannel: '/onboarding/customer/communicationPreferences',

  InstallationKitInstalled: 'onboarding/installationKitInstalled/',
  InstallationKitShippingAddress: '/onboarding/installationKitShippingAddress/',

  TenantInfoInstallationDocument: '/onboarding/tenantAnnouncement/'
};

export const OnboardingInformationClient = {
  async getCustomerData() {
    return SHOULD_USE_MOCKED_DATA ? getCustomerDataFromMock() : getCustomerDataFromAPI();
  },

  setContactChannels(contactChannels: ContactChannels) {
    return apiClient.post(`${Endpoints.ContactChannel}`, contactChannels);
  },

  setContactChannelsMocked(contactChannels: ContactChannels) {
    return new Promise((resolve: (value: any) => void, reject) => {
      setTimeout(function () {
        resolve({ data: { status: 'ok' } });
      }, 1500);
    });
  },

  setInstallationKitShippingAddress(
    orderId: string,
    personalInformation: PersonalData,
    address: Address
  ) {
    const payload = {
      ...personalInformation,
      ...address
    };
    return apiClient.post(`${Endpoints.InstallationKitShippingAddress}${orderId}`, payload);
  },

  setInstallationKitInstalled(orderId: string) {
    return apiClient.post(`${Endpoints.InstallationKitInstalled}${orderId}`);
  },

  getTenantInstallationInfoDocument(orderId: string) {
    // Don't do a download but instead simply open a new tag with the document.
    window
      .open(
        `${process.env.REACT_APP_API_BASE_URL}${Endpoints.TenantInfoInstallationDocument}${orderId}`,
        '_blank'
      )
      ?.focus();
  }
};

/**
 * Retrieves customer data from the API.
 * @param t The translation function.
 * @returns The customer data.
 */
async function getCustomerDataFromAPI() {
  // Make a GET request to the API to retrieve the customer data
  const result = await apiClient.get<CustomerDTO>(`${Endpoints.CustomerData}`);

  // Map the result to the correct format and return it
  return mapCustomerDataResult(result.data);
}

/**
 * Retrieves mock customer data.
 * @param t The translation function.
 * @returns The mock customer data.
 */
async function getCustomerDataFromMock() {
  // Return a promise that resolves with the mock customer data after a delay
  return new Promise((resolve: (value: CustomerDTO) => void, reject) => {
    setTimeout(function () {
      // Map the mock customer data to the correct format and resolve the promise with it
      /*reject({
        response: { 
          message: "Blah", 
          status: 404
        },
      });*/
      resolve(mapCustomerDataResult(MockedCustomerData));
    }, 1500);
  });
}
