import React, { useState } from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import WizardStepsForOverallProgress from 'components/WizardStepsForOverallProgress';
import { Row, Col, Container } from 'react-bootstrap';
import { TechemTheme } from '@techem/techem-theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { DetailedEventOverview } from 'components/DetailedEventOverview';
import Title from 'components/Title';
import PageDescription from 'components/PageDescription';
import { Trans, useTranslation } from 'react-i18next';
import { OrderType, StoreObject } from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';
import {
  Button,
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader
} from '@techem/ui-components';
import { SIZE } from 'baseui/button';
import { useMediaQuery } from 'tools/hooks/useMediaQuery';
import { minBreakpoints } from 'theme/mediaQueries';
import store from 'store';
import editIcon from 'assets/icons/png/3x/icn-edit@3x-rot.png';
import Icon, { ICONSIZE } from 'components/Icon';
import { RefreshAfterApiCall } from 'components/ApplicationInterceptor';
import PhoneSupportNoTitleView from 'components/PhoneSupportNoTitleView';
import { styled } from 'styletron-react';
import { translationKeyForFixedOption } from 'tools/FixedOptionMapper';
import { TrackerUtil } from 'tracking/TrackerUtil';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import { OnboardingInformationClient } from 'clients';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const HeaderView = styled(Col, {
  height: '100px',
  '@media (max-width: 767px)': {
    height: '80px'
  },
  backgroundColor: TechemTheme.colors.backgroundPrimary
});

const InstallationKitInfoPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  const [taskShippingInProgress, setTaskShippingInProgress] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleSendInstallationKitButtonClick = () => {
    setTaskShippingInProgress(true);
    OnboardingInformationClient.setInstallationKitShippingAddress(
      onboardingInfo!.userData.properties[onboardingInfo!.selectedPropertyIndex].orders.find(
        (order) => order.type === OrderType.TechemDirect
      )!.orderNumber,
      {
        gender: shippingAddress.gender,
        firstName: shippingAddress.firstName,
        lastName: shippingAddress.lastName
      },
      {
        street: shippingAddress.street,
        houseNumber: shippingAddress.houseNumber,
        zip: shippingAddress.zip,
        city: shippingAddress.city
      }
    )
      .then((_) => {
        setTaskShippingInProgress(false);
        RefreshAfterApiCall();
      })
      .catch((error) => {
        setTaskShippingInProgress(false);
        setErrorModalOpen(true);
      });
  };

  /*const generateMechanicSearchClickableLinkElement = (linkText: string) => {
    return (
      <span
        style={{
          color: TechemTheme.colors.buttonPrimaryFill,
          userSelect: "none",
          cursor: "pointer",
        }}
        onClick={() => {
          TrackerUtil.trackBtnClick("inline-link-mechanic-search");
          alert("Clicked mechanic search inline button");
        }}
        dangerouslySetInnerHTML={{ __html: linkText }}
        data-gi="mechanic-search"
      ></span>
    );
  };*/

  const shippingAddress = {
    gender: store.getState().onboardingInfo.userData.contractData.personalData.gender,
    firstName: store.getState().onboardingInfo.userData.contractData.personalData.firstName,
    lastName: store.getState().onboardingInfo.userData.contractData.personalData.lastName,
    street:
      store.getState().onboardingInfo.userData.properties[
        store.getState().onboardingInfo.selectedPropertyIndex
      ].address.street,
    houseNumber:
      store.getState().onboardingInfo.userData.properties[
        store.getState().onboardingInfo.selectedPropertyIndex
      ].address.houseNumber,
    zip: store.getState().onboardingInfo.userData.properties[
      store.getState().onboardingInfo.selectedPropertyIndex
    ].address.zip,
    city: store.getState().onboardingInfo.userData.properties[
      store.getState().onboardingInfo.selectedPropertyIndex
    ].address.city
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search
              },
              {
                state: { from: loc.pathname }
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8}>
              <Title className="mx-1 mb-3" title={t('installationKitInfoPageTitle')} />

              <PageDescription className="mx-1">
                {t('installationKitInfoPageDescription')}
              </PageDescription>

              <Row>
                <Col xs className="copy p-0 m-3 text-bold">
                  <Trans i18nKey="installationKitInfoPageBulletSectionTitle" />
                </Col>
              </Row>

              <Row>
                <Col xs className="copy p-0 mx-3">
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>
                      <Trans i18nKey="installationKitInfoPageBulletSectionBullet1" />
                    </li>
                    <li>
                      <Trans i18nKey="installationKitInfoPageBulletSectionBullet2" />
                    </li>
                    <li>
                      <Trans i18nKey="installationKitInfoPageBulletSectionBullet3" />
                    </li>
                  </ul>
                </Col>
              </Row>

              <Row>
                <Col xs className="copy p-0 m-3 text-bold">
                  <Trans i18nKey="installationKitInfoPageAddressSectionTitle" />
                </Col>
              </Row>

              <Row className="px-3">
                <Col
                  xs={12}
                  md={10}
                  lg={9}
                  xl={7}
                  style={{
                    backgroundColor: TechemTheme.colors.backgroundSecondary,
                    ...CSSAssignBorderRadius('8px')
                  }}
                  className="copy p-3"
                >
                  <Row>
                    <Col xs>
                      <Row>
                        <Col xs={12}>
                          {t(translationKeyForFixedOption(shippingAddress.gender))}{' '}
                          {`${shippingAddress.firstName} ${shippingAddress.lastName}`}
                        </Col>
                        <Col
                          xs={12}
                        >{`${shippingAddress.street} ${shippingAddress.houseNumber}`}</Col>
                        <Col xs={12}>{`${shippingAddress.zip} ${shippingAddress.city}`}</Col>
                      </Row>
                    </Col>
                    {false && (
                      <Col xs="auto" className={`clr-red`}>
                        <Row
                          onClick={() => {
                            alert('Clicked!');
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <Col xs="auto">
                            <Icon
                              iconSrc={editIcon}
                              size={ICONSIZE.mini}
                              alt="edit"
                              handleClick={() => {
                                alert('Clicked!');
                              }}
                              centered
                            />
                          </Col>
                          <Col xs="auto" className="ps-0">
                            <span>
                              <Trans i18nKey="installationKitInfoPageAddressSectionChangeAddressButtonText"></Trans>
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              <Row className={isMdOrAbove ? '' : 'justify-content-center'}>
                <Col xs="auto" className="mt-4 pt-3 pt-md-2 pb-3 pb-md-2">
                  <Row className="px-3">
                    <Col>
                      <Button
                        size={SIZE.large}
                        isLoading={taskShippingInProgress}
                        onClick={() => {
                          handleSendInstallationKitButtonClick();
                        }}
                        additionalTestId="submit-shipping-address"
                        trackClickUsingTestId={(buttonIdentifier) => {
                          TrackerUtil.trackBtnClick(
                            `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                            `button_${buttonIdentifier}`
                          );
                        }}
                      >
                        <Trans i18nKey="installationKitInfoPageSubmitButtonText"></Trans>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-5">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />

      <TechemModal
        closeable
        header={<TechemModalHeader title={t('generalErrorModalTitle')} />}
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {t('generalErrorModalDescription1')}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" sm={11} className="copy mt-3 mb-2">
                {t('generalErrorModalDescription2')}
              </Col>
            </Row>
            <Row>
              <Col xs className="text-center copy">
                <HeaderView lg={4} md={7} sm="auto" xs="auto" className="d-flex w-100">
                  <PhoneSupportNoTitleView />
                </HeaderView>
              </Col>
            </Row>
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                'order-installation-kit-error-modal'
              );
            }}
            primary={{
              text: t('generalErrorModalCloseButton'),
              handleOnClick: () => {
                setErrorModalOpen(false);
              },
              additionalTestId: 'close-error-modal'
            }}
          />
        }
        isOpen={errorModalOpen}
        modalTrackingId="order-installation-kit-error-modal"
        onClose={() => {
          setErrorModalOpen(false);
        }}
        additionalTestId="order-installation-kit-error-modal"
      />
    </div>
  );
};

export default connect(mapStateToProps)(InstallationKitInfoPage);
