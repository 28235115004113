import { TechemTheme } from '@techem/techem-theme';
import Icon, { ICONSIZE } from 'components/Icon';
import hourglassIcon from 'assets/icons/png/3x/icn-hourglass@3x.png';
import Header from 'layout/Header';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import Title from 'components/Title';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { EventName, OrderType } from 'reducers/onboardingInformationSlice';
import { DateFormat, formatDate } from 'tools/dev/DateTimeFormatHelper';
import { DetailedEventOverview } from 'components/DetailedEventOverview';
import useEventHistory from 'tools/hooks/useEventHistory';
import ProgressOverviewNavigationLink from 'components/ProgressOverviewNavigationLink';

const InstallationEstimatedIntervalPage: React.FC = () => {
  const { t } = useTranslation();
  const eventHistory = useEventHistory();

  const intervallData = useSelector((state: RootState) => {
    const idx = state.onboardingInfo.selectedPropertyIndex;
    const order = state.onboardingInfo.userData.properties[idx].orders.find(
      (order) => order.type === OrderType.TechemDirect
    );

    let data = { from: undefined, to: undefined };

    // not set => empty interval
    if (order === undefined || order.eventHistory === undefined) return data;

    const lastEvent = order.eventHistory[order.eventHistory.length - 1];
    // wrong event => empty interval
    if (lastEvent?.nameOrderObject.name !== EventName.InstallationIntervalSet) return data;

    return {
      from: formatDate(new Date(lastEvent.data.from), DateFormat.DateSimple),
      to: formatDate(new Date(lastEvent.data.to), DateFormat.DateSimple)
    };
  });

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header ignoreSpacing={true} />
      <Container>
        <ProgressOverviewNavigationLink />
      </Container>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center mt-md-3 pt-md-3">
            <Col xs={12} md={10} lg={8} className="mb-3">
              <Title className="mx-1" title={t('installationEstimatedIntervallPageTitle')} />
            </Col>
          </Row>
          <Row className="justify-content-center mb-4">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius('8px')
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon iconSrc={hourglassIcon} alt="hourglass" size={ICONSIZE.xxxlarge} />
                </Col>
              </Row>

              <Row className="justify-content-center" data-gi="on-site-inspection-appointment-info">
                <Col xs={10} className="copy p-0 pb-3 mt-3 mb-2 text-center">
                  {intervallData.from && intervallData.to && (
                    <Trans
                      i18nKey="installationEstimatedIntervallPageDescription"
                      values={{
                        from: intervallData.from,
                        to: intervallData.to
                      }}
                      tOptions={{ skipPostProcessing: true }}
                      components={[
                        <span className="fw-bold" style={{ color: TechemTheme.colors.accent }} />,
                        <span style={{ color: TechemTheme.colors.mono500 }} />
                      ]}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy text-bold mx-0">
              {t('installationEstimatedIntervallPageContentTitle')}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy my-2 mb-3">
              {t('installationEstimatedIntervallPageContentDescription')}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="pt-4 px-0">
              <DetailedEventOverview data={eventHistory} />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default InstallationEstimatedIntervalPage;
