import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { styled } from 'baseui';
import { useTranslation } from 'react-i18next';
import { TechemTheme } from '@techem/techem-theme';
import phoneIcon from 'assets/icons/png/3x/icn-phone-flip.png';
import Icon, { ICONSIZE } from 'components/Icon';
import { TrackerUtil } from 'tracking/TrackerUtil';
import store from 'store';
import { useMediaQuery } from 'tools/hooks/useMediaQuery';
import { minBreakpoints } from 'theme/mediaQueries';

// Don't change this, as display: 'flex !important' does not (!) work with inline styling for a Container component!!!
const CustomCol = styled(Col, {
  display: 'flex !important',
  '@media (max-width: 767px)': {
    display: 'none !important'
  }
});

const Component: React.FC = () => {
  const { t } = useTranslation();
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  const telNumberToCall = !!store.getState().OBCInfo.token
    ? t('headerPhoneSupportOBCViewNumberToCall')
    : t('headerPhoneSupportViewNumberToCall');
  const telNumberToDisplay = !!store.getState().OBCInfo.token
    ? t('headerPhoneSupportOBCViewNumberToDisplay')
    : t('headerPhoneSupportViewNumberToDisplay');

  // function Component({ t, i18n }: any) {
  var telHref = 'tel:' + telNumberToCall;
  return (
    <Row className="d-flex flex-row h-auto my-auto mx-0">
      <Col xs="auto" style={{ margin: 'auto 0' }}>
        <Icon
          handleClick={() => {
            TrackerUtil.trackBtnClick('header_button_phone-support', 'button_phone-support');
            window.location.href = telHref;
          }}
          size={isMdOrAbove ? ICONSIZE.xlarge : ICONSIZE.large}
          iconSrc={phoneIcon}
          alt="phone support icon"
          additionalTestId="phone"
        />
      </Col>
      <CustomCol xs="auto" className="d-none d-md-block my-auto mx-0 flex-column">
        <Row className="flex-row" style={{ margin: 'auto -15px' }}>
          <Col xs={12} style={{ flex: 'inherit !important' }}>
            <span className="copy">{t('headerPhoneSupportViewTitle')}</span>
          </Col>
        </Row>
        <Row className="flex-row" style={{ margin: 'auto -15px' }}>
          <Col xs={12} style={{ flex: 'inherit !important' }}>
            <a
              href={telHref}
              onClick={() => {
                TrackerUtil.trackBtnClick('header_button_phone-support', 'button_phone-support');
              }}
              style={{
                fontSize: '1.5rem',
                lineHeight: '1.5rem',
                fontWeight: 'bold',
                textDecoration: 'inherit',
                color: TechemTheme.colors.primaryA
              }}
            >
              {telNumberToDisplay}
            </a>
          </Col>
        </Row>
        <Row className="flex-row" style={{ margin: 'auto -15px' }}>
          <Col xs={12} style={{ flex: 'inherit !important' }}>
            <span className="copy" style={{ whiteSpace: 'nowrap' }}>
              {t('headerPhoneSupportViewOperatingHoursNotice')}
            </span>
          </Col>
        </Row>
      </CustomCol>
    </Row>
  );
};

export default withTranslation()(Component);
