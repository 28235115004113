export const PublicHolidays = [
  new Date(2022, 9, 3, 0, 0, 0),
  new Date(2022, 11, 24, 0, 0, 0),
  new Date(2022, 11, 25, 0, 0, 0),
  new Date(2022, 11, 26, 0, 0, 0),
  new Date(2022, 11, 31, 0, 0, 0),

  new Date(2023, 0, 1, 0, 0, 0),
  new Date(2023, 3, 7, 0, 0, 0),
  new Date(2023, 3, 9, 0, 0, 0),
  new Date(2023, 3, 10, 0, 0, 0),
  new Date(2023, 4, 1, 0, 0, 0),
  new Date(2023, 4, 18, 0, 0, 0),
  new Date(2023, 4, 29, 0, 0, 0),
  new Date(2023, 9, 3, 0, 0, 0),
  new Date(2023, 11, 24, 0, 0, 0),
  new Date(2023, 11, 25, 0, 0, 0),
  new Date(2023, 11, 26, 0, 0, 0),
  new Date(2023, 11, 31, 0, 0, 0)
];
