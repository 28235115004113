import { SHOULD_USE_MOCKED_DATA } from 'config';
import { ObcAppointmentApiClient } from 'lib/axios';
import { OBCTimeSlot } from 'pages/OBCFlow/OBCSchedulingAssistant/OBCSchedulingAssistantPage';
import { OBCSetTimeslotResultOutcome } from 'reducers/OBCSlice';
import { MockedOBCInfo, MockedOBCTimeslots } from 'services/MockedData';
import { DateFormat, formatDate } from 'tools/dev/DateTimeFormatHelper';

const Endpoints = {
  OBC: '/pkd/pharus/appointment-booker-service/appointment',
  OBCSlotsAvailable: '/available'
};

export const OBCClient = {
  /**
   * Gets mocked information for a specified token.
   *
   * @param {string} token - The token to get mocked information for.
   * @returns {Promise} A promise that resolves to an object with a 'data' property, containing the mocked information.
   */
  getOBCInfoMocked() {
    const data = MockedOBCInfo;
    // Return a new promise that will be resolved with an object containing the 'data' property after a 1.5 second delay.
    return new Promise((resolve: (value: { data: any }) => void, reject) => {
      setTimeout(function () {
        /*reject({
          response: { 
            message: "Blah", 
            status: 422 // Can be 400 | 404 | 422  
          },
        });*/
        resolve({ data });
      }, 1500);
    });
  },

  getOBCInfo(token: string) {
    return SHOULD_USE_MOCKED_DATA
      ? OBCClient.getOBCInfoMocked()
      : ObcAppointmentApiClient.get(`${Endpoints.OBC}/${token}`);
  },

  getOBCAvailableTimeslots(token: string, earliest: Date, latest: Date) {
    if (SHOULD_USE_MOCKED_DATA)
      // Return a mocked response after a delay
      return new Promise((resolve: (value: { data: any }) => void, reject) => {
        setTimeout(function () {
          // Uncomment this line to return an empty array of available slots
          //resolve({ data: { availableSlots: [] } });
          resolve({ data: { availableSlots: MockedOBCTimeslots } });
        }, 500);
      });

    // Make a GET request to the API to book a OBC Timeslot
    return ObcAppointmentApiClient.get(`${Endpoints.OBC}/${token}${Endpoints.OBCSlotsAvailable}`, {
      params: {
        earliest: formatDate(earliest, DateFormat.DateForAPICall),
        latest: formatDate(latest, DateFormat.DateForAPICall)
      }
    });
  },

  /**
   * Sets the time slot for a specified token.
   *
   * @param {string} token - The token to set the time slot for.
   * @param {OBCTimeSlot} timeSlot - An object representing the time slot to set. It should have a 'from' and a 'till' property, both of which should be Date objects.
   * @returns {Promise} A promise that resolves to the API response.
   */
  setOBCTimeslot(token: string, timeSlot: OBCTimeSlot) {
    if (SHOULD_USE_MOCKED_DATA) {
      // Return a mocked response after a delay
      return new Promise((resolve: (value: any) => void, reject) => {
        setTimeout(function () {
          //reject();
          resolve({
            data: {
              outcome: OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
            }
          });
          //resolve();
        }, 500);
      });
    }

    // Make an HTTP POST request to the specified endpoint, with the token as a path parameter and the from/to properties in the request body.
    return ObcAppointmentApiClient.post(`${Endpoints.OBC}/${token}`, {
      from: new Date(timeSlot.from).toISOString(),
      to: new Date(timeSlot.till).toISOString()
    });
  }
};
