import React from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import WizardStepsForOverallProgress from 'components/WizardStepsForOverallProgress';
import { Row, Col, Container } from 'react-bootstrap';
import { TechemTheme } from '@techem/techem-theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { DetailedEventOverview } from 'components/DetailedEventOverview';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import { OrderType, StoreObject } from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';
import Icon, { ICONSIZE } from 'components/Icon';
import hourglassIcon from 'assets/icons/png/3x/icn-hourglass@3x.png';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const InstallationPreparationPendingPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search
              },
              {
                state: { from: loc.pathname }
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-2">
              <Title className="mx-1" title={t('installationPreparationPendingPageTitle')} />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius('8px')
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon size={ICONSIZE.xxxlarge} iconSrc={hourglassIcon} alt="calendar" />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={10} className="copy p-0 pb-3 mt-3 mb-2 text-center">
                  {t('installationPreparationPendingPageContent')}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy text-bold pt-2 pt-md-3 m-2 mt-4 mx-0">
              {t('installationPreparationPendingPageBulletSectionTitle')}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy mt-1 mb-2 mb-md-3">
              <ul className="m-0" style={{ paddingLeft: '1.5rem' }}>
                <li>{t('installationPreparationPendingPageBulletSectionBullet1')}</li>
                <li>{t('installationPreparationPendingPageBulletSectionBullet2')}</li>
                <li>{t('installationPreparationPendingPageBulletSectionBullet3')}</li>
                <li>{t('installationPreparationPendingPageBulletSectionBullet4')}</li>
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-5">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(InstallationPreparationPendingPage);
