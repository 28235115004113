import { TimeSlotDTO } from 'clients/obc/types';
import { SHOULD_USE_MOCKED_DATA } from 'config';
import { AppointmentType } from 'contexts/OrtecAvailabilityContext';
import { apiClient } from 'lib/axios';
// eslint-disable-next-line
import { MockedOrtecTimeSlots } from 'services/MockedData';

// Endpoints for making API calls for Ortec Timeslots
const Endpoints = {
  Ortec: '/onboarding/appointment',
  OrtecInstallationTimeSlot: '/onboarding/appointment/installation',
  OrtecOnSiteInspectionTimeSlot: '/onboarding/appointment/onsiteInspection',
  OrtecSlotsAvailable: '/available'
};

export const OrtecTimeslotClient = {
  /**
   * Makes a request to get available Ortec time slots for a specific order and appointment type.
   * @param orderId The ID of the order.
   * @param appointmentType The type of appointment.
   */
  getOrtecAvailableTimeSlots(orderId: string, appointmentType: AppointmentType) {
    // Check if the customer number is the mocked customer number
    if (SHOULD_USE_MOCKED_DATA)
      // If it is, return a mocked response after a delay
      return new Promise((resolve: (value: { data: any }) => void, reject) => {
        setTimeout(function () {
          // Uncomment this line to return an empty array of available slots
          resolve({ data: { availableSlots: [] } });
          //reject({ response: "Blah" });
          // resolve({ data: { availableSlots: MockedOrtecTimeSlots } });
        }, 500);
      });

    // Make a GET request to the API to book the ortec time slot
    return apiClient.get(
      `${Endpoints.Ortec}/${orderId}/${appointmentType}${Endpoints.OrtecSlotsAvailable}`
    );
  },

  /**
   * Sends a request to book a time slot for an "Objektbegehung" (on-site inspection).
   * @param orderId The ID of the order.
   * @param timeSlot The time slot to book.
   */
  async setOnSiteInspectionOrtecTimeSlot(orderId: string, timeSlot: TimeSlotDTO) {
    // Make a POST request to the API to book the time slot
    return apiClient.post(`${Endpoints.OrtecOnSiteInspectionTimeSlot}/${orderId}`, timeSlot);
  },

  /**
   * Sends a request to book a time slot for a "MontageTermin" (installation).
   * @param orderId The ID of the order.
   * @param timeSlot The time slot to book.
   */
  async setInstallationOrtecTimeSlot(orderId: string, timeSlot: TimeSlotDTO) {
    // Make a POST request to the API to book the time slot
    return apiClient.post(`${Endpoints.OrtecInstallationTimeSlot}/${orderId}`, timeSlot);
  }
};
