// Functions to work with Adobe Analytics.

import { ABTesting } from 'reducers/abTestingSlice';
import store from 'store';

var abTesting: ABTesting;

// Specific interface for any type of event, requires the event name.
export type DataLayerEventForAdobeAnalytics = {
  event: string;
} & {
  [key: string]: string;
};

// Specific interface for any interaction event, requires the interaction category, identifier and type.
export interface DataLayerInteractionEventForAdobeAnalytics {
  elementCategory: string;
  elementIdentifier: string;
  elementType: string;
}

// Specific interface for page impression events, requires identifier, language, name (i.e. page title), path (without parameters) and full URL.
export interface DataLayerViewImpressionEventForAdobeAnalytics {
  pageIdentifier: string;
  pageLanguage: string; // ISO 639-1 Code, e.g. 'en', 'de'
  pageName: string;
  pagePath: string;
  virtualPagePath: string;
  pageURL: string;
}

export function loadAdobeAnalytics() {
  abTesting = store.getState().abTesting;
}

const marketingRelevantUrlParams = ['etcc_cmp', 'etcc_med', 'etcc_par', 'etcc_ctv', 'CID'];
export const emitDataLayerEvent = (
  eventInfo: DataLayerEventForAdobeAnalytics,
  skipMetadata = false
) => {
  const anyWindow = window as any;
  anyWindow.siteDataLayer = anyWindow.siteDataLayer || [];

  const marketingUrlParams = Array.from(
    new URLSearchParams(anyWindow.location.search).entries()
  ).filter((queryParam) => marketingRelevantUrlParams.includes(queryParam[0]));
  if (!skipMetadata) {
    anyWindow.siteDataLayer.push({
      ...eventInfo,
      metaData: {
        abTesting: {
          ...abTesting,
          full: ''
        },
        marketingUrlParams: {
          ...marketingUrlParams
        }
      }
    });
  } else {
    anyWindow.siteDataLayer.push({
      ...eventInfo
    });
  }
};

export const emitDataLayerClickEvent = (eventInfo: DataLayerInteractionEventForAdobeAnalytics) => {
  emitDataLayerEvent(
    {
      event: 'interaction',
      interactionType: 'click',
      ...eventInfo
    },
    true
  );
};

export const emitDataLayerPageImpression = (
  pageInfo: DataLayerViewImpressionEventForAdobeAnalytics
) => {
  emitDataLayerEvent({
    event: 'pageView',
    ...pageInfo
  });
};

export const emitDataLayerModalImpression = (
  pageInfo: DataLayerInteractionEventForAdobeAnalytics
) => {
  emitDataLayerEvent(
    {
      event: 'modalView',
      ...pageInfo
    },
    true
  );
};
