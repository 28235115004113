import React, { useContext, useState } from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import TitleWithPrevPageLink from 'components/TitleWithPrevPageLink';
import PageDescription from 'components/PageDescription';
import { Trans, useTranslation } from 'react-i18next';
import { StoreObject, OrderType, Order } from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';
import { SIZE } from 'baseui/button';
import { TechemTheme } from '@techem/techem-theme';
import {
  Button,
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader
} from '@techem/ui-components';
import { RefreshAfterApiCall } from 'components/ApplicationInterceptor';

import PhoneSupportNoTitleView from 'components/PhoneSupportNoTitleView';
import { styled } from 'styletron-react';
import { Paths } from 'Routes';
import { TrackerUtil } from 'tracking/TrackerUtil';
import PreferredTimeSlotPicker from 'components/timeslot/PreferredTimeSlotPicker';
import { PreferredTimeSlotContext } from 'contexts/PreferredTimeSlotContext';
import PreferredTimeSlotSelectionPage from '../PreferredTimeSlotSelection/PreferredTimeSlotSelectionPage';
import { AppointmentClient } from 'clients';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const HeaderView = styled(Col, {
  height: '100px',
  '@media (max-width: 767px)': {
    height: '80px'
  },
  backgroundColor: TechemTheme.colors.backgroundPrimary
});

const SchedulingAssistantInstallationPage: React.FC<Props> = ({ onboardingInfo }) => {
  // Hooks
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  //Context
  const { preferredTimeslots, setPreferredTimeslotIndex, resetPreferredTimeslotIndex } =
    useContext(PreferredTimeSlotContext);

  //States
  const [isSetting, setIsSetting] = useState(false);
  const [isSubmittingPreferredTimeslots, setIsSubmittingPreferredTimeslots] = useState(false);

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    setIsSubmittingPreferredTimeslots(false);
  };

  const onSetPreferredTimeslotButtonClick = (index: number) => {
    if (index < 0 || index > 2) return;
    // Set time slot index to identify the position in array of chosen timeslots
    setPreferredTimeslotIndex(index);
    setIsSetting(true);
  };

  const submitChosenTimeslotsHandler = () => {
    setIsSubmittingPreferredTimeslots(true);
    const payload = preferredTimeslots.filter((preferredTimeslot) => {
      return !!preferredTimeslot.date && !!preferredTimeslot.timeOfDay;
    });
    AppointmentClient.setInstallationPreferredTimeslots(
      onboardingInfo!.userData.properties[onboardingInfo!.selectedPropertyIndex].orders.find(
        (order: Order) => order.type === OrderType.TechemDirect
      )!.orderNumber,
      payload
    )
      .then((_) => {
        setIsSubmittingPreferredTimeslots(false);
        RefreshAfterApiCall();
      })
      .catch((_) => {
        setIsSubmittingPreferredTimeslots(false);
        setErrorModalOpen(true);
      });
  };
  return isSetting ? (
    <PreferredTimeSlotSelectionPage
      handleGoBack={() => {
        setIsSetting(false);
        resetPreferredTimeslotIndex();
      }}
      appointmentType={'installation'}
    />
  ) : (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <TitleWithPrevPageLink
            onPrevClick={() => {
              navigate({
                pathname: Paths.InstallationInfo,
                search: loc.search
              });
            }}
            title={t('schedulingAssistantInstallationPageTitle')}
          />

          <div className="mb-3" />

          <PageDescription className="mx-1 mb-3 mb-md-4">
            <Trans
              i18nKey="schedulingAssistantInstallationPageDescription"
              tOptions={{ skipPostProcessing: true }}
            />
            <br />{' '}
            <span style={{ color: TechemTheme.colors.accent }}>
              <Trans
                i18nKey="schedulingAssistantInstallationPageDescription2"
                tOptions={{ skipPostProcessing: true }}
              />
            </span>
          </PageDescription>

          <PreferredTimeSlotPicker
            submitChosenTimeslotsHandler={submitChosenTimeslotsHandler}
            onSetPreferredTimeslotButtonClick={onSetPreferredTimeslotButtonClick}
          />

          <Row className="justify-content-center">
            <Col xs="auto" className="pt-3 pt-md-2 mt-4 mx-0">
              <Button
                size={SIZE.large}
                disabled={
                  !preferredTimeslots[0].date ||
                  !preferredTimeslots[0].timeOfDay ||
                  !preferredTimeslots[1].date ||
                  !preferredTimeslots[1].timeOfDay
                }
                isLoading={isSubmittingPreferredTimeslots}
                onClick={() => {
                  submitChosenTimeslotsHandler();
                  setIsSubmittingPreferredTimeslots(true);
                }}
                additionalTestId="send-preferred-timeslots"
                trackClickUsingTestId={(buttonIdentifier) => {
                  TrackerUtil.trackBtnClick(
                    `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                    `button_${buttonIdentifier}`
                  );
                }}
              >
                <Trans i18nKey="schedulingAssistantInstallationPageSendPreferredTimeslotsButtonText" />
              </Button>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />

      <TechemModal
        closeable
        header={<TechemModalHeader title={t('generalErrorModalTitle')} />}
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {t('generalErrorModalDescription1')}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" sm={11} className="copy mt-3 mb-2">
                {t('generalErrorModalDescription2')}
              </Col>
            </Row>
            <Row>
              <Col xs className="text-center copy">
                <HeaderView lg={4} md={7} sm="auto" xs="auto" className="d-flex w-100">
                  <PhoneSupportNoTitleView />
                </HeaderView>
              </Col>
            </Row>
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                'installation-set-preferred-timeslots-error-modal'
              );
            }}
            primary={{
              text: t('generalErrorModalCloseButton'),
              handleOnClick: () => {
                handleErrorModalClose();
              },
              additionalTestId: 'close-error-modal'
            }}
          />
        }
        isOpen={errorModalOpen}
        modalTrackingId="installation-set-preferred-timeslots-error-modal"
        onClose={() => {
          handleErrorModalClose();
        }}
        additionalTestId="installation-set-preferred-timeslots-error-modal"
      />
    </div>
  );
};

export default connect(mapStateToProps)(SchedulingAssistantInstallationPage);
