import React from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import { TechemTheme } from '@techem/techem-theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { DetailedEventOverview } from 'components/DetailedEventOverview';
import Title from 'components/Title';
import { Trans, useTranslation } from 'react-i18next';
import { OrderType, StoreObject } from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';
import { SIZE } from 'baseui/button';
import packageDeliveryIcon from 'assets/icons/png/3x/icn-truck-fast.png';
import Icon, { ICONSIZE } from 'components/Icon';
import { TrackerUtil } from 'tracking/TrackerUtil';
import {
  Button,
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader
} from '@techem/ui-components';
import { useState } from 'react';

import { RefreshAfterApiCall } from 'components/ApplicationInterceptor';
import PhoneSupportNoTitleView from 'components/PhoneSupportNoTitleView';
import { styled } from 'styletron-react';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import { OnboardingInformationClient } from 'clients';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const HeaderView = styled(Col, {
  height: '100px',
  '@media (max-width: 767px)': {
    height: '80px'
  },
  backgroundColor: TechemTheme.colors.backgroundPrimary
});

const InstallationKitStatusPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const [
    isSubmittingInstallationKitIsInstalledResponse,
    setIsSubmittingInstallationKitIsInstalledResponse
  ] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleInstallationKitIsInstalledButtonClick = () => {
    setIsSubmittingInstallationKitIsInstalledResponse(true);
    OnboardingInformationClient.setInstallationKitInstalled(
      onboardingInfo!.userData.properties[onboardingInfo!.selectedPropertyIndex].orders.find(
        (order) => order.type === OrderType.TechemDirect
      )!.orderNumber
    )
      .then((_) => {
        setIsSubmittingInstallationKitIsInstalledResponse(false);
        RefreshAfterApiCall();
      })
      .catch((error) => {
        setIsSubmittingInstallationKitIsInstalledResponse(false);
        setErrorModalOpen(true);
      });
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header ignoreSpacing></Header>
      <Container>
        <Row className="justify-content-center mb-4 mb-md-3 pt-0 pt-md-3">
          <Col
            className="d-flex justify-content-center copy"
            style={{
              color: TechemTheme.colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            <span
              onClick={() => {
                TrackerUtil.trackLinkClick(
                  `${TrackerUtil.getPageName()}_link_process-overview`,
                  'link_process-overview'
                );

                navigate(
                  {
                    pathname: Paths.ProgressOverview,
                    search: loc.search
                  },
                  {
                    state: { from: loc.pathname }
                  }
                );
              }}
              data-gi="show-all-steps"
            >
              <Trans i18nKey="wizardStepsProcessOverviewButtonText" />
            </span>
          </Col>
        </Row>
      </Container>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center text-center mt-md-3 pt-md-3">
            <Col xs={12} md={10} lg={8} className="pb-3 mb-4">
              <Title className="mx-1" title={t('installationKitStatusPageTitle')} />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius('8px')
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon
                    size={ICONSIZE.xxxlarge}
                    iconSrc={packageDeliveryIcon}
                    alt="package delivery"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={8} className="copy p-0 mt-3 mb-2 text-center">
                  <Trans i18nKey="installationKitStatusPageTrackingSectionDescription" />
                </Col>
                <Col xs={8} className="copy p-0 mt-3 mb-4 pb-3 text-center">
                  <span style={{ color: TechemTheme.colors.mono500 }}>
                    <Trans i18nKey="installationKitStatusPageTrackingSectionDescriptionNote" />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center my-4 py-3 ">
            <Col xs={12} md={10} lg={8}>
              <Row className="justify-content-center">
                <Col xs={10} className="mb-2">
                  <span className="copy fs-5 text-bold">
                    <Trans i18nKey="installationKitStatusPageNextStepsTitle" />
                  </span>
                </Col>
                <Col xs={10} className="copy">
                  <Trans i18nKey="installationKitStatusPageNextStepsDescription" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius('8px')
              }}
            >
              <Row className="justify-content-center text-center">
                <Col xs={12} md={10} lg={8} className="mt-3 pt-3">
                  <h4>
                    <Trans i18nKey="installationKitStatusPageConfirmationSectionTitle" />
                  </h4>
                </Col>
                <Col xs={12} md={10} lg={8} className="mb-3">
                  <Trans i18nKey="installationKitStatusPageConfirmationSectionDescription" />
                </Col>

                <Col xs="auto" className="mt-3 mt-md-2 mb-2 pb-3 pb-md-3 mb-md-3">
                  <Button
                    size={SIZE.large}
                    isLoading={isSubmittingInstallationKitIsInstalledResponse}
                    onClick={() => {
                      handleInstallationKitIsInstalledButtonClick();
                    }}
                    additionalTestId="confirm-successful-installation"
                    trackClickUsingTestId={(buttonIdentifier) => {
                      TrackerUtil.trackBtnClick(
                        `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                        `button_${buttonIdentifier}`
                      );
                    }}
                  >
                    {t('installationKitStatusPageInstallationKitInstalledButtonText')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-5">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />

      <TechemModal
        closeable
        header={<TechemModalHeader title={t('generalErrorModalTitle')} />}
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {t('generalErrorModalDescription1')}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" sm={11} className="copy mt-3 mb-2">
                {t('generalErrorModalDescription2')}
              </Col>
            </Row>
            <Row>
              <Col xs className="text-center copy">
                <HeaderView lg={4} md={7} sm="auto" xs="auto" className="d-flex w-100">
                  <PhoneSupportNoTitleView />
                </HeaderView>
              </Col>
            </Row>
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                'set-installation-kit-installed-error-modal'
              );
            }}
            primary={{
              text: t('generalErrorModalCloseButton'),
              handleOnClick: () => {
                setErrorModalOpen(false);
              },
              additionalTestId: 'close-error-modal'
            }}
          />
        }
        isOpen={errorModalOpen}
        modalTrackingId="set-installation-kit-installed-error-modal"
        onClose={() => {
          setErrorModalOpen(false);
        }}
        additionalTestId="set-installation-kit-installed-error-modal"
      />
    </div>
  );
};

export default connect(mapStateToProps)(InstallationKitStatusPage);
