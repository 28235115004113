import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from 'baseui';

import { Container, Col, Row } from 'react-bootstrap';
import { Button } from '@techem/ui-components';

import logoRef from 'assets/images/techemlogo_final.svg';
import { TechemTheme } from '@techem/techem-theme';
import { KIND, SIZE } from 'baseui/button';
import undrawBeginChatImg from 'assets/images/undraw_begin_chat_x3.png';
import undrawCalendarImg from 'assets/images/undraw_calendar_x3.png';
import undrawTextingImg from 'assets/images/undraw_texting_x3.png';
import XMarkIcon from 'assets/icons/png/3x/icn-xmark@3x.png';
import { WizardSteps } from 'components/WizardSteps';
import { useMediaQuery } from 'tools/hooks/useMediaQuery';
import Icon, { ICONSIZE } from 'components/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import storage from 'utils/storage';
import { GITLAB_PIPELINE_CREATED_AT, GITLAB_PIPELINE_ID } from 'config';

const SMALL_MOBILE = 812; //Smaller than IPhone SE

const Logo = styled('img', {
  height: '44px'
});

type Step = 0 | 1 | 2 | number;

const OnboardingFirstStepsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = useLocation();
  const [step, setStep] = useState<Step>(0);
  const showImages = useMediaQuery(`(min-height: ${SMALL_MOBILE}px)`);
  const images = [undrawBeginChatImg, undrawTextingImg, undrawCalendarImg];

  const handleGoNextPage = () => {
    storage.setIsIntroCompleted(true);

    // Let AppInterceptor decide where to go next.
    navigate({ pathname: Paths.Default, search: loc.search }, { replace: true });
  };

  return (
    <div className="d-flex flex-column w-100 h-auto min-vh-100 position-absolute top-0 start-0 bg-transparent">
      <div className="position-relative">
        <div
          className="position-absolute justify-content-end"
          style={{ top: '16px', right: '16px' }}
        >
          <Icon
            alt={'Skip'}
            iconSrc={XMarkIcon}
            size={ICONSIZE.xxlarge}
            handleClick={handleGoNextPage}
            additionalTestId="close-intro"
          />
        </div>
      </div>
      <Container
        className="pt-5 mt-5 m-auto position-relative"
        style={{ transitionDuration: '350ms' }}
      >
        <Row className="justify-content-center">
          <Col xs="auto">
            <Logo className="d-flex mb-5" src={logoRef} alt="techem logo" />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs="auto" className="mb-2 text-center">
            <h3>{t(`firstStepsPageTitle${step}`)}</h3>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs="auto" md={10} lg={7} xl={6} className="mt-2 mb-3 text-center">
            <span className="copy">
              <Trans
                i18nKey={`firstStepsPageDescription${step}`}
                tOptions={{ skipPostProcessing: true }}
              />
            </span>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mb-3 pb-3 position-relative">
          <Col className="position-absolute bottom-0">
            {showImages && (
              <Row className="justify-content-center">
                <Col xs="auto" className="p-0 mt-3">
                  <img
                    style={{ maxWidth: '248px', maxHeight: '200px' }}
                    src={images[step]}
                    alt="undraw-icon"
                  />
                </Col>
              </Row>
            )}

            <Row className="justify-content-center">
              <Col xs className="p-0 mt-3 px-4 mx-4" style={{ maxWidth: '500px' }}>
                <WizardSteps currentStep={step + 1} totalSteps={3} />
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col xs={12} md={10} lg={8} className="mt-4 px-3">
                <Button
                  stretched={true}
                  size={SIZE.large}
                  onClick={() => {
                    if (step < 2) {
                      setStep(step + 1);
                      return;
                    }

                    handleGoNextPage();
                  }}
                >
                  {t('firstStepsPageButtonText')}
                </Button>
                <div className="mt-2">
                  {step === 0 && <div className="m-4 p-1" />}
                  {step !== 0 && (
                    <Button
                      additionalStyleAsPlainCSS={step === 0 ? { display: 'none' } : {}}
                      key={step}
                      kind={KIND.secondary}
                      stretched={true}
                      size={SIZE.large}
                      onClick={() => {
                        if (step > 0) setStep(step - 1);
                      }}
                    >
                      {t('firstStepsPageButtonTextBack')}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="d-flex w-100 position-absolute top-0">
        <div
          className="m-auto mt-2 text-center"
          style={{
            lineHeight: '10px',
            height: '26px'
          }}
        >
          <span
            style={{
              fontSize: '10px',
              color: TechemTheme.colors.backgroundPrimary
            }}
          >
            {`${GITLAB_PIPELINE_ID} started at ${GITLAB_PIPELINE_CREATED_AT}`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OnboardingFirstStepsPage;
