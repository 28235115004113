import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// copied from: https://reactrouter.com/web/guides/scroll-restoration

export default function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
