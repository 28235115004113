import { SIZE, KIND } from 'baseui/button';
import PageDescription from 'components/PageDescription';
import TitleWithPrevPageLink from 'components/TitleWithPrevPageLink';

import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { TechemTheme } from '@techem/techem-theme';
import { DateDefaultLocale } from 'tools/dev/DateTimeFormatHelper';
import { TrackerUtil } from 'tracking/TrackerUtil';
import { Button } from '@techem/ui-components';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import Calendar from 'components/Calendar';
import PreferredTimeSlotButton from 'components/timeslot/PreferredTimeSlotButton';
import { PreferredTimeSlotContext } from 'contexts/PreferredTimeSlotContext';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import { AppointmentType } from 'contexts/OrtecAvailabilityContext';
import {
  DAY_OF_DATE_INDEX_FRIDAY,
  DAY_OF_DATE_INDEX_SATURDAY,
  DAY_OF_DATE_INDEX_SUNDAY,
  PREFERRED_TIMESLOT_ALL_DAY_APARTMENT_THRESHOLD
} from 'tools/Constants';
import useDuration from 'tools/hooks/useDuration';
import AnimatedExpandingView from 'components/AnimatedExpandingView';

interface Props {
  appointmentType: AppointmentType; // Type of the TimeSlot Booking
  handleGoBack?: () => void;
}

const PreferredTimeSlotSelectionPage: React.FC<Props> = ({ appointmentType, handleGoBack }) => {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = useLocation();
  const { duration, numberOfApartments } = useDuration(appointmentType);

  // Context
  const {
    minDate,
    maxDate,
    unavailableDays,
    dateCandidate,
    availableTimeSlotsDatas,
    preferredTimeslotIndex,
    preferredTimeslots,
    preferredTimeSlotCandidate,
    preferredTimeData,
    filterAvailableTimeSlots,
    setPreferredTimeslots,
    handleSetDateCandidate
  } = useContext(PreferredTimeSlotContext);

  type AvailableTimeSlots = ReturnType<typeof filterAvailableTimeSlots>;

  // State
  const [availableTimeSlots, setAvailableTimeSlots] = useState<AvailableTimeSlots>([]);

  const [randomIdForAnimatedView, setRandomIdForAnimatedView] = useState(Math.random());

  const descriptionPrefixes = [
    t('schedulingAssistantOnSiteInspectionPageDateSelectionModalNumberTermFirst'),
    t('schedulingAssistantOnSiteInspectionPageDateSelectionModalNumberTermSecond'),
    t('schedulingAssistantOnSiteInspectionPageDateSelectionModalNumberTermThird')
  ];

  const hiddenDayIndexes =
    appointmentType === 'installation'
      ? [DAY_OF_DATE_INDEX_SUNDAY, DAY_OF_DATE_INDEX_FRIDAY, DAY_OF_DATE_INDEX_SATURDAY]
      : [DAY_OF_DATE_INDEX_SUNDAY, DAY_OF_DATE_INDEX_SATURDAY];

  useEffect(() => {
    let tmpAvailableTimeSlots: AvailableTimeSlots = [];

    if (!!dateCandidate)
      tmpAvailableTimeSlots = filterAvailableTimeSlots(availableTimeSlotsDatas, dateCandidate);

    setAvailableTimeSlots(tmpAvailableTimeSlots);
  }, [
    duration,
    numberOfApartments,
    dateCandidate,
    appointmentType,
    availableTimeSlotsDatas,
    filterAvailableTimeSlots
  ]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <TitleWithPrevPageLink
            onPrevClick={
              handleGoBack
                ? handleGoBack
                : () => {
                    navigate(
                      {
                        pathname:
                          appointmentType === 'inspection'
                            ? Paths.OnSiteInspectionInfo
                            : Paths.InstallationInfo,
                        search: loc.search
                      },
                      {
                        replace: true
                      }
                    );
                  }
            }
            title={t('schedulingAssistantOnSiteInspectionPageTitle')}
          />
          <Row className="justify-content-center mt-3">
            <Col xs={12} md={8} lg={6} className="mb-4 px-3">
              <PageDescription className="mx-1 text-center">
                <Trans
                  i18nKey={
                    appointmentType === 'inspection'
                      ? 'schedulingAssistantOnSiteInspectionViaPreferredTimeslotPageDescription'
                      : 'schedulingAssistantInstallationViaPreferredTimeslotPageDescription'
                  }
                  values={{
                    prefix: descriptionPrefixes[preferredTimeslotIndex]
                  }}
                />
              </PageDescription>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8}>
              <Row
                className="justify-content-center"
                style={{
                  backgroundColor: TechemTheme.colors.backgroundSecondary,
                  ...CSSAssignBorderRadius('8px')
                }}
              >
                <Col
                  xs="auto"
                  className="m-3"
                  style={{
                    backgroundColor: TechemTheme.colors.backgroundPrimary,
                    ...CSSAssignBorderRadius('8px')
                  }}
                >
                  <Calendar
                    minDate={minDate}
                    maxDate={maxDate}
                    locale={DateDefaultLocale}
                    excludeDates={unavailableDays}
                    onChange={(dates) => {
                      // If actual date changed (no click on the exact same date as just before!),
                      // we need to reset the random id to tell animated view that this is a new child.
                      if (dates.date?.toString() !== dateCandidate?.toString())
                        setRandomIdForAnimatedView(Math.random());
                      handleSetDateCandidate(dates);
                    }}
                    filterDate={(date: Date) => !hiddenDayIndexes.includes(date.getDay())}
                    // Make weekends not selectable + Friday if type installation
                    hideWeekend
                  />
                </Col>
              </Row>

              {!!dateCandidate ? (
                <AnimatedExpandingView>
                  <Row
                    key={`timeslot-picker-${dateCandidate.toString()}-${randomIdForAnimatedView}}`}
                    className="justify-content-center"
                  >
                    <Col xs className="m-3 px-0">
                      <Row className="mb-3 justify-content-center">
                        <Col xs={12} md={8} lg={6} className="text-bold copy-l">
                          <Trans
                            i18nKey={
                              appointmentType === 'inspection'
                                ? 'schedulingAssistantOnSiteInspectionPageTimeslotSelectionTitle'
                                : 'schedulingAssistantInstallationPageTimeslotSelectionTitle'
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        {availableTimeSlots.map((item, index) => {
                          const from: Date = new Date(item.from);
                          const till: Date = new Date(item.till);
                          return (
                            <Row key={index} className="justify-content-center">
                              <Col xs={12} md={8} lg={6} key={index} className="px-2 pb-3">
                                <PreferredTimeSlotButton
                                  index={index}
                                  timeSlot={item}
                                  kind={
                                    !!preferredTimeData &&
                                    new Date(preferredTimeData.from!).valueOf() ===
                                      from.valueOf() &&
                                    new Date(preferredTimeData.till!).valueOf() === till.valueOf()
                                      ? KIND.primary
                                      : KIND.secondary
                                  }
                                  additionalTestId={`start-${new Date(item.from).toLocaleTimeString(
                                    'en',
                                    {
                                      timeStyle: 'short',
                                      hour12: false
                                    }
                                  )} end-${new Date(item.till).toLocaleTimeString('en', {
                                    timeStyle: 'short',
                                    hour12: false
                                  })}${
                                    !!preferredTimeData &&
                                    new Date(preferredTimeData.from!).valueOf() ===
                                      from.valueOf() &&
                                    new Date(preferredTimeData.till!).valueOf() === till.valueOf()
                                      ? ' selected'
                                      : ''
                                  }`}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                      </Row>

                      <Row className="justify-content-center">
                        <Col
                          xs={12}
                          md={8}
                          lg={6}
                          className="px-2 pb-2 text-center"
                          style={{
                            color: TechemTheme.colors.accent,
                            fontSize: '12px'
                          }}
                        >
                          {appointmentType !== 'installation' ? (
                            <Trans
                              i18nKey={
                                'schedulingAssistantOnSiteInspectionViaPreferredTimeslotExpectedDurationOnlyAllDayHint'
                              }
                              values={{
                                hours: duration
                              }}
                            />
                          ) : numberOfApartments >=
                            PREFERRED_TIMESLOT_ALL_DAY_APARTMENT_THRESHOLD ? (
                            <Trans
                              i18nKey="schedulingAssistantInstallationViaPreferredTimeslotExpectedDurationOnlyAllDayHint"
                              values={{
                                hours: duration
                              }}
                            />
                          ) : (
                            <Trans
                              i18nKey="schedulingAssistantInstallationViaPreferredTimeslotExpectedDurationHint"
                              values={{
                                hours: duration
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </AnimatedExpandingView>
              ) : (
                <Row className="justify-content-center">
                  <Col xs className="m-3 px-0">
                    <Row className="justify-content-center">
                      <Col xs={8} className="text-center copy">
                        <Trans i18nKey="OBCSchedulingAssistantSelectDateHint" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              <Row className="justify-content-center">
                <Col xs="auto" className="m-3 mb-0">
                  <Button
                    size={SIZE.large}
                    onClick={() => {
                      const timeOfDay = preferredTimeSlotCandidate?.timeOfDay!;

                      let newPreferredTimeslots = [...preferredTimeslots];
                      newPreferredTimeslots[preferredTimeslotIndex] = {
                        date: dateCandidate!,
                        timeOfDay: timeOfDay
                      };

                      setPreferredTimeslots(newPreferredTimeslots);

                      if (handleGoBack) handleGoBack();
                    }}
                    disabled={!preferredTimeSlotCandidate}
                    additionalTestId="add-timeslot"
                    trackClickUsingTestId={(buttonIdentifier) => {
                      TrackerUtil.trackBtnClick(
                        `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                        `button_${buttonIdentifier}`
                      );
                    }}
                  >
                    <Trans i18nKey="OBCSchedulingAssistantSendTimeslotButtonText"></Trans>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default PreferredTimeSlotSelectionPage;
