import { styled } from 'baseui';
import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getPixelSize, ICONSIZE } from './Icon';

import { md, minBreakpoints } from 'theme/mediaQueries';
import { useMediaQuery } from 'tools/hooks/useMediaQuery';

const PlaceholderCol = styled(Col, {
  width: '50px',
  paddingTop: '4px',
  [md]: {
    paddingTop: '24px'
  }
});

interface Props {
  indented?: boolean;
  className?: string;
  forceBreak?: boolean;
}

const PageDescription: React.FC<PropsWithChildren<Props>> = ({
  children,
  indented,
  className,
  forceBreak
}) => {
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  return (
    <Row className={`${className ?? ''}${isMdOrAbove ? ' justify-content-center' : ''}`}>
      {!!indented && (
        <PlaceholderCol xs="auto">
          <div
            style={{
              width: getPixelSize(ICONSIZE.medium),
              height: getPixelSize(ICONSIZE.medium)
            }}
          ></div>
        </PlaceholderCol>
      )}

      <Col
        xs
        md={10}
        lg={8}
        data-gi="page-description-element"
        className="px-0 d-flex justify-content-center"
      >
        <p
          style={forceBreak ? { maxWidth: '720px' } : {}}
          className={`copy${!!indented ? ' text-center' : ''}${isMdOrAbove ? ' text-center' : ''}`}
        >
          {children}
        </p>
      </Col>

      {!!indented && (
        <PlaceholderCol xs="auto">
          <div
            style={{
              width: getPixelSize(ICONSIZE.medium),
              height: getPixelSize(ICONSIZE.medium)
            }}
          ></div>
        </PlaceholderCol>
      )}
    </Row>
  );
};

export default PageDescription;
