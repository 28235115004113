import React from 'react';
import { TechemTheme } from '@techem/techem-theme';

const defaultDeviderStyles = {
  borderColor: TechemTheme.colors.buttonSecondaryFill,
  borderTop: '1px solid'
};

interface DividerProps {
  className?: string;
}

const Divider: React.FC<DividerProps> = ({ className }) => {
  return <hr style={defaultDeviderStyles} className={className} />;
};

export default Divider;
