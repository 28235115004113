// TODO: This is a slightly modified code snippet from facebook. It's extremly cryptic.
// Whoever understands it completely, feel free to refactor or rewrite it in Typescript :)
export function defineFbqFunctionGlobally() {
  const n = (window.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  });
  if (!window._fbq) window._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];
}
