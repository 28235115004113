import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useStyletron } from 'baseui';

import { minBreakpoints } from 'theme/mediaQueries';
import { useMediaQuery } from 'tools/hooks/useMediaQuery';

interface Props {
  title: string;
  className?: string;
  centered?: boolean;
}

const Title: React.FC<Props> = ({ title, className, centered = true }) => {
  const [css] = useStyletron();
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  return (
    <Row className={`${className}${isMdOrAbove && centered ? ' justify-content-center' : ''}`}>
      <Col xs="auto" className="px-0">
        <div data-gi="page-title-element py-0">
          <h2
            className={`${css({ wordBreak: 'break-word' })} ${
              isMdOrAbove ? 'text-center' : 'text-start'
            } m-auto`}
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Title;
