import React, { useRef } from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import TitleWithPrevPageLink from 'components/TitleWithPrevPageLink';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import checklistIcon from 'assets/icons/png/3x/icn-checklist.png';
import { Paths } from 'Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@techem/ui-components';
import { SIZE } from 'baseui/button';
import { TrackerUtil } from 'tracking/TrackerUtil';

const mapStateToProps = (state: any) => {
  return {
    OBCInfo: state.OBCInfo
  };
};

const OBCChecklistPage: React.FC = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const checklistPdfATagRef = useRef<HTMLAnchorElement>(null);

  const handlePrevBtnClicked = () => {
    navigate({
      pathname: Paths.OBCScheduled,
      search: loc.search
    });
  };

  const handleShowChecklistButtonClick = () => {
    (checklistPdfATagRef.current as HTMLAnchorElement).click();
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="mb-3">
              <TitleWithPrevPageLink
                onPrevClick={handlePrevBtnClicked}
                title={t('OBCChecklistPageTitle')}
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="copy mx-3 my-md-1 text-center">
              <p>{t('OBCChecklistPageDescription')}</p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs="auto" className="pb-3 my-3">
              <img
                style={{
                  maxWidth: '258px',
                  width: '-webkit-fill-available',
                  marginLeft: '64px',
                  marginRight: '64px'
                }}
                src={checklistIcon}
                alt="checkliste"
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg="auto" className="copy mx-3 my-1">
              <p>
                <strong>{t('OBCChecklistPageBulletSectionTitle')}</strong>
              </p>
              <ul style={{ paddingLeft: '1.5rem' }}>
                {Array.from({ length: 5 }, (_, i) => i + 1).map((index) => (
                  <li>{t(`OBCChecklistPageBulletSectionBullet${index}`)}</li>
                ))}
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs="auto" className="m-3 mb-0">
              <Button
                size={SIZE.large}
                onClick={() => {
                  handleShowChecklistButtonClick();
                }}
                additionalTestId="show-checklist"
                trackClickUsingTestId={(buttonIdentifier) => {
                  TrackerUtil.trackBtnClick(
                    `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                    `button_${buttonIdentifier}`
                  );
                }}
              >
                {t('OBCChecklistPageShowChecklistButtonText')}
              </Button>
              <a
                style={{ display: 'none' }}
                ref={checklistPdfATagRef}
                href="./statics/documents/Checkliste-Welcome-Service.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                File
              </a>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(OBCChecklistPage);
