import Axios from 'axios';
import AuthClient from 'clients/auth/AuthClient';
import { API_BASE_URL, API_MIETER_BASE_URL } from 'config';
import { createFormAxios } from 'services/tools/formAxios';

// Set Axios to send cookies with requests
Axios.defaults.withCredentials = true;

// Create an Axios instance for making form-encoded requests to the TAFU API
export const tafuApiClient = createFormAxios({
  baseURL: `${API_BASE_URL}/tafu`,
  timeout: 30000, // in milliseconds
  withCredentials: false
});

// Create an Axios instance for making requests to the API
export const apiClient = Axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000, // in milliseconds
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
});

export const ObcAppointmentApiClient = Axios.create({
  baseURL: API_MIETER_BASE_URL,
  timeout: 30000, // in milliseconds
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
});

// Add an interceptor to the API client to handle error responses
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    if (error.response.status === 403 || error.response.status === 401) {
      // FORBIDDEN (403) or UNAUTHORIZED (401).
      // We need to fire a login call first to authenticate before trying this call.
      AuthClient.login();
    } else {
      return Promise.reject(error);
    }
  }
);

ObcAppointmentApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    if (error.response.status === 403 || error.response.status === 401) {
      // FORBIDDEN (403) or UNAUTHORIZED (401).
      // We need to fire a login call first to authenticate before trying this call.
      AuthClient.login();
    } else {
      return Promise.reject(error);
    }
  }
);
