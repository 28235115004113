import { AppointmentType } from 'contexts/OrtecAvailabilityContext';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const DURATION_PER_APARTMENT = 1;
const DURATION_OFFSET = 1;
const DURATION_INSPECTION = 2;

/** A helper function that returns the estemated installation duration in hours for the current selcted property
 * calculated by the numberOfApartments that are stored in the redux store.
 * It also returns the information of the number of apartments.
 */
const useDuration = (appointmentType: AppointmentType) => {
  const numberOfApartments = useSelector((state: RootState) => {
    const idx = state.onboardingInfo.selectedPropertyIndex;
    return state.onboardingInfo.userData.properties[idx].numberOfApartments;
  });

  const duration =
    appointmentType === 'installation'
      ? numberOfApartments * DURATION_PER_APARTMENT + DURATION_OFFSET
      : DURATION_INSPECTION;

  return {
    duration: duration,
    numberOfApartments: numberOfApartments
  };
};

export default useDuration;
