import { apiClient } from 'lib/axios';

import { generateURLQueryForAPILoginCall, generateURLQueryForAPILogoutCall } from './authUtils';

export const AuthPaths = {
  Login: '/oauth2/login',
  Logout: '/oauth2/logout'
};

const AuthClient = {
  login() {
    const urlQuery = generateURLQueryForAPILoginCall();
    const location = `${apiClient.defaults.baseURL}${AuthPaths.Login}${urlQuery}`;
    window.location.href = location;
  },

  logout() {
    const urlQuery = generateURLQueryForAPILogoutCall();
    const location = `${apiClient.defaults.baseURL}${AuthPaths.Logout}${urlQuery}`;
    window.location.href = location;
  }
};

export default AuthClient;
