import { useSelector } from 'react-redux';
import { EventHistoryEntry, OrderType } from 'reducers/onboardingInformationSlice';
import { RootState } from 'reducers/rootReducer';

const useEventHistory = (): EventHistoryEntry[] => {
  const eventHistory = useSelector((state: RootState) => {
    const idx = state.onboardingInfo!.selectedPropertyIndex;
    const onboardingInfo = state.onboardingInfo;

    return onboardingInfo!.userData.properties.length > 0
      ? onboardingInfo!.userData.properties[idx].orders
          .find((order) => order.type === OrderType.TechemDirect)!
          .eventHistory.filter((eventItem) => !!eventItem.data)
      : [];
  });

  return eventHistory;
};
export default useEventHistory;
