import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import { Timeline } from 'components/event-overview/Timeline';
import TitleWithPrevPageLink from 'components/TitleWithPrevPageLink';
import PageDescription from 'components/PageDescription';
import { TechemTheme } from '@techem/techem-theme';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  EventHistoryEntry,
  EventHistoryOrdering,
  EventName,
  Order,
  OrderType
} from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';
import LoadingSpinner from 'components/LoadingSpinner';
import { Paths } from 'Routes';
import { TimelineItem } from 'components/event-overview/TimeLineItem';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import { intersectExists } from 'tools/dev/ArrayHelper';

const progressMilestones = [0, 40, 50, 52, 90, 100];
const showSecondStepAsRegularProgressValue = 10;
const optionalEBSStepsProgressValues = EventHistoryOrdering.filter((item) => {
  return (
    (
      [
        EventName.InstallationKitNeeded,
        EventName.InstallationKitRequested,
        EventName.InstallationKitSent,
        EventName.InstallationKitInstalled
      ] as String[]
    ).indexOf(item.name) >= 0
  );
}).map((item) => item.progress);

interface ProgressOverviewProps {
  historyEntries?: EventHistoryEntry[];
}

const mapStateToProps = (state: any) => {
  return {
    historyEntries:
      state.onboardingInfo && state.onboardingInfo.userData.properties.length > 0
        ? state.onboardingInfo!.userData.properties[
            state.onboardingInfo!.selectedPropertyIndex
          ].orders.find((order: Order) => order.type === OrderType.TechemDirect)!.eventHistory
        : undefined
  };
};
const ProgressOverviewPage: React.FC<ProgressOverviewProps> = ({ historyEntries }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const progressData = progressMilestones.map((mileStoneNumber) => {
    return {
      progressValue: mileStoneNumber,
      isOptional:
        mileStoneNumber === progressMilestones[2] || mileStoneNumber === progressMilestones[3],
      showAsOptional: false,
      isCurrent: false,
      hidden: false,
      stepDescriptionIndex: 0
    };
  });

  // Only mark optional steps for EBS as optional if none of the four EBS-relevant states
  // are included (yet) in the event history.
  const showOptionalStepsAsRegular = historyEntries
    ? intersectExists(
        historyEntries.map((entry) => entry.nameOrderObject.progress),
        optionalEBSStepsProgressValues
      )
    : false;
  if (!showOptionalStepsAsRegular) {
    progressData.find((item) => item.progressValue === progressMilestones[2])!.showAsOptional =
      true;
    progressData.find((item) => item.progressValue === progressMilestones[3])!.showAsOptional =
      true;
  }

  const showSecondStepAsRegular = historyEntries
    ? historyEntries!
        .map((entry) => entry.nameOrderObject.progress)
        .indexOf(showSecondStepAsRegularProgressValue) > -1
    : false;
  if (!showSecondStepAsRegular) {
    progressData[1].showAsOptional = true;
  }

  // Mark correct item as current and set hidden property.
  if (historyEntries) {
    // Mark item that is closest to the latest event progress value.
    const currentItem = progressData.find(
      (item) =>
        historyEntries!
          .map((entry) => entry.nameOrderObject.progress)
          .filter((progress) => progress >= item.progressValue).length === 0
    );
    if (currentItem) {
      currentItem.isCurrent = true;
    }

    // Hide according items and manage step indices.
    // See this comment for all details:
    // https://jira.techem-agile.de/browse/PKD-1486?focusedCommentId=70065&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-70065
    const maxProgressValue = Math.max(
      ...historyEntries!.map((entry) => entry.nameOrderObject.progress)
    );
    var stepDescriptionIndex = 0;
    progressData.forEach((item) => {
      if (
        item.showAsOptional &&
        maxProgressValue > item.progressValue &&
        [
          progressData[1].progressValue,
          progressData[2].progressValue,
          progressData[3].progressValue
        ].includes(item.progressValue) &&
        progressData[1].showAsOptional
      ) {
        item.hidden = true;
      } else {
        item.stepDescriptionIndex = stepDescriptionIndex++;
      }
    });
  }

  const handlePrevBtnClicked = () => {
    navigate({
      pathname: !!loc.state && !!(loc.state as any).from ? (loc.state as any).from : Paths.Default,
      search: loc.search
    });
  };

  const getCheckedState = (progressDataItem: {
    progressValue: number;
    isOptional: boolean;
    showAsOptional: boolean;
    isCurrent: boolean;
    hidden: boolean;
    stepDescriptionIndex: number;
  }) => {
    // Extra condition for optional steps that may be checked or not.
    return (
      historyEntries!
        .map((entry) => entry.nameOrderObject.progress)
        .filter((progress) => progress >= progressDataItem.progressValue).length > 0
    );
  };

  return !!historyEntries ? (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <TitleWithPrevPageLink
            onPrevClick={handlePrevBtnClicked}
            title={t('processOverviewPageTitle')}
          />

          <PageDescription indented className="mt-3">
            {t('processOverviewPageDescription')}
          </PageDescription>

          <Row className="justify-content-center">
            <Col
              className="pt-4"
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius('8px')
              }}
            >
              <Timeline>
                {progressData.map((progressDataItem, index, array) => (
                  <TimelineItem
                    key={`timeline-item-${index + 1}`}
                    checked={getCheckedState(progressDataItem)}
                    additionalTestId={`timeline-item-${index + 1} progress-${
                      progressDataItem.progressValue
                    }${getCheckedState(progressDataItem) ? ' checked' : ''}${
                      progressDataItem.isCurrent ? ' current' : ''
                    }${progressDataItem.showAsOptional ? ' optional' : ''}`}
                    last={index === array.length - 1}
                    transparent={progressDataItem.showAsOptional}
                    isCurrent={progressDataItem.isCurrent}
                    hidden={progressDataItem.hidden}
                  >
                    <Row>
                      <Col xs={12}>
                        <span className="copy clr-water fw-bold">
                          <Trans
                            i18nKey={
                              index === array.length - 1
                                ? ''
                                : `processOverviewPageStep${
                                    progressDataItem.stepDescriptionIndex + 1
                                  }Description`
                            }
                          />
                        </span>
                      </Col>
                      <Col xs={12}>
                        <span className="copy clr-dark-grey fw-bold">
                          <Trans i18nKey={`processOverviewPageStep${index + 1}Title`} />
                        </span>
                      </Col>
                      <Col xs={12}>
                        <span className="copy">
                          <Trans i18nKey={`processOverviewPageStep${index + 1}Content`} />
                        </span>
                      </Col>
                    </Row>
                  </TimelineItem>
                ))}
              </Timeline>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </div>
  ) : (
    <Container className="m-auto" style={{ transitionDuration: '350ms' }}>
      <Row className="justify-content-center">
        <Col xs="auto" className="d-flex vh-100">
          <LoadingSpinner topLevelStylingOverrides={{ margin: 'auto' }} />
        </Col>
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps)(ProgressOverviewPage);
