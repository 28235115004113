import { EventName, OrderType } from 'reducers/onboardingInformationSlice';
import { CustomerDTO } from './DTO';

/* TIME SLOTS  ORTEC*/

export const MockedOrtecTimeSlots = [
  {
    from: '2023-03-29T08:00:00+01:00',
    till: '2023-03-29T09:00:00+01:00',
    sequenceNumber: 2,
    resourceId: '81ORR',
    routeId: '19070'
  },
  {
    from: '2023-03-29T09:00:00+01:00',
    till: '2023-03-29T10:00:00+01:00',
    sequenceNumber: 1,
    resourceId: '81ORR',
    routeId: '19043'
  },
  {
    from: '2023-03-29T12:00:00+01:00',
    till: '2023-03-29T13:00:00+01:00',
    sequenceNumber: 1,
    resourceId: '81ORR',
    routeId: '19140'
  },
  {
    from: '2023-03-30T07:00:00+01:00',
    till: '2023-03-30T08:00:00+01:00',
    sequenceNumber: 1,
    resourceId: '81ORR',
    routeId: '19162'
  },
  {
    from: '2023-03-30T08:00:00+01:00',
    till: '2023-03-30T09:00:00+01:00',
    sequenceNumber: 1,
    resourceId: '81ORR',
    routeId: '19184'
  }
];

export const MockedOBCTimeslots = [
  {
    from: new Date('2023-03-29T13:30:00.000Z'),
    to: new Date('2023-03-29T14:00:00.000Z')
  },
  {
    from: new Date('2023-03-29T08:00:00.000Z'),
    to: new Date('2023-03-29T08:30:00.000Z')
  },
  {
    from: new Date('2023-03-29T09:00:00.000Z'),
    to: new Date('2023-03-29T09:30:00.000Z')
  },
  {
    from: new Date('2023-03-30T14:30:00.000Z'),
    to: new Date('2023-03-30T15:00:00.000Z')
  }
];

export const MockedOBCInfo = {
  personalInformation: {
    firstName: 'Janosch',
    lastName: 'Mustermann',
    gender: 'OTHER'
  },
  appointmentSlot: {
    from: '2022-06-23T09:30:00.000Z',
    to: '2022-06-23T10:00:00.000Z'
  }
};

/* USER DATA */

const contactChans = {
  phoneNumberAllowedForContact: true,
  emailAddressAllowedForContact: true
};
const invoiceAddress = {
  street: 'Bahnhofstraße',
  houseNumber: '12A',
  zip: '60489',
  city: 'Frankfurt am Main'
};
const property1Address = {
  street: 'Westerbachstraße',
  houseNumber: '47',
  zip: '60489',
  city: 'Frankfurt am Main'
};
const property2Address = {
  street: 'Kurfürstendamm',
  houseNumber: '124',
  zip: '10711',
  city: 'Berlin'
};
const clientPersonalData = {
  gender: 'male',
  firstName: 'Jonathan',
  lastName: 'Mustermann'
};
const clientContactDetails = {
  phoneNumber: '004927284582397',
  email: 'jonny@mustermanns.com'
};
const tdOrder1 = {
  type: OrderType.TechemDirect,
  clientContactDetails: clientContactDetails,
  eventHistory: [
    {
      timestamp: '2021-01-28T07:37:00',
      name: EventName.OrderCompleted,
      eventData: {
        clientNumber: '8623748322'
      }
    },
    {
      timestamp: '2021-01-28T07:37:01',
      name: EventName.OnboardingAccessGranted
    }
    /*{
      timestamp: "2021-01-28T07:37:01",
      name: EventName.PreferredOnSiteInspectionTimeslotsRequired,
    },
    {
      timestamp: "2021-01-29T15:22:00",
      name: EventName.PreferredOnSiteInspectionTimeslotsSet,
      eventData: {
        preferredTimeslots: [
          {
            date: "2021-02-01",
            timeOfDay: "afternoon",
          },
          {
            date: "2021-02-08",
            timeOfDay: "all_day",
          },
          {
            date: "2021-02-04",
            timeOfDay: "morning",
          },
        ],
      },
    },
    {
      timestamp: "2021-01-29T15:22:00",
      name: EventName.OnSiteInspectionScheduled,
      eventData: {
        date: "2021-02-01",
        startTime: "14:00",
        endTime: "16:00",
      },
    },
    {
      timestamp: "2021-02-01T12:39:00",
      name: EventName.InstallationStateRecorded,
      eventData: {},
    },
    {
      timestamp: "2021-02-03T12:47:00",
      name: EventName.PreferredInstallationTimeslotsRequired,
      eventData: {},
    },
    /*{
      timestamp: "2021-02-04T12:17:00",
      name: EventName.PreferredInstallationTimeslotsSet,
      eventData: {
        preferredTimeslots: [
          {
            date: "2021-02-18",
            timeOfDay: "all_day",
          },
          {
            date: "2021-02-22",
            timeOfDay: "morning",
          },
          {
            date: "2021-02-23",
            timeOfDay: "afternoon",
          },
        ],
      },
    },
    {
      timestamp: "2021-02-10T13:21:00",
      name: EventName.InstallationTimeslotScheduled,
      eventData: {
        date: "2021-02-22",
        startTime: "09:00",
        endTime: "11:00",
      },
    },
    {
      timestamp: "2021-02-18T16:03:00",
      name: EventName.InstallationCompleted,
      eventData: {},
    },
    {
      timestamp: "2021-02-19T11:33:00",
      name: EventName.OnboardingCompleted,
      eventData: {},
    },
  */
  ],
  data: {
    centralHeatingAvailable: true,
    centralHotWaterDelivery: true,
    heatingType: 'underfloor_heating',
    heatingSources: ['gas', 'solar'],
    billingPeriod: { start: '2021-04-01', end: '2022-05-31' }
  },
  // onSiteInspectionInterval: {
  //   from: "2022-10-10T00:00:00.000",
  //   to: "2022-10-16T00:00:00.000",
  // },
  // installationInterval: {
  //   from: "2022-10-10T00:00:00.000",
  //   to: "2022-11-10T00:00:00.000",
  // },
  orderNumber: '78329041-01923874'
};
const tdOrder2 = {
  type: OrderType.TechemDirect,
  clientContactDetails: clientContactDetails,
  eventHistory: [
    {
      timestamp: '2021-01-28T07:37:00',
      name: EventName.OrderCompleted,
      eventData: {
        clientNumber: '8623748322'
      }
    },
    {
      timestamp: '2021-01-28T07:37:10',
      name: EventName.OnboardingAccessGranted
    },
    {
      timestamp: '2021-01-28T07:37:01',
      name: EventName.PreferredOnSiteInspectionTimeslotsRequired
    },
    {
      timestamp: '2021-01-29T15:22:00',
      name: EventName.PreferredOnSiteInspectionTimeslotsSet,
      eventData: {
        preferredTimeslots: [
          {
            date: '2021-02-01',
            timeOfDay: 'afternoon'
          },
          {
            date: '2021-02-08',
            timeOfDay: 'all_day'
          },
          {
            date: '2021-02-04',
            timeOfDay: 'morning'
          }
        ]
      }
    },
    {
      timestamp: '2021-01-29T15:23:00',
      name: EventName.OnSiteInspectionScheduled,
      eventData: {
        date: '2021-02-01',
        startTime: '14:00',
        endTime: '16:00'
      }
    },
    {
      timestamp: '2021-01-29T15:25:00',
      name: EventName.OnSiteInspectionCancelled,
      eventData: {}
    },
    {
      timestamp: '2021-01-29T15:22:00',
      name: EventName.PreferredOnSiteInspectionTimeslotsSet,
      eventData: {
        preferredTimeslots: [
          {
            date: '2021-02-01',
            timeOfDay: 'afternoon'
          },
          {
            date: '2021-02-08',
            timeOfDay: 'all_day'
          },
          {
            date: '2021-02-04',
            timeOfDay: 'morning'
          }
        ]
      }
    },
    {
      timestamp: '2021-01-30T12:27:00',
      name: EventName.OnSiteInspectionFollowUpRequired,
      eventData: {}
    },
    {
      timestamp: '2021-01-30T18:04:00',
      name: EventName.PreferredOnSiteInspectionTimeslotsSet,
      eventData: {
        preferredTimeslots: [
          {
            date: '2021-02-12',
            timeOfDay: 'afternoon'
          },
          {
            date: '2021-02-13',
            timeOfDay: 'all_day'
          },
          {
            date: '2021-02-14',
            timeOfDay: 'morning'
          }
        ]
      }
    },
    {
      timestamp: '2021-02-03T11:09:00',
      name: EventName.OnSiteInspectionScheduled,
      eventData: {
        date: '2021-02-13',
        startTime: '07:00',
        endTime: '09:00'
      }
    },
    {
      timestamp: '2021-02-13T12:39:00',
      name: EventName.InstallationStateRecorded,
      eventData: {}
    },
    {
      timestamp: '2021-02-15T12:45:00',
      name: EventName.InstallationKitNeeded,
      eventData: {}
    },
    {
      timestamp: '2021-02-16T10:15:00',
      name: EventName.InstallationKitRequested,
      eventData: {
        gender: 'male',
        firstName: 'Jonathan',
        lastName: 'Mustermann',
        street: 'Kurfürstendamm',
        houseNumber: '124',
        zip: '10711',
        city: 'Berlin'
      }
    },
    {
      timestamp: '2021-02-18T11:22:00',
      name: EventName.InstallationKitSent,
      eventData: {
        ebsTrackingNumber: '450639017768'
      }
    },
    {
      timestamp: '2021-02-27T14:29:00',
      name: EventName.InstallationKitInstalled,
      eventData: {}
    },
    {
      timestamp: '2021-02-27T14:29:00',
      name: EventName.InstallationIntervalSet,
      eventData: {
        from: '2022-02-27T14:29:00',
        to: '2022-03-27T14:29:00'
      }
    },
    {
      timestamp: '2021-02-27T14:30:00',
      name: EventName.OnboardingActionRequired,
      eventData: {}
    },
    {
      timestamp: '2021-02-27T14:30:00',
      name: EventName.PreferredInstallationTimeslotsRequired,
      eventData: {}
    }
    /*{
      timestamp: "2021-02-28T12:17:00",
      name: EventName.PreferredInstallationTimeslotsSet,
      eventData: {
        preferredTimeslots: [
          {
            date: "2021-03-18",
            timeOfDay: "all_day",
          },
          {
            date: "2021-03-22",
            timeOfDay: "morning",
          },
          {
            date: "2021-03-23",
            timeOfDay: "afternoon",
          },
        ],
      },
    },
    {
      timestamp: "2021-03-01T13:21:00",
      name: EventName.InstallationTimeslotScheduled,
      eventData: {
        date: "2021-03-23",
        startTime: "15:00",
        endTime: "17:00",
      },
    },
    {
      timestamp: "2021-02-10T13:22:00",
      name: EventName.InstallationTimeslotCancelled,
      eventData: {},
    },
    {
      timestamp: "2021-02-10T14:07:00",
      name: EventName.PreferredInstallationTimeslotsSet,
      eventData: {
        preferredTimeslots: [
          {
            date: "2021-02-18",
            timeOfDay: "all_day",
          },
          {
            date: "2021-02-22",
            timeOfDay: "morning",
          },
          {
            date: "2021-02-23",
            timeOfDay: "afternoon",
          },
        ],
      },
    },
    {
      timestamp: "2021-02-10T14:29:00",
      name: EventName.InstallationTimeslotScheduled,
      eventData: {
        date: "2021-02-22",
        startTime: "09:00",
        endTime: "11:00",
      },
    },
    {
      timestamp: "2021-03-24T13:21:00",
      name: EventName.InstallationFollowUpRequired,
      eventData: {},
    },
    /*{
      timestamp: "2021-03-24T14:27:00",
      name: EventName.PreferredInstallationTimeslotsSet,
      eventData: {
        preferredTimeslots: [
          {
            date: "2021-04-08",
            timeOfDay: "afternoon",
          },
          {
            date: "2021-04-09",
            timeOfDay: "morning",
          },
          {
            date: "2021-04-11",
            timeOfDay: "all_day",
          },
        ],
      },
    },
    {
      timestamp: "2021-03-27T13:21:00",
      name: EventName.InstallationTimeslotScheduled,
      eventData: {
        date: "2021-04-09",
        startTime: "08:00",
        endTime: "10:00",
      },
    },
    {
      timestamp: "2021-04-10T11:01:00",
      name: EventName.InstallationCompleted,
      eventData: {},
    },
    {
      timestamp: "2021-04-10T11:02:00",
      name: EventName.OnboardingCompleted,
      eventData: {},
    },*/
  ],
  data: {
    centralHeatingAvailable: true,
    centralHotWaterDelivery: false,
    heatingType: 'radiator_underfloor_heating',
    heatingSources: ['pellets'],
    billingPeriod: { start: '2021-01-01', end: '2021-12-31' }
  },
  // onSiteInspectionInterval: { from: "2022-06-01", to: "2023-12-31" },
  // installationInterval: { from: "2023-06-01", to: "2023-12-31" },

  orderNumber: '928409234-29374980'
};
const property1 = {
  address: property1Address,
  numberOfApartments: 4,
  orders: [tdOrder1],
  propertyNumber: 'AABDJ284/7',
  onSiteInspectionAppointmentAdvanceDays: 21,
  installationAppointmentAdvanceDays: 16
};
const property2 = {
  address: property2Address,
  numberOfApartments: 3,
  orders: [tdOrder2],
  propertyNumber: 'BDKENSDFJ',
  // onSiteInspectionAppointmentAdvanceDays: 3,
  installationAppointmentAdvanceDays: 18
};
const property3 = {
  propertyNumber: '12345/6789',
  numberOfApartments: 2,
  address: {
    zip: '67700',
    street: 'Testweg',
    city: 'Testingen',
    houseNumber: '1'
  },
  orders: [
    {
      type: 'statement_heating_costs',
      orderNumber: '221010-20339098',
      clientContactDetails: {
        email: 'manuel.stolze.extern@techem.de',
        phoneNumber: '0123456'
      },
      eventHistory: [
        {
          timestamp: '2022-10-10T00:00:00.000',
          name: 'order_completed',
          eventData: { clientNumber: '9905' }
        },
        {
          timestamp: '2022-10-10T09:45:02.550',
          name: 'installation_kit_needed',
          eventData: {}
        }
        /*{
          timestamp: "2022-10-10T09:45:38.830",
          name: "installation_kit_requested",
          eventData: {
            firstName: "Manuel",
            lastName: "Test",
            gender: "male",
            zip: "67700",
            street: "Testweg",
            city: "Testingen",
            houseNumber: "1",
          },
        },
        /*{
          timestamp: "2022-10-10T09:48:01.297",
          name: "installation_kit_sent",
          eventData: { ebsTrackingNumber: "45345352523" },
        },
        {
          timestamp: "2022-10-10T09:48:18.820",
          name: "installation_kit_installed",
          eventData: {},
        },
        /*{
          timestamp: "2022-10-10T09:49:00.760",
          name: "preferred_installation_timeslots_required",
          eventData: {},
        },
        {
          timestamp: "2022-10-10T09:49:02.127",
          name: "installation_followup_required",
          eventData: {},
        },
        {
          timestamp: "2022-10-10T11:41:14.797",
          name: "preferred_installation_timeslots_set",
          eventData: {
            preferredTimeslots: [
              { date: "2022-12-14", timeOfDay: "morning" },
              { date: "2022-12-28", timeOfDay: "afternoon" },
              { date: "2022-12-19", timeOfDay: "morning" },
            ],
          },
        },
        {
          timestamp: "2022-10-10T12:02:01.027",
          name: "installation_timeslot_scheduled",
          eventData: {
            date: "2022-10-11",
            startTime: "14:01",
            endTime: "16:01",
          },
        },
        */
      ],
      data: {
        centralHeatingAvailable: true,
        centralHotWaterDelivery: true,
        heatingType: 'radiator',
        heatingSources: ['gas'],
        billingPeriod: { start: '2022-01-01', end: '2022-12-31' }
      }
    }
  ]
};
const property4 = {
  propertyNumber: '1337/80085',
  numberOfApartments: 2,
  address: {
    zip: '67700',
    street: 'Teststraße 1',
    city: 'Teststadt',
    houseNumber: '2'
  },
  orders: [
    {
      type: 'statement_heating_costs',
      orderNumber: '221010-20340763',
      clientContactDetails: {
        email: 'manuel.stolze.extern@techem.de',
        phoneNumber: '0123456'
      },
      eventHistory: [
        {
          timestamp: '2022-10-10T00:00:00.000',
          name: 'order_completed',
          eventData: { clientNumber: '9905' }
        },
        {
          timestamp: '2022-10-10T12:13:01.603',
          name: 'preferred_installation_timeslots_required',
          eventData: {}
        },
        {
          timestamp: '2022-10-10T12:14:09.213',
          name: 'preferred_installation_timeslots_set',
          eventData: {
            preferredTimeslots: [
              { date: '2022-12-13', timeOfDay: 'morning' },
              { date: '2022-12-12', timeOfDay: 'afternoon' },
              { date: '2022-12-26', timeOfDay: 'all_day' }
            ]
          }
        }
        // {
        //   timestamp: "2022-10-12T14:45:03.163",
        //   name: "installation_timeslot_scheduled",
        //   eventData: {
        //     date: "2022-10-13",
        //     startTime: "16:44",
        //     endTime: "18:44",
        //   },
        // },
        // {
        //   timestamp: "2022-10-12T14:46:00.390",
        //   name: "installation_timeslot_cancelled",
        //   eventData: {},
        // },
        // {
        //   timestamp: "2022-10-12T14:46:01.390",
        //   name: "preferred_installation_timeslots_required",
        //   eventData: {},
        // },
      ],
      data: {
        centralHeatingAvailable: true,
        centralHotWaterDelivery: true,
        heatingType: 'radiator',
        heatingSources: ['gas'],
        billingPeriod: { start: '2022-01-01', end: '2022-12-31' }
      }
    }
  ]
};
export const MockedCustomerNumber = 'MOCKED_12345';
export const MockedCustomerData: CustomerDTO = {
  userData: {
    clientNumber: MockedCustomerNumber,
    properties: [property1, property2, property3, property4],
    contractData: {
      personalData: clientPersonalData,
      address: invoiceAddress
    },
    contactChannels: contactChans
  }
  //selectedPropertyIndex: undefined,
};
