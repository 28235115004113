import React from 'react';

import { useStyletron } from 'baseui';

import Header from 'layout/Header';
import Footer from 'layout/Footer';
import WizardStepsForOverallProgress from 'components/WizardStepsForOverallProgress';
import { Row, Col, Container } from 'react-bootstrap';
import { TechemTheme } from '@techem/techem-theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { DetailedEventOverview } from 'components/DetailedEventOverview';
import Title from 'components/Title';
import { Trans, useTranslation } from 'react-i18next';
import {
  EventName,
  mostRecentEvent,
  OrderType,
  StoreObject
} from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';
import { Button } from '@techem/ui-components';
import { KIND, SIZE } from 'baseui/button';
import Icon, { ICONSIZE } from 'components/Icon';

import calendarIcon from 'assets/icons/png/3x/icn-calendar.png';
import { DateFormat, formatDate } from 'tools/dev/DateTimeFormatHelper';
import { TrackerUtil } from 'tracking/TrackerUtil';
import { minBreakpoints } from 'theme/mediaQueries';
import { useMediaQuery } from 'tools/hooks/useMediaQuery';
import YoutubeEmbeddedVideo from 'components/YoutubeEmbeddedVideo';
import fileDownloadIcon from 'assets/icons/png/3x/FileDownload.png';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import useDuration from 'tools/hooks/useDuration';
import { OnboardingInformationClient } from 'clients';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const InstallationAppointmentScheduledPage: React.FC<Props> = ({ onboardingInfo }) => {
  const [css] = useStyletron();
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);
  const { duration: installationDuration } = useDuration('installation');

  const onDownloadTenantAnnouncementClick = () => {
    OnboardingInformationClient.getTenantInstallationInfoDocument(
      onboardingInfo!.userData.properties[onboardingInfo!.selectedPropertyIndex].orders.find(
        (o) => o.type === OrderType.TechemDirect
      )!.orderNumber
    );
  };

  const scheduledAppointmentInfo = mostRecentEvent(
    onboardingInfo!.userData.properties[onboardingInfo!.selectedPropertyIndex].orders
      .find((o) => o.type === OrderType.TechemDirect)!
      .eventHistory.filter(
        (e) => e.nameOrderObject.name === EventName.InstallationTimeslotScheduled
      )
  );

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search
              },
              {
                state: { from: loc.pathname }
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-3">
              <Title className="mx-1" title={t('installationAppointmentScheduledPageTitle')} />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius('8px')
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon iconSrc={calendarIcon} alt="calendar" size={ICONSIZE.xxxlarge} />
                </Col>
              </Row>

              <Row className="justify-content-center" data-gi="installation-appointment-info">
                <Col xs={10} className="copy ps-0 pe-0 mt-3 mb-3 pb-1 text-center">
                  <Trans
                    i18nKey="installationAppointmentScheduledPageContent"
                    values={{
                      weekday: formatDate(
                        new Date(scheduledAppointmentInfo.data.date),
                        DateFormat.DateWeekdayOnly
                      ),
                      date: formatDate(
                        new Date(scheduledAppointmentInfo.data.date),
                        DateFormat.DateWithFullMonth
                      ),
                      timeStartTerm: scheduledAppointmentInfo.data.startTime,
                      timeEndTerm: scheduledAppointmentInfo.data.endTime,
                      hours: installationDuration
                    }}
                    tOptions={{ skipPostProcessing: true }}
                    components={[
                      <span
                        style={{
                          color: TechemTheme.colors.accent
                        }}
                      />,
                      <span style={{ color: TechemTheme.colors.mono500 }} />
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy text-bold pt-2 mt-2 mt-4 mx-0">
              <div
                className={css({
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '32px'
                })}
              >
                {t('installationAppointmentScheduledPagePrecautionsTitle')}
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy">
              <Row className="justify-content-center">
                <Col xs md={7} className="copy mt-2">
                  <Row className="justify-content-center">
                    <Col xs={12} className="copy">
                      <ul style={{ paddingLeft: '1.5rem' }} className="mb-0">
                        {Array.from({ length: 3 }, (_, i) => i + 1).map((index) => {
                          return (
                            <li key={index}>
                              {t(`installationAppointmentScheduledPagePrecautionsBullet${index}`)}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>

                    <Col xs className="copy mt-3 mb-3 mb-md-0">
                      <span
                        style={{
                          color: TechemTheme.colors.accent // #009BB4
                        }}
                      >
                        <Trans
                          i18nKey="installationAppointmentScheduledPagePrecautions"
                          tOptions={{ skipPostProcessing: true }}
                        />
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={10} md={5} className="justify-content-center">
                  <Row className="justify-content-center">
                    <Col>
                      <YoutubeEmbeddedVideo embedId="Wfy1XCuBRFc" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy mt-4 mb-md-3 pt-3 pt-md-2">
              <Row className={isMdOrAbove ? '' : 'justify-content-center'}>
                <Col xs="auto">
                  <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    onClick={() => {
                      onDownloadTenantAnnouncementClick();
                    }}
                    additionalTestId="download-tenant-announcement"
                    trackClickUsingTestId={(buttonIdentifier) => {
                      TrackerUtil.trackBtnClick(
                        `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                        `button_${buttonIdentifier}`
                      );
                    }}
                    startEnhancer={() => (
                      <Icon iconSrc={fileDownloadIcon} alt="checkmark icon" size={ICONSIZE.mini} />
                    )}
                  >
                    <Trans i18nKey="installationAppointmentScheduledPageDownloadTenantAnnouncementButtonText" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="pt-4">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(InstallationAppointmentScheduledPage);
