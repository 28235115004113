const storagePrefix = 'techem_direct_onboarding_';

export enum StorageKey {
  introCompleted = 'intro_completed'
}

const storage = {
  isIntroCompleted: () => {
    return !!JSON.parse(
      window.localStorage.getItem(`${storagePrefix}${StorageKey.introCompleted}`) as string
    );
  },

  setIsIntroCompleted: (isIntroCompleted: boolean) => {
    window.localStorage.setItem(
      `${storagePrefix}${StorageKey.introCompleted}`,
      JSON.stringify(isIntroCompleted)
    );
  },

  clearIsIntroCompleted: () => {
    window.localStorage.removeItem(`${storagePrefix}${StorageKey.introCompleted}`);
  }
};

export default storage;
