import React, { useEffect, useState } from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import WizardStepsForOverallProgress from 'components/WizardStepsForOverallProgress';
import { Row, Col, Container } from 'react-bootstrap';
import { TechemTheme } from '@techem/techem-theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { DetailedEventOverview } from 'components/DetailedEventOverview';
import Title from 'components/Title';
import { Trans, useTranslation } from 'react-i18next';
import { OrderType, StoreObject } from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';
import Icon, { ICONSIZE } from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';

import calendarIcon from 'assets/icons/png/3x/icn-calendar.png';
import store from 'store';
import { Property, Order } from 'reducers/onboardingInformationSlice';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const OnSiteInspectionPreferredTimeslotsSetPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const [queryParams] = useState<URLSearchParams>(new URLSearchParams(loc.search));

  const [selectedProperty, setSelectedProperty] = useState<Property>();

  useEffect(() => {
    let property = store
      .getState()
      .onboardingInfo.userData.properties.find(
        (property) => property.propertyNumber === queryParams.get('propertyNumber')
      );
    if (property) {
      setSelectedProperty(property);
    }
  }, [queryParams]);

  return !!onboardingInfo?.userData.clientNumber ? (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search
              },
              {
                state: { from: loc.pathname }
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-3">
              <Title className="mx-1" title={t('onSiteInspectionPreferredTimeslotsSetPageTitle')} />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius('8px')
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon iconSrc={calendarIcon} alt="calendar" size={ICONSIZE.xxxlarge} />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={12} className="copy p-0 pb-3 mt-3 mb-2  px-4 mx-2 text-center">
                  <Trans
                    i18nKey="onSiteInspectionPreferredTimeslotsSetPageContent"
                    tOptions={{ skipPostProcessing: true }}
                    components={[<span style={{ color: TechemTheme.colors.mono500 }} />]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy text-bold pt-2 pt-md-3 m-2 mt-4 mx-0">
              {t('onSiteInspectionPreferredTimeslotsSetPagePrecautionsTitle')}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy my-2">
              {t('onSiteInspectionPreferredTimeslotsSetPagePrecautionsDescription')}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="copy mt-1 mb-2 mb-md-3">
              <ul style={{ paddingLeft: '1.5rem' }} className="mb-0">
                <li>{t('onSiteInspectionPreferredTimeslotsSetPagePrecaution1')}</li>
                <li>{t('onSiteInspectionPreferredTimeslotsSetPagePrecaution2')}</li>
              </ul>
            </Col>
            <Col xs={12} md={10} lg={8} className="copy mt-3 mb-3 mb-md-0">
              <span
                style={{
                  color: TechemTheme.colors.accent // #009BB4
                }}
              >
                <Trans
                  i18nKey="onSiteInspectionAppointmentScheduledPagePrecautions"
                  tOptions={{ skipPostProcessing: true }}
                />
              </span>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-4">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0 && selectedProperty
                    ? selectedProperty!.orders
                        .find((order: Order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  ) : (
    <Container className="m-auto" style={{ transitionDuration: '350ms' }}>
      <Row className="justify-content-center">
        <Col xs="auto" className="d-flex vh-100">
          <LoadingSpinner topLevelStylingOverrides={{ margin: 'auto' }} />
        </Col>
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps)(OnSiteInspectionPreferredTimeslotsSetPage);
