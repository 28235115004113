import React from 'react';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import WizardStepsForOverallProgress from 'components/WizardStepsForOverallProgress';
import { Row, Col, Container } from 'react-bootstrap';
import { TechemTheme } from '@techem/techem-theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'Routes';
import { DetailedEventOverview } from 'components/DetailedEventOverview';
import Title from 'components/Title';
import { Trans, useTranslation } from 'react-i18next';
import {
  EventName,
  mostRecentEvent,
  OrderType,
  StoreObject
} from 'reducers/onboardingInformationSlice';
import { connect } from 'react-redux';
import Icon, { ICONSIZE } from 'components/Icon';

import calendarIcon from 'assets/icons/png/3x/icn-calendar.png';
import { DateFormat, formatDate } from 'tools/dev/DateTimeFormatHelper';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const OnSiteInspectionAppointmentScheduledPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const scheduledAppointmentInfo = mostRecentEvent(
    onboardingInfo!.userData.properties[onboardingInfo!.selectedPropertyIndex].orders
      .find((o) => o.type === OrderType.TechemDirect)!
      .eventHistory.filter((e) => e.nameOrderObject.name === EventName.OnSiteInspectionScheduled)
  );

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search
              },
              {
                state: { from: loc.pathname }
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-3">
              <Title className="mx-1" title={t('onSiteInspectionAppointmentScheduledPageTitle')} />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius('8px')
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon iconSrc={calendarIcon} alt="calendar" size={ICONSIZE.xxxlarge} />
                </Col>
              </Row>

              <Row className="justify-content-center" data-gi="on-site-inspection-appointment-info">
                <Col xs={10} className="copy p-0 pb-3 mt-3 mb-2 text-center">
                  <Trans
                    i18nKey="onSiteInspectionAppointmentScheduledPageContent"
                    values={{
                      weekday: formatDate(
                        new Date(scheduledAppointmentInfo.data.date),
                        DateFormat.DateWeekdayOnly
                      ),
                      date: formatDate(
                        new Date(scheduledAppointmentInfo.data.date),
                        DateFormat.DateWithFullMonth
                      ),
                      timeStartTerm: scheduledAppointmentInfo.data.startTime,
                      timeEndTerm: scheduledAppointmentInfo.data.endTime
                    }}
                    tOptions={{ skipPostProcessing: true }}
                    components={[
                      <span style={{ color: TechemTheme.colors.accent }} />,
                      <span style={{ color: TechemTheme.colors.mono500 }} />
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy text-bold pt-2 pt-md-3 m-2 mt-4 mx-0">
              {t('onSiteInspectionAppointmentScheduledPagePrecautionsTitle')}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy my-2">
              {t('onSiteInspectionAppointmentScheduledPagePrecautionsDescription')}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy my-2">
              <Row className="justify-content-center">
                <Col xs={12} className="copy">
                  <ul style={{ paddingLeft: '1.5rem' }} className="mb-0">
                    {Array.from({ length: 2 }, (_, i) => i + 1).map((index) => {
                      return (
                        <li key={index}>
                          {t(`onSiteInspectionAppointmentScheduledPagePrecaution${index}`)}
                        </li>
                      );
                    })}
                  </ul>
                </Col>

                <Col xs className="copy mt-3 mb-3 mb-md-0">
                  <span
                    style={{
                      color: TechemTheme.colors.accent // #009BB4
                    }}
                  >
                    <Trans
                      i18nKey="onSiteInspectionAppointmentScheduledPagePrecautions"
                      tOptions={{ skipPostProcessing: true }}
                    />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-4">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(OnSiteInspectionAppointmentScheduledPage);
