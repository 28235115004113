import React from 'react';
import {
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader
} from '@techem/ui-components';
import { Col, Row } from 'react-bootstrap';
import PhoneSupportNoTitleView from '../../../components/PhoneSupportNoTitleView';
import { TrackerUtil } from '../../../tracking/TrackerUtil';
import { styled } from 'styletron-react';
import { TechemTheme } from '@techem/techem-theme';
import { ErrorState } from '../index';
import { useTranslation } from 'react-i18next';
import MailTo from '../../../components/MailTo';

interface ErrorModalProps {
  errorState: ErrorState;
  setErrorState: (es: ErrorState | null) => void;
}

const HeaderView = styled(Col, {
  height: '100px',
  '@media (max-width: 767px)': {
    height: '80px'
  },
  backgroundColor: TechemTheme.colors.backgroundPrimary
});

export const TemporaryErrorModal: React.FC<ErrorModalProps> = ({ errorState, setErrorState }) => {
  const { t } = useTranslation();
  const email = t('welcomeEmail') || '';

  const getErrorTitle = (errorStatus: 400 | 404 | 422 | number) => {
    switch (errorStatus) {
      case 400:
      case 404:
        return t('OBCCustomerNotFoundErrorModalTitle');
      case 422:
        return t('propertyNumberInvalidErrorModalTitle');
      default:
        return t('tempGeneralErrorModalTitle');
    }
  };

  const getErrorDescription = (errorStatus: 400 | 404 | 422 | number) => {
    switch (errorStatus) {
      case 400:
      case 404:
        return t('propertyNumberInvalidErrorModalTitle');
      case 422:
        return t('propertyNumberInvalidErrorModalDescription1');
      default:
        return t('tempGeneralErrorModalDescription1');
    }
  };

  const getSecondErrorDescription = (errorStatus: 400 | 404 | 422 | number) => {
    switch (errorStatus) {
      case 400:
      case 404:
        return t('OBCCustomerNotFoundErrorModalDescription2');
      case 422:
        return '';
      default:
        return t('tempGeneralErrorModalDescription2');
    }
  };

  const getErrorButtonDescription = (errorStatus: 400 | 404 | 422 | number) => {
    switch (errorStatus) {
      case 400:
      case 404:
        return t('OBCCustomerNotFoundErrorModalCloseButton');
      case 422:
        return t('propertyNumberInvalidErrorModalCloseButton');
      default:
        return t('tempGeneralErrorModalCloseButton');
    }
  };

  return (
    <TechemModal
      closeable
      header={
        <TechemModalHeader title={errorState ? getErrorTitle(errorState!.error.status) : ''} />
      }
      body={
        <TechemModalBody>
          <Row>
            <Col xs className="text-center copy">
              {errorState && getErrorDescription(errorState!.error.status)}
              {errorState!.error.status === 500 && (
                <MailTo email={email} label={email} subject={'Welcome Call Termin'} />
              )}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto" sm={11} className="copy mt-3 mb-2">
              {errorState && getSecondErrorDescription(errorState!.error.status)}
            </Col>
          </Row>
          {errorState && errorState!.error.status !== 422 && (
            <Row>
              <Col xs className="text-center copy">
                <HeaderView lg={4} md={7} sm="auto" xs="auto" className="d-flex w-100">
                  <PhoneSupportNoTitleView />
                </HeaderView>
              </Col>
            </Row>
          )}
        </TechemModalBody>
      }
      footer={
        <TechemModalButtonFooter
          trackClickUsingTestId={(buttonIdentifier: string) => {
            TrackerUtil.trackBtnClickByIdentifier(
              buttonIdentifier,
              'load-customer-data-error-modal'
            );
          }}
          primary={{
            text: errorState ? getErrorButtonDescription(errorState!.error.status) : '',
            handleOnClick: () => {
              setErrorState({
                error: errorState!.error,
                show: false
              });
              setTimeout(() => {
                setErrorState(null);
              }, 1500);
            },
            additionalTestId: 'close-error-modal'
          }}
        />
      }
      isOpen={!!errorState && !!errorState.error && errorState.show}
      modalTrackingId="load-customer-data-error-modal"
      onClose={() => {
        setErrorState({
          error: errorState!.error,
          show: false
        });
        setTimeout(() => {
          setErrorState(null);
        }, 1500);
      }}
      additionalTestId="load-customer-data-error-modal"
    />
  );
};
