import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TechemTheme } from '@techem/techem-theme';
import { useStyletron } from 'styletron-react';
import { CSSAssignBorderRadius } from 'services/tools/CSSHelper';
import Icon, { ICONSIZE } from '../../../components/Icon';
import phoneIcon from '../../../assets/icons/png/3x/icn-phone-flip.png';
import { useTranslation } from 'react-i18next';

const telHref = 'tel:08002508050';

const LoginErrorHint: React.FC = () => {
  const { t } = useTranslation();
  const [css] = useStyletron();

  return (
    <Row
      className={
        'justify-content-center mt-5 mb-4 copy ' +
        css({
          color: TechemTheme.colors.contentSecondary + '14'
        })
      }
    >
      <Col xs={12} xl={8}>
        <div
          className={css({
            background: TechemTheme.colors.backgroundTertiary,
            ...CSSAssignBorderRadius('8px'),
            padding: '16px 20px 24px 20px'
            //textAlign: "center",
          })}
        >
          <Row className="justify-content-center">
            <Col
              xs={12}
              className={css({
                maxWidth: '720px',
                color: TechemTheme.colors.contentSecondary,
                fontFamily: 'Univers LT Pro',
                fontStyle: 'normal'
              })}
            >
              Wir bedauern, dass derzeit kein Login in unser Onboarding-Tool möglich ist und
              arbeiten mit Hochdruck an einer Lösung. Sollten Sie zwischenzeitlich Fragen zu Ihrem
              Auftrag oder anderen Anliegen haben, so wenden Sie sich bitte jederzeit an unsere
              Kundenbetreuung.
              <br />
              <br />
              Wir sind stets bemüht, Ihnen zur Seite zu stehen und jegliche Unannehmlichkeiten zu
              minimieren. Vielen Dank für Ihr Verständnis und Ihre Geduld.
              <br />
              <br />
            </Col>

            <Col
              xs={12}
              className="d-flex justify-content-center"
              style={{ flex: 'inherit !important' }}
            >
              <div className="my-auto mx-0">
                <Icon
                  handleClick={() => {
                    window.location.href = telHref;
                  }}
                  size={ICONSIZE.large}
                  iconSrc={phoneIcon}
                  alt="phone support icon"
                />
              </div>
              <a
                href={telHref}
                className="mx-3 my-auto"
                style={{
                  fontSize: '1.5rem',
                  lineHeight: '1.5rem',
                  fontWeight: 'bold',
                  textDecoration: 'inherit',
                  color: TechemTheme.colors.primaryA
                }}
              >
                0800 2 50 80 50
              </a>
            </Col>
            <Col xs={12} style={{ flex: 'inherit !important' }}>
              <span className="copy" style={{ whiteSpace: 'nowrap' }}>
                {t('headerPhoneSupportViewOperatingHoursNotice')}
              </span>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default LoginErrorHint;
