import React from 'react';
import { styled } from 'baseui';

export interface IconProps {
  iconSrc: string;
  handleClick?: any;
  size?: string;
  centered?: boolean;
  className?: string;
  disabled?: boolean;
  alt: string;
  additionalTestId?: string;
}
export const ICONSIZE = {
  mini: 'mini',
  default: 'default',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
  xxlarge: 'xxlarge',
  xxxlarge: 'xxxlarge'
};
export const getPixelSize = (size?: string) => {
  if (!size) {
    return '22px';
  }
  switch (size) {
    case ICONSIZE.xxxlarge:
      return '64px';
    case ICONSIZE.xxlarge:
      return '56px';
    case ICONSIZE.xlarge:
      return '48px';
    case ICONSIZE.large:
      return '36px';
    case ICONSIZE.medium:
      return '26px';
    case ICONSIZE.small:
      return '24px';
    case ICONSIZE.mini:
      return '14px';
    default:
      return '22px';
  }
};
const getCursor = (clickHandler: any) => {
  return clickHandler ? 'pointer' : 'default';
};

const IconView = styled('img', (props: Omit<any, '$theme'>) => ({
  maxWidth: getPixelSize(props.size),
  maxHeight: getPixelSize(props.size),
  margin: 'auto',
  cursor: getCursor(props.onClick),
  opacity: props.disabled ? '.5' : '1',
  overflow: 'auto'
}));

export default function Icon(props: IconProps) {
  return (
    <div className={`${props.className ? props.className : ''} h-100 w-100 d-flex m-auto`}>
      <div
        className={`${props.centered ? 'm-auto' : 'mt-0 mx-auto mb-auto'} d-flex`}
        style={{
          height: props.centered ? '100%' : getPixelSize(props.size),
          width: props.centered ? '100%' : getPixelSize(props.size)
        }}
      >
        <IconView
          src={props.iconSrc}
          onClick={props.handleClick}
          size={props.size}
          centered={props.centered ? 1 : 0}
          disabled={props.disabled}
          alt={props.alt}
          data-gi={`img-btn${
            props.additionalTestId !== undefined ? ' ' + props.additionalTestId : ''
          }`}
        />
      </div>
    </div>
  );
}
Icon.defaultProps = {
  size: ICONSIZE.default,
  centered: false
};
