import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import deTranslation from './lang/de.json';
import Sanitizer from './PostProcessor';

const resources = {
  de: {
    translation: {
      ...deTranslation
    }
  }
};

const i18nProvider = () => {
  var i18n = i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Sanitizer as any)
    .init({
      resources,
      lng: 'de',
      fallbackLng: 'de',
      interpolation: {
        // TODO: DO NOT set escapeValue globally to false as this opens the app to XSS
        // Ticket created: PKD-566
        escapeValue: false
      },
      postProcess: ['Sanitizer']
    });

  return i18n;
};

export default i18nProvider();
